<template>
  <div class="choose-err">
    <div class="err-info flex">
      <div class="flex">
        <img
          class="status-small-icon small-icon-12"
          src="@/assets/images/work/icon_exception.png"
          alt="load error"
        />
        <span
          class="err-title"
          v-html="$t('convertPage.convertErrTitle')"
        ></span>
      </div>
      <span class="desc">{{
        $t(`convertPage.targetFileErrorDesc.${store.currentRule.name}`)
      }}</span>
    </div>
    <div>
      <button
        class="but"
        @click="back"
        :style="{ backgroundColor: buttonColor }"
      >
        <img
          class="small-icon small-icon-8"
          src="@/assets/images/work/icon_restart.png"
          alt="load error"
        />{{ $t("convertPage.startOver") }}
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "ChooseErr",
  components: {},
  props: {
    buttonColor: {
      default: "#00a3ff",
      type: String,
    },
    fileType: {
      type: String,
      default: "PDF",
    },
    from: {
      type: String,
      default: "PDF",
    },
  },
  setup() {
    let useStores = useStore();
    let store = useStores.state;
    function back() {
      useStores.commit("change", { key: "workStatus", value: "chooseFile" });
    }
    return {
      store,
      back,
    };
  },
};
</script>

<style scoped>
.choose-err span {
  margin: 27px 0;
}
.choose-err {
  font-size: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.choose-err .err-info {
  text-align: center;
  justify-content: space-between;
  align-items: center;
}
.choose-err .err-info .err-title {
  display: none;
  font-size: 18px;
  font-weight: 700;
}
.choose-err .desc {
  font-weight: 800;
  font-size: 24px;
}

@media all and (max-width: 640px) and (min-width: 320px) {
  .choose-err {
    justify-content: flex-start;
    padding-top: 45px;
    box-sizing: border-box;
  }
  .choose-err .err-info {
    flex-direction: column;
  }
  .choose-err .err-info .err-title {
    display: flex;
  }
  .choose-err .desc {
    margin-top: 18px;
    font-size: 14px;
    font-weight: 400;
  }
  .choose-err span {
    margin: 0;
  }
  .choose-err button {
    margin-top: 28px;
  }
}
@media all and (max-width: 1020px) and (min-width: 640px) {
}
</style>
