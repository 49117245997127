<template>
  <transition name="left" class="v-enter-active v-leave-active">
    <div class="reset-body flex">
      <div class="reset flex">
        <div class="">
          <div class="title">{{ $t("resetPasswordPage.title") }}</div>
          <IPassword
            @updateValue="updateValuePasswordFirst"
            :has-parent-hint="hasHintPasswordFirst"
            :show-grid="true"
            :placeholder="$t('resetPasswordPage.newPasswordPlaceholder')"
            :hint="firstHint"
            ref="passwordFirstRef"
            class="first-pass"
          ></IPassword>

          <IPassword
            :icon="require('@/assets/images/account/icon-shield.png')"
            @updateValue="updateValuePasswordLast"
            :has-parent-hint="hasHintPasswordLast"
            @oninput="oninputPasswordLast"
            @inputBlur="onBlurPasswordLast"
            :placeholder="$t('resetPasswordPage.confirmPasswordPlaceholder')"
            ref="passLast"
            :confirm="true"
            :hint="confirmHint"
            @keypress.enter="updatePassword"
          ></IPassword>
          <Button class="button" @click="updatePassword" :status="butStatus">
            {{ $t("resetPasswordPage.buttonText") }}
          </Button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { inject, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import IPassword from "../components/IPassword";
import Button from "../components/Button";
import utils from "../util/utils";
import { useStore } from "vuex";
import Account from "../store/account";
import { useI18n } from "vue-i18n";
export default {
  name: "ResetPass",
  components: {
    IPassword,
    Button,
  },
  setup() {
    const { t } = useI18n();
    let useStores = useStore();

    let router = useRouter();
    let route = useRoute();
    let butStatus = ref("before");

    let passwordFirstRef = ref(null);

    let passLast = ref(null);
    let passwordFirst = ref("");
    let passwordLast = ref("");
    let confirmHint = ref(t("error.comparePasswordErr"));
    let firstHint = ref("");

    let hasHintPasswordLast = ref(false);
    let hasHintPasswordFirst = ref(false);

    function updateValuePasswordFirst(e) {
      hasHintPasswordFirst.value = false;
      passwordFirst.value = e;
    }
    function updateValuePasswordLast(e) {
      hasHintPasswordLast.value =
        !!passwordFirst.value && e !== passwordFirst.value;
      passwordLast.value = e;
    }

    function oninputPasswordLast(e) {
      passwordLast.value = e;
      hasHintPasswordLast.value = false;
    }

    function onBlurPasswordLast(e) {
      hasHintPasswordLast.value =
        !!passwordFirst.value &&
        e !== passwordFirst.value;
    }

    function setFirstStatus() {
      hasHintPasswordFirst.value = true;
      firstHint.value = passLast.value.defHint;
    }

    let headTopupHide = inject("headTopupHide");

    function updatePassword() {
      if (!passwordFirst.value) {
        setFirstStatus();
        return;
      } else if (
        !passwordLast.value ||
        passwordLast.value !== passwordFirst.value
      ) {
        hasHintPasswordLast.value = true;
        return;
      }
      if (passLast.value.lv < 5) {
        hasHintPasswordLast.value = true;
        setFirstStatus();
        return;
      }
      hasHintPasswordFirst.value = false;
      hasHintPasswordLast.value = false;

      butStatus.value = "going";
      const _sid = utils.getCookie("sid");
      utils.setCookie("sid", route.params.sid || utils.getCookie("tsid"));
      utils
        .updatePassword(passwordLast.value)
        .then((res) => {
          butStatus.value = "ok";
          utils.setCookie("sid", res.data.sid);
          Account.storage(res.data.sid);
          useStores.dispatch("account/init");
          useStores.commit("changeLogin", true);
          window.setTimeout(function () {
            headTopupHide();
            router.push({ path: "/profile" });
          }, 500);
        })
        .catch((e) => {
          utils.setCookie("sid", _sid);
          console.log(e);
          butStatus.value = "before";
        });
    }

    return {
      passwordFirstRef,
      firstHint,
      confirmHint,
      passLast,
      butStatus,
      hasHintPasswordFirst,
      hasHintPasswordLast,
      passwordFirst,
      passwordLast,
      updateValuePasswordLast,
      updateValuePasswordFirst,
      oninputPasswordLast,
      onBlurPasswordLast,
      updatePassword,
    };
  },
  // created () {
  // }
};
</script>

<style scoped>
.reset-body {
  height: 100%;
  flex-direction: column;
}
.reset {
  background: #ffffff;
  border-radius: 8px;
  width: 420px;
  text-align: center;
  padding: 32px 0;
  font-size: 14px;
}
.reset > div {
  width: 360px;
}
.reset .title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 28px;
}
.reset .flex {
  flex-direction: column;
}
.first-pass {
  margin-bottom: 24px;
}
.button {
  margin-top: 28px;
}

@media all and (max-width: 570px) and (min-width: 320px) {
  .reset-body {
    width: 100%;
  }
  .reset,
  .reset > div {
    width: 100%;
  }
  .reset {
    padding: 0;
  }
  .reset .title {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .reset button {
    font-size: 16px;
  }
  .first-pass {
    margin-bottom: 28px;
  }
  .button {
    margin-top: 14px;
  }
}
</style>
