import axios from "axios";
import pdf from "vue-pdf";
import { v4 as uuidv4 } from "uuid";

axios.defaults.withCredentials = true;

// const offsetTimezone = new Date().getTimezoneOffset();
// axios.interceptors.request.use(
//   config => {
//     config.headers.tzoffset = offsetTimezone.toString();
//     return config;
//   },
//   error => {
//     return Promise.reject(error)
//   }
// )

// utils
function copy(value) {
  let currentFocus = document.activeElement;
  let input = document.createElement("input");
  document.body.appendChild(input);
  input.style.opacity = 0;
  input.value = value;
  let scrollY = window.scrollY;
  input.focus();
  input.setSelectionRange(0, input.value.length);
  let res = document.execCommand("copy", true);
  currentFocus.focus();
  document.body.removeChild(input);
  window.scrollTo(0, scrollY);
  return res;
}

function getPathname() {
  return window.location.pathname.replace("/", "");
}

function getQuery(letiable) {
  let query = "";
  if (window.location.search) query = window.location.search.substring(1);
  else if (window.location.href.includes("?")) {
    try {
      query = window.location.href.split("?")[1];
    } catch (e) {
      console.log(e);
    }
  }
  if (window.location.hash && window.location.hash.includes("?")) {
    query += "&" + window.location.hash.split("?")[1];
  }
  if (!query) return false;
  let pars = query.split("&");
  for (let i = 0; i < pars.length; i++) {
    let pair = pars[i].split("=");
    if (pair[0] == letiable) {
      return pair[1];
    }
  }
  return false;
}

function getCurrentYearAndMonth() {
  const _date = new Date();
  let _month = _date.getMonth() + 1;
  if (_month < 10) _month = `0${_month}`;
  return `${_date.getFullYear()}${_month}`;
}

function getCenterStr(str) {
  if (!str) return "";
  str = str.toString();
  const _l = str.length;
  const _c = _l - 2 > 3 ? 3 : _l - 2;
  return str[0] + "*".repeat(_c) + str[_l - 1];
}

function noPassByEmail(email) {
  if (!email) return "";
  let new_email = email.toString();
  if (new_email.indexOf("@") > 0) {
    let str = email.split("@");
    let _m = str[0];
    let _domain = str[1];
    new_email = getCenterStr(_m) + "@" + getCenterStr(_domain);
  }
  return new_email;
}

function getFirstFilename(str, len = 4) {
  if (typeof str !== "string") return "";
  return str.substring(0, len)
}
function getOmittedFilename(str, len = 8) {
  if (typeof str !== "string") return "";
  let index = str.lastIndexOf(".");
  if (index <= len) return str;
  if (str.length >= len) {
    let suf = getFileSuffix(str);
    return `${str.substring(0, len - 1 - suf.length)}...${str.substring(
      index - 3,
      index
    )}.${suf}`;
  }
  return str;
}

function getHideString(str, maxlen = 10) {
  if (!str || str.length <= maxlen) return str;
  return str.substring(0, maxlen) + "...";
}

function getFileBaseName(str) {
  if (typeof str !== "string") return "";
  let index = str.lastIndexOf(".");
  return str.substring(0, index);
}

function getFileSuffix(str) {
  if (!str || typeof str !== "string") return "";
  let index = str.lastIndexOf(".");
  return str.substr(index + 1);
}

function getPercentage(loaded, total) {
  return Math.floor((loaded / total) * 100);
}

function getMaxValue(values) {
  return Math.max.apply(null, values);
}

function getObjectURLByFile(file) {
  let url = null;
  if (window.createObjectURL !== undefined) {
    // basic
    url = window.createObjectURL(file);
  } else if (window.webkitURL !== undefined) {
    // webkit or chrome
    try {
      url = window.webkitURL.createObjectURL(file);
    } catch (error) {
      console.log(error);
    }
  } else if (window.URL !== undefined) {
    // mozilla(firefox)
    try {
      url = window.URL.createObjectURL(file);
    } catch (error) {
      console.log(error);
    }
  }
  return url;
}

function nodeToHtml(node) {
  let _div = document.createElement("div");
  _div.appendChild(node.cloneNode(true));
  return _div.innerHTML;
}

function verifyEmail(email) {
  let emailReg = /^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  return emailReg.test(email);
}

function renderSize(value, digits = 2) {
  if (null == value || value === "") {
    return "0 Bytes";
  }
  const unitArr = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let index = 0;
  let srcsize = parseFloat(value);
  index = Math.floor(Math.log(srcsize) / Math.log(1024));
  let size = srcsize / Math.pow(1024, index);
  size = size.toFixed(digits); //保留的小数位数
  return size + " " + unitArr[index];
}

function calculateDecimal(num) {
  try {
    if (typeof num === "string") num = Number.parseFloat(num);
  } catch (e) {
    console.log(e);
  }
  return Math.round(num * 100) / 100;
}

function complementDecimal(num) {
  return num.toString().length === 3 ? num.toString() + "0" : num.toString();
}

function getSystemLanguage() {
  let result = null;
  try {
    let lan = navigator.systemLanguage || navigator.language || navigator.userLanguage;
    if (lan.toLowerCase().indexOf('zh-cn') !== -1) {
      result = "zh"
    } else if(lan.toLowerCase().indexOf('en') !== -1) {
      result = "en"
    } else if(lan.toLowerCase().indexOf('zh-tw')!==-1) {
      result = "zh_tc"
    }
  } catch (e){
    console.log(e);
  }
  return result;
}

// cookie
function setCookie(cname, cvalue, exdays = 360) {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  const _domain =
    process.env.NODE_ENV === "development"
      ? process.env.VUE_APP_DOMAIN
      : `.${process.env.VUE_APP_DOMAIN}`;
  document.cookie = `${cname}=${cvalue}; ${expires}; domain=${_domain};`;
}

function clearCookie(cname) {
  var d = new Date();
  d.setTime(-1);
  let expires = "expires=" + d.toUTCString();
  document.cookie = `${cname}= ; ${expires}; domain=.${process.env.VUE_APP_DOMAIN};`;
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1);
    if (c.indexOf(name) != -1) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


// about office file
import XLSX from "xlsx";

function isEncryptInPdf(file) {
  return new Promise((resolve, reject) => {
    let loadingTask = pdf.createLoadingTask(getObjectURLByFile(file))
    loadingTask.onPassword = () => {
      reject('NEEDPASS')
    };
    loadingTask.promise
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject('NOTPDF')
      })
  })
}

function getPdf(file) {
  return pdf.createLoadingTask(getObjectURLByFile(file)).promise;
}

function getNumPages(file, type, back) {
  if (type === "excel") {
    let reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = function (e) {
      let data = e.target.result;
      let zzexcel = XLSX.read(data, {
        type: "binary",
      });
      back(zzexcel.SheetNames.length);
    };
  } else if (type === "pdf") {
    getPdf(file)
      .then((pdf) => {
        back(pdf.numPages);
      })
      .catch((err) => {
        console.error("pdf 加载失败", err);
      });
  } else {
    back(0);
  }
}

// downloads
function downLoadByXMLRequest(path, errFun, filename) {
  let xhr = new XMLHttpRequest();
  xhr.open("GET", `${process.env.VUE_APP_HOST_UFO}/api/v1/s/${path}`, true);
  // xhr.setRequestHeader("x-sid", getCookie("sid"));
  xhr.responseType = "blob";
  xhr.onload = function () {
    if (this.status === 200) {
      let blob = this.response;
      let a = document.createElement("a");

      // blob.type = "application/octet-stream";
      let url = URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    } else {
      errFun(this.response);
    }
  };
  xhr.send();
}

function downloadAtHTML(data, filename) {
  let url = URL.createObjectURL(data);
  download(url, filename);
  window.URL.revokeObjectURL(url);
}

function download(url, filename) {
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", filename);
  (document.body || document.documentElement).appendChild(a);
  a.click();
  a.remove();
}

// work
function downloadForStream(path) {
  return axios({
    method: "get",
    url: `${process.env.VUE_APP_HOST_UFO}/api/v1/s/${path}`,
    responseType: "blob",
  });
}

function convertFile(path) {
  return axios.post(
    `${process.env.VUE_APP_HOST_CORE}/api/v1/files/${path}`,
    null
  );
}

function uploadFile(files, backFun) {
  let formData = new FormData();
  if (Array.isArray(files)) {
    files.forEach((f, index) => {
      formData.append(`file${index}`, f);
    });
  } else {
    formData.append("file", files);
  }
  return axios.post(`${process.env.VUE_APP_HOST_UFO}/api/v1/s`, formData, {
    onUploadProgress: (e) => {
      backFun(e.loaded, e.total);
    },
  });
}

function fileExists(path) {
  return axios.get(`${process.env.VUE_APP_HOST_UFO}/api/v1/s/${path}/metadata`);
}

function archives(path, files, suf) {
  return axios.post(
    `${process.env.VUE_APP_HOST_CORE}/api/v1/archives/${path}/${suf}`,
    files
  );
}

function login(email, password) {
  return axios({
    method: "post",
    url: `${process.env.VUE_APP_HOST_GAS}/api/auth`,
    data: { email: email, password: password },
  });
}

function getMe() {
  return axios({
    method: "get",
    url: `${process.env.VUE_APP_HOST_GAS}/api/accounts/me`,
  });
}

function sendCode(email, check = false, template = "other", language= "en") {
  return axios({
    method: "post",
    url: `${process.env.VUE_APP_HOST_GAS}/api/verification/email`,
    data: { email: email, template: `${template}-${language}`, check: check },
  });
}

function authCode(email, code) {
  return axios({
    method: "post",
    url: `${process.env.VUE_APP_HOST_GAS}/api/auth/email`,
    data: { email: email, vcode: code },
  });
}

function updateAccount(data) {
  return axios({
    method: "put",
    url: `${process.env.VUE_APP_HOST_GAS}/api/accounts/me`,
    data: data,
  });
}

function getAccountExist(email) {
  return axios({
    method: "get",
    url: `${process.env.VUE_APP_HOST_GAS}/api/accounts?email=${email}`,
  });
}

function sigup(email, password, code) {
  return axios({
    method: "post",
    url: `${process.env.VUE_APP_HOST_GAS}/api/accounts`,
    data: {
      channel: "official",
      vcode: code,
      password: password,
      email: email,
      device: { udid: `BR-${uuidv4()}` },
    },
  });
}

function getFilesOfMe(path) {
  return axios({
    method: "get",
    url: `${process.env.VUE_APP_HOST_UFO}/api/v1/s/${path}/metadata`,
  });
}

function getQuotaOfMe() {
  return axios({
    method: "get",
    url: `${process.env.VUE_APP_HOST_GAS}/api/accounts/me/quota`,
  });
}

function updatePassword(password) {
  return axios({
    method: "put",
    url: `${process.env.VUE_APP_HOST_GAS}/api/accounts/me/password`,
    data: { password: password },
  });
}

function getProducts() {
  return axios({
    method: "get",
    url: `${process.env.VUE_APP_HOST_POP}/api/products`,
  });
}

function getOrders() {
  return axios({
    method: "get",
    url: `${process.env.VUE_APP_HOST_POP}/api/accounts/me/orders?status=COMPLETED`,
  });
}

function bindEmail(email, code) {
  return axios({
    method: "put",
    url: `${process.env.VUE_APP_HOST_GAS}/api/accounts/me/email`,
    data: { email: email, vcode: code },
  });
}

function setRecents(recents, code) {
  window.localStorage.setItem(
    `${process.env.VUE_APP_STORAGE_PREX}recents-${code}`,
    JSON.stringify(recents)
  );
}

function getRecents(code) {
  let result = [];
  const _recents =
    window.localStorage.getItem(
      `${process.env.VUE_APP_STORAGE_PREX}recents-${code}`
    ) || "[]";
  try {
    result = JSON.parse(_recents);
  } catch (e) {
    console.log(e);
  }
  return result;
}

function changeRecents(code, recent) {
  if (!recent) return;
  let recents = getRecents(code);
  const _index = recents.indexOf(recent);
  if (_index >= 0) {
    recents.splice(_index, 1);
  }
  if (recents.length > 2) {
    recents.pop();
  }
  recents.unshift(recent);
  setRecents(recents, code);
}

/*
* curl -X POST \
     -H 'Content-Type: application/json' \
     -H 'Cookie: sid=4720c256-8b29-4c3c-9616-c521f930ec5c' \
     -d '{
        "ref": "X00000X/202112/xoNdTUeMBLbvfRhsy6LPN.pdf",
        "suffix": "merged",
        "files": [
          "X00000X/202112/xoNdTUeMBLbvfRhsy6LPN.pdf",
          "X00000X/202112/8Oqys_XEfe7RzmSC4JfO5.pdf"
        ]}' \
     http://localhost:8080/api/v1/pdf/merge
* */
function mergeByFile(data) {
  return axios({
    method: "post",
    url: `${process.env.VUE_APP_HOST_CORE}/api/v1/pdf/merge`,
    data,
  });
}

/*
* curl -X POST \
     -H 'Content-Type: application/json' \
     -H 'Cookie: sid=4720c256-8b29-4c3c-9616-c521f930ec5c' \
     -d '{
        "ref": "X00000X/202112/xoNdTUeMBLbvfRhsy6LPN.pdf",
        "suffix": "merged",
        "files": [
          "X00000X/202112/xoNdTUeMBLbvfRhsy6LPN.pdf",
          "X00000X/202112/8Oqys_XEfe7RzmSC4JfO5.pdf",
          "X00000X/202201/9shDYGRhd3BsM67WyEf9d.pdf"
        ], "segments": [
          { "file": 0, "page": 0 },
          { "file": 1, "page": 0 },
          { "file": 2, "page": ":40" },
          { "file": 2, "page": "81:" },
          { "file": 1, "page": 1 },
          { "file": 2, "page": "41:80" }
     ]}' \
     http://localhost:8080/api/v1/pdf/merge
*
* */
function mergeByPage(data) {
  return axios({
    method: "post",
    url: `${process.env.VUE_APP_HOST_CORE}/api/v1/pdf/merge`,
    data,
  });
}

function extract(data) {
  return axios({
    method: "post",
    url: `${process.env.VUE_APP_HOST_CORE}/api/v1/pdf/extract`,
    data,
  });
}

function splitByPage(data) {
  return axios({
    method: "post",
    url: `${process.env.VUE_APP_HOST_CORE}/api/v1/pdf/split`,
    data,
  });
}

/*
* curl -X POST \
     -H 'Content-Type: application/json' \
     -H 'Cookie: sid=4720c256-8b29-4c3c-9616-c521f930ec5c' \
     -d '{
        "files": [
          "X00000X/202112/xoNdTUeMBLbvfRhsy6LPN.pdf",
          "X00000X/202112/8Oqys_XEfe7RzmSC4JfO5.pdf"
        ], "cuts": [ { "file": 0, "page": 0 }, { "file": 1, "page": 0 } ]}' \
     http://localhost:8080/api/v1/pdf/split
* */
function splitByCuttingPoint(data) {
  return axios({
    method: "post",
    url: `${process.env.VUE_APP_HOST_CORE}/api/v1/pdf/split`,
    data,
  });
}

function toWork(local) {
  if (!local) return;
  const _host =
    process.env.NODE_ENV === "production"
      ? `https://${process.env.VUE_APP_DOMAIN}/`
      : `http://${process.env.VUE_APP_DOMAIN}:3000/`;
  window.location.href = `${_host}${local}.html`;
}

export default {
  nodeToHtml,
  downloadAtHTML,
  download,
  setCookie,
  convertFile,
  archives,
  copy,
  getSystemLanguage,
  getMaxValue,
  clearCookie,
  getCookie,
  noPassByEmail,
  getFileSuffix,
  getPercentage,
  getPathname,
  getQuery,
  uploadFile,
  getOmittedFilename,
  getFirstFilename,
  getHideString,
  getFileBaseName,
  downloadForStream,
  downLoadByXMLRequest,
  getObjectURLByFile,
  isEncryptInPdf,
  getNumPages,
  renderSize,
  getPdf,
  fileExists,
  login,
  sendCode,
  updatePassword,
  updateAccount,
  sigup,
  authCode,
  bindEmail,
  verifyEmail,
  getMe,
  getRecents,
  setRecents,
  changeRecents,
  getFilesOfMe,
  getCurrentYearAndMonth,
  getQuotaOfMe,
  getAccountExist,
  getOrders,
  mergeByFile,
  mergeByPage,
  splitByCuttingPoint,
  splitByPage,
  toWork,
  calculateDecimal,
  complementDecimal,
  getProducts,
  extract,
};
