import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
// pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.bootcss.com/pdf.js/2.12.313/pdf.worker.js";
pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdf/pdf.worker.js";
function getPDF(url) {
  const loadingTask = pdfjsLib.getDocument({
    url,
    cMapUrl: "/pdf/cmaps/",
    cMapPacked: true,
  });

  return loadingTask.promise;
}
function getPage(pdf, pageNum) {
  if (!pdf) return Promise.reject("pdf is null");
  return pdf.getPage(pageNum);
}

function directlyPainting(pdf, pageNum, canvas) {
  getPage(pdf, pageNum).then((page) => {
    painting(page, canvas);
  });
}

function painting(page, canvas, width = 100, height = 130, scale = 0.7) {
  if (!page || !canvas) return Promise.resolve();
  //
  // Fetch the first page
  //
  const viewport = page.getViewport({ scale });
  // Support HiDPI-screens.
  const outputScale = window.devicePixelRatio || 1;

  //
  // Prepare canvas using PDF page dimensions
  //
  const context = canvas.getContext("2d");

  canvas.width = Math.floor(viewport.width * outputScale);
  canvas.height = Math.floor(viewport.height * outputScale);
  // canvas.style.width = Math.floor(viewport.width) + "px";
  // canvas.style.height = Math.floor(viewport.height) + "px";
  canvas.style.width = width + "px";
  canvas.style.height = height + "px";

  const transform =
    outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

  //
  // Render PDF page into canvas context
  //
  const renderContext = {
    canvasContext: context,
    transform,
    viewport,
  };
  return page.render(renderContext).promise;
}
//
//
export { getPDF, painting, directlyPainting };
