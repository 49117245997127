<template>
  <div class="password">
    <IInput
      name="password"
      :type="isHide ? 'password' : 'text'"
      :icon="icon"
      :textFold="true"
      :placeholder="placeholder || $t('password.name')"
      :hint="hasParentHint && !!hint ? hint : defHint"
      @updateValue="updateValue"
      :hasParentHint="hasParentHint || hasHint"
      @oninput="oninput"
      @inputBlur="inputBlur"
      @inputFocus="inputFocus"
    >
      <img
        @click="toggle"
        class="eyes"
        :src="
          !isHide
            ? require('@/assets/images/account/icon-open-eyes.png')
            : require('@/assets/images/account/icon-close-eyes.png')
        "
        :class="{
          'err-icon': hasParentHint,
          'in-icon': !hasParentHint && ivalue.length > 0,
        }"
        alt="load error"
      />
    </IInput>
    <div class="grid" v-if="showGrid">
      <span :class="{ grid1: lv >= 1, grid2: lv >= 5, grid3: lv >= 7 }"></span>
      <span :class="{ grid2: lv >= 5, grid3: lv >= 7 }"></span>
      <span :class="{ grid3: lv >= 7 }"></span>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import IInput from "./IInput";
import { useI18n } from "vue-i18n";

export default {
  name: "IPassword",
  components: { IInput },
  props: {
    hasParentHint: {
      type: Boolean,
      default: false,
    },
    confirm: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: require("@/assets/images/account/icon-lock-black.png"),
    },
    placeholder: {
      type: String,
      default: "",
    },
    showGrid: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { t } = useI18n();
    let lv = ref(0);
    let ivalue = ref("");
    let hasHint = ref(false);
    let access = computed(() => {
      return lv.value >= 5;
    });
    let defHint = computed(() => {
      return lv.value < 5 && ivalue.value.length < 8
        ? t("error.passwordHintTextShort")
        : lv.value < 5 && ivalue.value.length >= 8
        ? t("error.passwordHintTextLong")
        : "";
    });

    function updateValue(e) {
      let _lv = getScore(e);

      hasHint.value = !!e && props.showGrid && _lv < 5;
      if (_lv >= 5 || !props.showGrid) {
        context.emit("updateValue", e);
      } else {
        context.emit("updateValue", "");
      }
    }
    function oninput(value) {
      ivalue.value = value;
      hasHint.value = false;
      context.emit("oninput", value);
      lv.value = getScore(value);
      if (!value) lv.value = 0;
    }
    function inputBlur(value) {
      ivalue.value = value;
      lv.value = getScore(value);
      if (!value) lv.value = 0;
      context.emit("inputBlur", value);
    }
    function inputFocus() {
      lv.value = getScore(ivalue.value);
    }

    const _charMap = {
      special: [
        33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 58, 59, 60,
        61, 62, 63, 64, 91, 93, 94, 95, 96, 123, 125, 126,
      ],
      num: [48, 49, 50, 51, 52, 53, 54, 55, 56, 57],
      lowercase: [
        97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111,
        112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122,
      ],
      uppercase: [
        65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82,
        83, 84, 85, 86, 87, 88, 89, 90,
      ],
    };
    function getStyleCount(password) {
      let count = {};
      for (let i = 0; i < password.length; i++) {
        for (let t in _charMap) {
          if (_charMap[t].indexOf(password.charCodeAt(i)) > 0) {
            count[t] = 1;
          }
        }
      }
      if (Object.values(count).length < 1) return 0;
      return Object.values(count).reduce((prev, curr) => prev + curr);
    }
    function getScore(password) {
      return getStyleCount(password) + getLengthScore(password);
    }
    function getLengthScore(password) {
      let result = 0;
      if (password.length <= 7) result = 0;
      if (password.length > 7 && password.length <= 9) result = 3;
      if (password.length > 9 && password.length <= 11) result = 4;
      if (password.length >= 12) result = 5;
      return result;
    }

    let isHide = ref(true);
    function toggle() {
      isHide.value = !isHide.value;
    }

    return {
      lv,
      inputBlur,
      access,
      defHint,
      hasHint,
      ivalue,
      toggle,
      isHide,
      updateValue,
      oninput,
      inputFocus,
    };
  },
  created() {},
};
</script>

<style scoped>
.password {
  position: relative;
}
.password .eyes {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.grid {
  position: absolute;
  right: 0;
  top: 54px;
  height: 4px;
  display: flex;
}
.grid > span {
  display: inline-block;
  height: 100%;
  width: 26px;
  border-radius: 1px;
  background: #d9d9d9;
}
.grid > span:not(:last-child) {
  margin-right: 2px;
}
.grid .grid1 {
  background: #fb3636;
}
.grid .grid2 {
  background: #fb9536;
}
.grid .grid3 {
  background: #00cb08;
}

.err-icon {
  filter: invert(124%) sepia(142%) saturate(6248%) hue-rotate(344deg)
    brightness(141%) contrast(191%);
}

.in-icon,
.focus img {
  filter: brightness(0);
}

@media all and (max-width: 1020px) and (min-width: 320px) {
  .password {
    width: 100%;
  }
}
</style>
