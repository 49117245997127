<template>
  <div class="convert-err">
    <div class="head">
      <div class="title" v-html="$t('convertPage.convertErrTitle')"></div>

      <div v-if="errCode" v-html="$t(`error.convertERR${errCode}`)"></div>
      <div v-else v-html="$t('error.unknownError')"></div>
    </div>
    <div>
      <button
        class="but"
        @click="back"
        :style="{ backgroundColor: buttonColor }"
      >
        <img
          class="small-icon small-icon-8"
          src="@/assets/images/work/icon_restart.png"
          alt="load error"
        />{{ $t("convertPage.startOver") }}
      </button>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "ConvertErr",
  components: {},
  props: {
    buttonColor: {
      default: "#00a3ff",
      type: String,
    },
    errCode: {
      default: "500",
      type: String,
    },
  },
  setup() {
    let useStores = useStore();
    function back() {
      useStores.commit("change", { key: "workStatus", value: "chooseFile" });
    }
    return {
      back,
    };
  },
};
</script>

<style scoped>
.convert-err {
  padding-top: 40px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.convert-err .head {
  margin-bottom: 28px;
}
.convert-err > div {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.convert-err .title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 14px;
}

@media all and (max-width: 640px) and (min-width: 320px) {
  .convert-err {
    justify-content: flex-start;
    box-sizing: border-box;
    padding-top: 45px;
  }
}
</style>
