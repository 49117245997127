<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00002 14.969C4.15752 14.969 1.03101 11.843 1.03101 8.00002C1.03101 4.15752 4.15749 1.03101 8.00002 1.03101C11.843 1.03101 14.969 4.15701 14.969 8.00002C14.969 11.843 11.843 14.969 8.00002 14.969ZM8.00002 2.03101C4.70852 2.03101 2.03101 4.7085 2.03101 8.00002C2.03101 11.291 4.7085 13.969 8.00002 13.969C11.291 13.969 13.969 11.2915 13.969 8.00002C13.969 4.7085 11.291 2.03101 8.00002 2.03101Z"
      :fill="color" />
    <circle cx="8" cy="8" r="7" :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8 5.5C7.44771 5.5 7 5.05229 7 4.5C7 3.94771 7.44771 3.5 8 3.5C8.55229 3.5 9 3.94771 9 4.5C9 5.05229 8.55229 5.5 8 5.5ZM8 6.5C7.44772 6.5 7 6.94772 7 7.5V11.5C7 12.0523 7.44772 12.5 8 12.5C8.55228 12.5 9 12.0523 9 11.5V7.5C9 6.94772 8.55228 6.5 8 6.5Z"
          fill="white" />
  </svg>

</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Hint",
  props: {
    iwidth: {
      type: String,
      default: "16"
    },
    iheight: {
      type: String,
      default: "16"
    },
    color: {
      type: String,
      default: "#C4C4C4"
    }
  }
});
</script>
