<template>
  <div class="brand">
    <img
      v-if="route.path === '/login'"
      src="@/assets/images/brand/brand.png"
      alt="load error"
    />
    <img
      v-if="route.path === '/signUp'"
      src="@/assets/images/brand/signup.png"
      alt="load error"
    />
  </div>
</template>

<script>
import { useRoute } from "vue-router";

export default {
  name: "Brand",
  components: {},
  setup() {
    let route = useRoute();
    return { route };
  },
  created() {},
};
</script>

<style scoped>
.brand {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.brand img {
  width: 204px;
  height: 150px;
}
</style>
