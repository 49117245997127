import { createStore } from "vuex";
import { files } from "@/common/fileRule";
import { tools } from "@/common/tools";
import utils from "../util/utils";
import { work } from "@/router/work";
import { AccountStoreModule } from "@/store/account";

// Create a new store instance.
const store = createStore({
  modules: {
    account: AccountStoreModule,
  },
  state() {
    return {
      currentFile: {
        downloadFileUrl: "",
        downFileName: "",
        downFullFileName: "",
        convertFileName: "",
        convertFullFileName: "",
        convertFileUrl: "",
        convertAfterFiles: [],
        archiveBeforeUrl: "",
      },
      workStatus: "chooseFile",
      work: work,
      workLimitType: "",
      files: files,
      tools,
      currentRule: files[tools[work].from],
      themeColor: "#ffffff",
      isLogin: false,
      recents: [],
    };
  },
  mutations: {
    reset(state) {
      state.isLogin = false;
      state.workStatus = "chooseFile";
      // state.work = "ptw";
      state.currentRule = files.pdf;
    },
    changeLogin(state, isLogin) {
      state.isLogin = isLogin;
    },
    changeRecents(state, recent) {
      if (!recent) return;
      const _index = state.recents.indexOf(recent);
      if (_index >= 0) {
        state.recents.splice(_index, 1);
      }
      if (state.recents.length > 2) {
        state.recents.pop();
      }
      state.recents.unshift(recent);
      utils.setRecents(state.recents, state.account.code);
    },
    changeCurrentFileProperty(state, property) {
      state.currentFile[property.key] = property.value;
    },
    clearCurrentFileProperty(state) {
      state.currentFile = {
        downloadFileUrl: "",
        downFileName: "",
        downFullFileName: "",
        convertFileName: "",
        convertFullFileName: "",
        convertFileUrl: "",
        convertAfterFiles: [],
      };
    },
    change(state, data) {
      state[data["key"]] = data["value"];
    },
    changeWorkStatus(state, currentWorkStatus) {
      state.workStatus = currentWorkStatus;
    },
    changeCurrentRule(state, work) {
      state.currentRule = files[tools[work].from];
    },
  },
});
export default store;
