import { computed } from "vue";
import { tools } from "@/common/tools";

let otherTools = computed(() => {
  let _tools = {};
  Object.keys(tools).forEach((toolKey) => {
    if (tools[toolKey].type !== "convert") {
      _tools[toolKey] = tools[toolKey];
    }
  });
  return _tools;
});
let toPDFTools = computed(() => {
  let _tools = {};
  Object.keys(tools).forEach((toolKey) => {
    if (tools[toolKey].type === "convert" && tools[toolKey].to === "pdf") {
      _tools[toolKey] = tools[toolKey];
    }
  });
  return _tools;
});
let fromPDFTools = computed(() => {
  let _tools = {};
  Object.keys(tools).forEach((toolKey) => {
    if (tools[toolKey].type === "convert" && tools[toolKey].from === "pdf") {
      _tools[toolKey] = tools[toolKey];
    }
  });
  return _tools;
});

export { fromPDFTools, otherTools, toPDFTools };
