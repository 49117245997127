<template>
  <div class="merge" v-show="status === 'mergeOrSplitBefore'">
    <div class="file-name pc">
      <div
        v-for="(name, index) in names"
        :key="'merge-file-name-list-item-' + index + name"
      >
        <img :src="getFileTypeImgUrl(name)" alt="file-icon" />
        <span>{{ utils.getOmittedFilename(name) }}</span>
      </div>
      <div v-if="isMultipleFile">
        <img :src="getFileTypeImgUrl('aaa.pdf')" alt="more" />
        <span>...</span>
      </div>
    </div>

    <div class="file-name mb">
      <div>
        <img  v-if="numFiles === 1" :src="getFileTypeImgUrl(names[0])" alt="file icon" />
        <img  v-else src="@/assets/images/file/icon_more_file.svg" alt="file icon" />
        <span v-if="numFiles > 1">{{ `${numFiles} ${$t("mergePage.files")}`}}</span>
        <span v-else>{{ utils.getOmittedFilename(names[0]) }}</span>
      </div>

    </div>

    <div
      class="merge-select"
      :class="{
        checked: selectText === 'file' && currentStatus !== 'uploading',
        'select-right': store.work === 'split',
      }"
      @click="selectViewFile"
    >
      <var-radio
        unchecked-color="#D9D9D9"
        checked-color="#00A3FF"
        v-model="selectText"
        icon-size="18px"
        :disabled="currentStatus === 'uploading'"
        :checked-value="currentStatus === 'uploading' ? 'uploading' : 'file'"
        :style="{
          '--radio-action-padding' : '2px 0 0 0'
        }"
      >
      </var-radio>
      <div>
        <span class="title"
          >{{ $t(`${store.work}Page.${store.work}File`) }}
          <Badge v-if="payType !== 'paid' && store.work === 'split'"></Badge>
        </span>
        <span class="desc">{{
          $t(`${store.work}Page.${store.work}FileDesc`)
        }}</span>
      </div>
    </div>
    <div
      class="merge-select"
      :class="{
        checked: selectText === 'page'  && currentStatus !== 'uploading',
        'select-left': store.work === 'split',
      }"
      @click="selectViewPage"
    >
      <var-radio
        unchecked-color="#D9D9D9"
        checked-color="#00A3FF"
        v-model="selectText"
        :disabled="currentStatus === 'uploading'"
        icon-size="18px"
        :checked-value="currentStatus === 'uploading'? 'uploading' : 'page'"
        :style="{
          '--radio-action-padding' : '2px 0 0 0'
        }"
      >
      </var-radio>
      <div>
        <span class="title"
          >{{ $t(`${store.work}Page.${store.work}Page`) }}
          <Badge v-if="payType !== 'paid' && store.work === 'merge'"></Badge>
        </span>
        <span class="desc">{{
          $t(`${store.work}Page.${store.work}PageDesc`)
        }}</span>
      </div>
    </div>

    <div class="file-property pc">
      <span>{{ numFiles }} {{ $t(`${store.work}Page.files`) }}</span>
      <span>{{ totalSize }}</span>

    </div>

    <div class="add-file mb" @click="addFile">
      <img
        v-if="currentStatus === 'uploading'"
        src="@/assets/images/loading.svg"
        alt="loading"
      />
      <img
        v-else
        class="add-file-icon"
        src="@/assets/images/view-pdf/icon_add.svg"
        alt="add"
      />
      <span>{{ $t(`${store.work}Page.addMore`) }}</span>
    </div>

    <div class="action">
      <button @click="next" :class="{ 'action-selected': selectText === '' || currentStatus === 'uploading'}" >
        {{ $t("action.next") }}
      </button>
    </div>
  </div>
  <div
    class="merge-view"
    :style="{
      'align-items':
        store.work === 'split' && selectText === 'file'
          ? 'flex-start'
          : 'center',
    }"
    v-show="status === 'mergeOrSplitView'"
  >
    <div class="nav">
      <div
        class="flex"
        :class="{
          'left-nav': store.work === 'merge',
          'right-nav': store.work === 'split',
          'checked-nav': selectText === 'file',
        }"
        @click="toFilesView"
      >
        {{
          $t(
            `${store.work}Page.${
              store.work === "merge" ? "navLeftText" : "navRightText"
            }`
          )
        }}
        <Badge
          v-if="payType !== 'paid' && store.work === 'split'"
          :isRadiusBadge="true"
        ></Badge>
      </div>
      <div
        class="flex"
        :style="{ order: store.work === 'split' ? -1 : 1 }"
        :class="{
          'right-nav': store.work === 'merge',
          'left-nav': store.work === 'split',
          'checked-nav': selectText === 'page',
        }"
        @click="toPagesView"
      >
        {{
          $t(
            `${store.work}Page.${
              store.work === "merge" ? "navRightText" : "navLeftText"
            }`
          )
        }}
        <Badge
          v-if="payType !== 'paid' && store.work === 'merge'"
          :isRadiusBadge="true"
        ></Badge>
      </div>
    </div>
    <div class="merge-view-main" @touchstart="hideMask">
      <div v-if="store.work === 'merge'">
        <div
          class="flex tip merge-tip"
          :class="{'merge-tip-show': !(store.work === 'merge' && !longPressed)}"
        >
          <Hint></Hint>
          <span>{{$t('mergePage.tip')}}</span>
        </div>
        <div v-show="fileCanvass.length > 0 && selectText === 'file'">
          <draggable
            :class="{ dragging: dragging }"
            :list="fileCanvass"
            :disabled="store.work === 'split' || (getIsMobile() ? !longPressed  : false ) || currentStatus === 'working'"
            class="canvass"
            :delay="getIsMobile() ? '200' : '0'"
            :style="canvassStyle"
            item-key="id"
            @start="draggableStart"
            @end="draggableEnd"
          >
            <template #item="{ element, index }">
              <PdfView
                :ref="
                  (el) => {
                    if (el) pdfFileViews[index] = el;
                  }
                "
                :index="index"
                :page="element"
                :toRender="element.toRender"
                :canOptional="store.work === 'split'"
                :edit="longPressed"
                :readyDrag="readyDrag"
                @hideMask="hideMask"
                @onReadyDrag="onReadyDrag"
                @onReadyDragEnd="onReadyDragEnd"
                @onLongPressed="onLongPressed"
                @enlarge="enlarge"
                @remove="remove"
                @choose="choose"
              >
              </PdfView>
            </template>
          </draggable>
        </div>
      </div>
      <div v-else>
        <div v-show="selectText === 'file'" class="canvass canvass-column">
          <div
            v-for="(element, index) in pageCanvass"
            :key="`split-select-${index}`"
          >
            <PdfView
              :ref="
                (el) => {
                  if (el) pdfFileViews[index] = el;
                }
              "
              :style="canvassStyle"
              :index="index"
              :page="element"
              :isLast="index === pageCanvass.length - 1"
              :isCut="true"
              :toRender="element.toRender"
              :canOptional="false"
              @cut="cut"
              @paintingNextPage="paintingNextPage"
            />
          </div>
        </div>
      </div>
      <div v-show="pageCanvass.length > 0 && selectText === 'page'">
        <div
          class="flex tip split-tip"
          v-if="store.work === 'split'"
          v-html="splitTip"
        ></div>
        <draggable
          :class="{ dragging: dragging }"
          :list="pageCanvass"
          :disabled="store.work === 'split' || (getIsMobile() ? !(longPressed) : false ) || currentStatus === 'working'"
          class="canvass"
          :delay="getIsMobile() ? '200' : '0'"
          :style="canvassStyle"
          item-key="id"
          @start="draggableStart"
          @end="draggableEnd"
        >
          <template #item="{ element, index }">
            <PdfView
              :ref="
                (el) => {
                  if (el) pdfPageViews[index] = el;
                }
              "
              :index="index"
              :page="element"
              :toRender="element.toRender"
              :canOptional="store.work === 'split'"
              :edit="longPressed"
              :readyDrag="readyDrag"
              @hideMask="hideMask"
              @onReadyDrag="onReadyDrag"
              @onReadyDragEnd="onReadyDragEnd"
              @onLongPressed="onLongPressed"
              @enlarge="enlarge"
              @remove="remove"
              @choose="choose"
              @paintingNextPage="paintingNextPage"
            >
            </PdfView>
          </template>
        </draggable>
      </div>
    </div>

    <div
      class="merge-view-footer"
      :class="{'column-footer' : store.work === 'split'}"
    >
      <Bar
        class="bar"
        v-if="currentStatus === 'uploading' || currentStatus === 'working'"
        :style="{
          borderRadius: '0px',
          width: '100%',
          height: '6px',
          backgroundColor: store.tools[store.work].textColor,
          lineBackgroundColor: store.tools[store.work].activeBackground,
        }"
      ></Bar>
      <div class="err-hint flex" v-if="isAddFileError">
        <div class="err-hint-info flex">
          <img src="@/assets/images/icon_prompt.png" alt="add" />
          <span>
            <span class="hint-name"> {{ addErrorFileName }} </span>&nbsp;
            <span>
              {{ $t("mergePage.addErrorText") }}
            </span>
          </span>
        </div>
        <img
          @click.stop="closeErrorHint"
          class="cancel"
          src="@/assets/images/icon_cancel.png"
          alt="cancel"
        />
      </div>
      <div class="hint flex" v-show="longPressed && !dragging && !longPressedOver">
        <Hint color="#02A4FF"></Hint>
        <span>
          {{ $t("mergePage.editHint") }}
        </span>
      </div>
      <div v-if="currentStatus === 'uploading'" class="status-tag left-tag">
        <img src="@/assets/images/work/icon_upload.png" alt="upload" />
        <span>{{ $t("convertPage.uploading") }}...</span>
      </div>
      <div v-else-if="currentStatus === 'working'" class="status-tag left-tag">
        <img src="@/assets/images/loading.svg" alt="working" />
        <span v-if="store.work === 'split' && selectText === 'file'">{{
          $t(`${store.work}Page.workLoadingSplitText`)
        }}</span>
        <span v-else>{{ $t(`${store.work}Page.workLoadingText`) }}</span>
      </div>
      <div v-else-if="!(store.work === 'merge' && longPressed)" class="status-tag">
        <div @click="addFile">
          <img
            :style="styleButton"
            class="add-file-icon"
            src="@/assets/images/view-pdf/icon_add.svg"
            alt="add"
          />
          <span>{{ $t(`${store.work}Page.addMore`) }}</span>
        </div>
        <div
          v-if="store.work === 'split'"
          class="select-all"
          @click="selectedAll"
        >
          <div
            v-if="
              (selectText === 'page' && !allSelected) ||
              (selectText === 'file' && !cutAllSelected)
            "
            class="flex"
            :class="{
              'not-selected':
                (selectText === 'page' && !allSelected) ||
                (selectText === 'file' && !cutAllSelected),
            }"
          ></div>
          <img
            v-else
            src="@/assets/images/view-pdf/icon-selected-black.png"
            alt="check"
          />
          <span v-if="selectText === 'page'">
            {{ $t(`${store.work}Page.selectAll`) }}
          </span>
          <span v-else>
            {{ $t(`${store.work}Page.SplitAllPage`) }}
          </span>
        </div>
      </div>
      <div class="flex but-parent">
        <div
          class="pc"
          v-if="
            store.work === 'split' && selectText === 'page' && selectedPages > 0
            && currentStatus === ''
          "
        >
          1 PDF ({{ selectedPages }}
          {{ selectedPages === 1 ? $t('splitPage.page')  : $t('splitPage.pages')  }})
        </div>
        <div
          class="pc"
          v-if="
            store.work === 'split' && selectText === 'file' && cuts.length > 0
            && currentStatus === ''
          "
        >
          {{ cuts.length + 1 }} PDFs
        </div>
        <button
          v-if="!(store.work === 'merge' && longPressed)"
          :class="{
            pc: store.work === 'split',
            going: !!currentStatus,
            notWork:
              store.work === 'split' &&
              !(
                (selectText === 'page' && currentSelectedCanvass.length > 0) ||
                (selectText === 'file' && cuts.length > 0)
              ),
          }"
          :style="styleButton"
          @click="toWork"
        >
          {{
            store.work === "split" && selectText === "file"
              ? $t(`${store.work}Page.workButtonSplitText`)
              : $t(`${store.work}Page.workButtonText`)
          }}
        </button>
      </div>
      <button
        class="long-button"
        :style="styleButton"
        @click="exitLongPressed()"
        v-if="longPressed"
      >
        {{$t('mergePage.exitEditButtonText')}}
      </button>
      <button
        class="long-button mt-24"
        v-if="store.work === 'split'"
        :class="{
            mb: store.work === 'split',
            going: !!currentStatus,
            notWork:
              store.work === 'split' &&
              !(
                (selectText === 'page' && currentSelectedCanvass.length > 0) ||
                (selectText === 'file' && cuts.length > 0)
              ),
          }"
        :style="styleButton"
        @click="toWork"
      >
        {{
          store.work === "split" && selectText === "file"
            ? `${cuts.length > 0 ? splitLongButtonText : $t('splitPage.workButtonSplitText')}`
            : `
              ${
                (store.work === 'split' && selectText === 'page' && selectedPages > 0)
                  ? $t(`${store.work}Page.workButtonMobileText`) + ' (' + (selectedPages + (selectedPages === 1 ? $t('splitPage.page') : $t('splitPage.pages') )) + ')'
                  : $t('splitPage.workButtonText')
                }
              `
        }}
      </button>
    </div>
  </div>
  <input
    @click.stop=""
    multiple="multiple"
    :accept="store.currentRule.accept"
    @change="changeInput"
    ref="fileInput"
    type="file"
    name="file"
    hidden
  />
  <div class="mask" v-show="isEnlarge" @click="shrink">
    <div class="mask-action" @click.stop="">
      <img
        v-if="currentPage && currentPageImgUrl"
        @click.stop=""
        class="enlarged"
        :src="currentPageImgUrl"
        alt="enlarged"
      />
      <span class="tags-name" v-show="isEnlarge && selectText === 'page'">
        {{ currentPage ? currentPage.originalName : "" }}
      </span>
      <div class="tags">
        <i @click.stop="prePage" class="flex">
          <Direc
            dir="left"
            :color="currentIndex <= 0 ? '#696969' : '#ffffff'"
          ></Direc>
        </i>

        <span class="tags-file-name">
          {{ currentPage ? currentPage.name : "" }}
        </span>

        <i @click.stop="nextPage" class="flex">
          <Direc
            dir="right"
            :color="currentIndex + 1 >= totalPage ? '#696969' : '#ffffff'"
          ></Direc>
        </i>
      </div>
      <img
        class="quit-img"
        @click.stop="shrink"
        src="@/assets/images/view-pdf/icon-view-quit.svg"
        alt="cancel"
      />
    </div>
  </div>
</template>

<script>
import {
  ref,
  computed,
  reactive,
  watch,
  inject,
  onMounted,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";

import PdfView from "@/components/PdfView";
import Badge from "@/components/Badge";
import Bar from "@/components/Bar";
import Hint from "@/components/icons/Hint";
import Direc from "@/components/icons/Direc";

import draggable from "vuedraggable";
import { getPDF } from "@/common/pdfUtils";
import utils from "@/util/utils";
import { useI18n } from "vue-i18n";
import { i18nHTML, i18nReplace } from "@/language/i18n";


export default {
  name: "Merge",
  components: { PdfView, draggable, Badge, Bar, Hint, Direc },
  emits: ["mergeOrSplitView", "backErr"],
  props: {
    status: {
      type: String,
      default: "mergeOrSplitBefore",
    },
    files: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    let files = reactive(props.files.files);
    function createPath(originalPaths) {
      if (!Array.isArray(originalPaths)) return [];
      return originalPaths.map((p) => {
        return {
          name: p.metadata.ofn,
          path: p.path,
        };
      });
    }
    let paths = createPath(props.files.paths);

    let useStores = useStore();
    let store = useStores.state;

    let showCanvas = ref(null);

    let selectText = ref("");
    let selectTextAndCurrentStatusText = computed(() => {
      return selectText.value + currentStatus.value;
    });

    function selectViewFile() {
      if (currentStatus.value !== 'uploading') selectText.value = 'file'
    }
    function selectViewPage() {
      if (currentStatus.value !== 'uploading') selectText.value = 'page'
    }

    function getFileTypeImgUrl() {
      return store.files["mergepdf"].iconSmall;
    }    
    function getFirst4Filename() {
      let firstFilenames = names.value.map(n => utils.getFirstFilename(n) + "...");
      return firstFilenames.join(", ");
    }

    let isMultipleFile = computed(() => {
      return files.length > 2;
    });

    let totalSize = computed(() => {
      return utils.renderSize(
        files.reduce((prev, curr) => {
          return prev + curr.size;
        }, 0)
      );
    });

    let names = computed(() => {
      let filesName = files.map((file) => file.name);
      return filesName.length > 2 ? filesName.slice(0, 2) : filesName;
    });
    let numFiles = computed(() => {
      return files.length;
    });

    const payType = inject("payType");
    const mobileHeadTopup = inject("mobileHeadTopup");

    let loginUrl = `/index.html#/login?from=work&work=${store.work}`;
    let productUrl = `/index.html#/product?from=work&work=${store.work}&path=${store.currentFile.convertFileUrl}`;
    function next() {
      if (selectText.value === "" || currentStatus.value === "uploading") return;
      if (store.work === "merge") {
        if (selectText.value === "page" && !store.isLogin) {
          if (getIsMobile()) mobileHeadTopup();
          else window.location.href = loginUrl;
        } else if (
          selectText.value === "page" &&
          store.isLogin &&
          payType.value !== "paid"
        ) {
          window.location.href = productUrl;
        } else {
          context.emit("mergeOrSplitView", selectText.value);
        }
      } else if (store.work === "split") {
        if (selectText.value === "file" && !store.isLogin) {
          if (getIsMobile()) mobileHeadTopup();
          else window.location.href = loginUrl;
        } else if (
          selectText.value === "file" &&
          store.isLogin &&
          payType.value !== "paid"
        ) {
          window.location.href = productUrl;
        } else {
          context.emit("mergeOrSplitView", selectText.value);
        }
      }
    }
    function toFilesView() {
      if (store.work === "merge") {
        selectText.value = "file";
        return;
      }
      if (!store.isLogin) {
        if (getIsMobile()) mobileHeadTopup();
        else window.location.href = loginUrl;
      } else if (store.isLogin && payType.value !== "paid") {
        window.location.href = productUrl;
      } else {
        selectText.value = "file";
      }
    }
    function toPagesView() {
      if (store.work === "split") {
        selectText.value = "page";
        return;
      }
      if (!store.isLogin) {
        if (getIsMobile()) mobileHeadTopup();
        else window.location.href = loginUrl;
      } else if (store.isLogin && payType.value !== "paid") {
        window.location.href = productUrl;
      } else {
        selectText.value = "page";
      }
    }

    let fileCanvass = reactive([]);
    let pageCanvass = reactive([]);

    let currentCanvass = computed(() => {
      if (selectText.value === "file") {
        return fileCanvass;
      } else if (selectText.value === "page") {
        return pageCanvass;
      }
      return [];
    });
    let totalPage = computed(() => {
      return currentCanvass.value.length;
    });

    async function createData(files, paths) {
      if (!files || !Array.isArray(files)) return;

      let initNum = fileCanvass.length;

      window.setTimeout(async () => {
        for (let index = 0; index < files.length; index++) {
          const file = files[index];
          let _path = paths.find((p) => p.name === file.name);
          let pdf = null;
          try {
            pdf = await getPDF(utils.getObjectURLByFile(file));
          } catch (_) {
            continue;
          }

          try {
            await pdf.getPage(1).then((page) => {
              fileCanvass.push({
                id: _path.path + file.name + 1,
                originalName: file.name,
                name: file.name,
                viewType: "File",
                canvas: page,
                numPage: 1,
                path: _path.path,
                toRender: true,
                selected: false,
              });
            });
          } catch (_) {
            console.log("get page fail");
          }
        }
      }, 0);

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        let _path = paths.find((p) => p.name === file.name);
        let pdf = null;
        try {
          pdf = await getPDF(utils.getObjectURLByFile(file));
        } catch (_) {
          isAddFileError.value = true;
          addErrorFileName.value = addErrorFileName.value
            ? addErrorFileName.value
            : file.name;
          console.log("get pdf fail");
          continue;
        }

        for (let i = 1; i <= pdf.numPages; i++) {
          try {
            await pdf.getPage(i).then((page) => {
              pageCanvass.push({
                id: _path.path + file.name + i,
                originalName: file.name,
                name: String.fromCharCode(initNum + 65 + index) + i,
                canvas: page,
                viewType: "Page",
                numPage: i,
                path: _path.path,
                toRender: i === 1 || pageStopRenderIndex.value === pageCanvass.length - 1,
                selected: false,
                cuted: false,
                cutIndex: null,
              });
            });
          } catch (_) {
            console.log("get page fail");
          }
        }
      }
      setCutIndex();
    }

    let pageStopRenderIndex = ref(0);

    function paintingNextPage(prevIndex) {
      if (prevIndex !== -1 && prevIndex < pageCanvass.length) {
        pageCanvass[prevIndex].canvas = null;
      }
      prevIndex++;
      if (prevIndex < pageCanvass.length) {
        pageCanvass[prevIndex].toRender = true;
      } else {
        pageStopRenderIndex.value = prevIndex - 1;
      }
    }

    function paintingNextFile(prevIndex) {
      if (prevIndex !== -1 && prevIndex < fileCanvass.length) {
        fileCanvass[prevIndex].canvas = null;
      }
      prevIndex++;
      if (prevIndex < fileCanvass.length) {
        fileCanvass[prevIndex].toRender = true;
      }
    }

    let pageLongPressed = ref(false);
    let pageLongPressedOver = ref(false);
    let fileLongPressed = ref(false);
    let fileLongPressedOver = ref(false);


    function getIsMobile() {
      return window.document.body.clientWidth < 765;
    }

    let readyDrag = ref(false);
    function onReadyDrag() {
      readyDrag.value = true;
    }

    let longPressed = computed(() => {
      if (selectText.value === 'file') {
        return  fileLongPressed.value;
      }
      if (selectText.value === 'page') {
        return  pageLongPressed.value;
      }
      return false;
    });
    let longPressedOver = computed(() => {
      if (selectText.value === 'file') {
        return  fileLongPressedOver.value;
      }
      if (selectText.value === 'page') {
        return  pageLongPressedOver.value;
      }
      return false;
    });

    function onLongPressed() {
      if (currentStatus.value === 'working') return;
      if (store.work === 'merge') {
        if (selectText.value === 'file') {
          fileLongPressed.value = true;
        }
        if (selectText.value === 'page') {
          pageLongPressed.value = true;
        }
      }
    }

    function exitLongPressed() {
      if (store.work === 'merge') {
        if (selectText.value === 'file') fileLongPressed.value = false;
        if (selectText.value === 'page') pageLongPressed.value = false;
      }
    }


    function remove(index) {
      currentCanvass.value.splice(index, 1);
    }

    function hideMask() {
      currentPdfViews.value.filter(v => v.readyRemove).forEach(view => {
        view.readyRemove = false;
      })
    }

    let selectedPages = computed(() => {
      return pageCanvass.filter((p) => p.selected).length;
    });

    let splitLongButtonText = computed(() => {
      if (store.work !== 'split') return "";
      let rep = [{ totalNumber: (cuts.value.length + 1).toString() }];
      return i18nReplace(t(`${store.work}Page.workButtonSplitMobileText`), rep);
    });


    // enlarge pdf view
    let pdfFileViews = reactive([]);
    let pdfPageViews = reactive([]);
    let currentIndex = ref(0);
    let isEnlarge = ref(false);

    let currentPdfViews = computed(() => {
      if (selectText.value === "file") {
        return pdfFileViews;
      }
      if (selectText.value === "page") {
        return pdfPageViews;
      }
      return [];
    });

    let currentPageImgUrl = computed(() => {
      let _url = "";
      try {
        _url = currentPdfViews.value[currentIndex.value].$el
          .querySelector("canvas")
          .toDataURL("image/png");
      } catch (e) {
        console.log(e);
      }
      return _url;
    });

    let currentPage = computed(() => {
      return currentCanvass.value[currentIndex.value];
    });

    function shrink() {
      isEnlarge.value = false;
    }

    function enlarge(index) {
      if (currentStatus.value === "working") return;
      isEnlarge.value = true;
      currentIndex.value = index;
    }

    // prev page
    function prePage() {
      if (currentIndex.value > 0) {
        currentIndex.value = currentIndex.value - 1;
      }
    }

    // next page
    function nextPage() {
      if (
        selectText.value === "file" &&
        currentIndex.value < fileCanvass.length - 1
      ) {
        currentIndex.value = currentIndex.value + 1;
      }
      if (
        selectText.value === "page" &&
        currentIndex.value < pageCanvass.length - 1
      ) {
        currentIndex.value = currentIndex.value + 1;
      }
    }

    // No scrolling
    watch(
      isEnlarge,
      (newValue) => {
        if (newValue) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      },
      { immediate: true }
    );

    let currentStatus = ref("");
    // add files
    let isAddFileError = ref(false);
    let addErrorFileName = ref("");
    let fileInput = ref(null);
    function addFile() {
      addErrorFileName.value = "";
      isAddFileError.value = false;
      fileInput.value.click();
    }
    function changeInput(e) {
      let _files = Object.values(e.target.files);
      let isMultiple = _files.length > 1;
      currentStatus.value = "uploading";
      utils
        .uploadFile(_files, () => {})
        .then((res) => {
          let _paths = isMultiple
            ? createPath(res.data)
            : createPath([res.data]);
          files.push(_files);
          createData(
            _files,
            _paths,
            pageCanvass.length - 1,
            fileCanvass.length - 1
          );

          useStores.commit("changeCurrentFileProperty", {
            key: "numFile",
            value: _files.length,
          });
        })
        .catch((e) => {
          console.log(e);
          isAddFileError.value = true;
          addErrorFileName.value = _files[0].name;
        })
        .finally(() => {
          currentStatus.value = "";
        });
      fileInput.value.value = null;
    }

    function backErr(e) {
      if (e && e.response && e.response.status) {
        try {
          context.emit("backErr", e.response.status.toString());
        } catch (e) {
          console.log(e);
        }
      }
      useStores.commit("changeWorkStatus", "convertErr");
    }
    let putFileAttribute = inject("putFileAttribute");
    let setFileAttr = inject("setFileAttr");

    let styleButton = computed(() => {
      return {
        "--button-color": store.tools[store.work].textColor,
      };
    });
    function toWork() {
      // if (currentStatus.value !== '') return;
      if (store.work === "merge") {
        merge();
      } else if (store.work === "split") {
        split();
      }
    }
    function getFileIndexPathMap(source) {
      let fileIndexPathMap = {};
      let count = 0;
      source.forEach((p) => {
        if (!Object.keys(fileIndexPathMap).includes(p.path)) {
          fileIndexPathMap[p.path] = count;
          count++;
        }
      });
      return fileIndexPathMap;
    }
    // to split
    function split() {
      if (
        (selectText.value === "page" &&
          currentSelectedCanvass.value.length <= 0) ||
        (selectText.value === "file" && cuts.value.length <= 0)
      )
        return;
      currentStatus.value = "working";
      if (selectText.value === "page") {
        let selectedPageCanvass = pageCanvass.filter((p) => p.selected);
        let selectedMap = getFileIndexPathMap(selectedPageCanvass);
        let files = [];
        Object.keys(selectedMap).forEach((p) => {
          files[Number(selectedMap[p])] = p;
        });
        utils
          .extract({
            files,
            segments: getSegments(selectedMap, selectedPageCanvass),
          })
          .then((res) => {
            toDownload(res.data);
          })
          .catch((e) => {
            backErr(e);
            console.log(e);
          })
          .finally(() => {
            currentStatus.value = "";
          });
      } else {
        if (cuts.value.length === pageCanvass.length) {
          let AllFilesMap = getFileIndexPathMap(pageCanvass);
          let files = [];
          Object.keys(AllFilesMap).forEach((p) => {
            files[Number(AllFilesMap[p])] = p;
          });
          utils
            .splitByPage({
              files,
            })
            .then((res) => {
              toDownload(res.data);
            })
            .catch((e) => {
              backErr(e);
              console.log(e);
            })
            .finally(() => {
              currentStatus.value = "";
            });
        } else {
          let cutsFilesMap = getFileIndexPathMap(fileCanvass);
          let files = [];
          Object.keys(cutsFilesMap).forEach((p) => {
            files[Number(cutsFilesMap[p])] = p;
          });
          let _cuts = [];
          pageCanvass.forEach((c, index) => {
            if (c.cuted) {
              _cuts.push({ file: cutsFilesMap[c.path], page: c.numPage - 1 });
            }
            if (index === pageCanvass.length - 1) {
              _cuts.push({ file: cutsFilesMap[c.path], page: c.numPage - 1 });
            }
          });
          utils
            .splitByCuttingPoint({
              files,
              cuts: _cuts,
            })
            .then((res) => {
              try {
                res.data[0].metadata.ofn = props.files.paths[0].metadata.ofn;
              } catch (e) {
                console.log(e);
              }
              setFileAttr(res, true);
              useStores.commit("changeCurrentFileProperty", {
                key: "downloadFileUrl",
                value: props.files.paths[0].path,
              });
            })
            .catch((e) => {
              backErr(e);
              console.log(e);
            })
            .finally(() => {
              currentStatus.value = "";
            });
        }
      }
    }

    // to merge
    function merge() {
      currentStatus.value = "working";

      if (selectText.value === "file") {
        let files = fileCanvass.map((p) => p.path);
        let data = { files };
        utils
          .mergeByFile(data)
          .then((res) => {
            toDownload(res.data);
          })
          .catch((e) => {
            backErr(e);
          })
          .finally(() => {
            currentStatus.value = "";
          });
      } else if (selectText.value === "page") {
        let fileIndexPathMap = getFileIndexPathMap(pageCanvass);
        let files = [];
        Object.keys(fileIndexPathMap).forEach((p) => {
          files[Number(fileIndexPathMap[p])] = p;
        });
        let data = {
          files,
          segments: getSegments(fileIndexPathMap, pageCanvass),
        };
        utils
          .mergeByPage(data)
          .then((res) => {
            toDownload(res.data);
          })
          .catch((e) => {
            backErr(e);
          })
          .finally(() => {
            currentStatus.value = "";
          });
      }
    }

    function getSegments(fileIndexPathMap, pageCanvass) {
      let segments = [];
      let prevFile = {
        file: "",
        index: 0,
        startPage: 0,
        endPage: 0,
      };
      for (let index = 0; index < pageCanvass.length; index++) {
        let page = pageCanvass[index];
        const currentFile = page.path;
        if (pageCanvass.length === 1) {
          segments.push({
            file: fileIndexPathMap[0],
            page: page.numPage - 1,
          });
        }
        if (index === 0) {
          prevFile.file = currentFile;
          prevFile.index = 0;
          prevFile.startPage = page.numPage - 1;
          prevFile.endPage = page.numPage - 1;
          continue;
        }
        if (index === pageCanvass.length - 1) {
          if (prevFile.file !== currentFile) {
            let isMultiple = prevFile.endPage !== prevFile.startPage;

            let b = {
              file: fileIndexPathMap[prevFile.file],
              page: isMultiple
                ? `${prevFile.startPage}:${prevFile.endPage}`
                : prevFile.endPage,
            };
            segments.push(b);
            segments.push({
              file: fileIndexPathMap[currentFile],
              page: page.numPage - 1,
            });
          } else {
            if (page.numPage - 1 === prevFile.endPage + 1) {
              prevFile.endPage = page.numPage - 1;
              let isMultiple = prevFile.endPage !== prevFile.startPage;
              let b = {
                file: fileIndexPathMap[prevFile.file],
                page: isMultiple
                  ? `${prevFile.startPage}:${prevFile.endPage}`
                  : prevFile.endPage,
              };
              segments.push(b);
            } else {
              let isMultiple =
                prevFile.endPage !== prevFile.startPage &&
                prevFile.endPage !== prevFile.startPage;
              let b = {
                file: fileIndexPathMap[prevFile.file],
                page: isMultiple
                  ? `${prevFile.startPage}:${prevFile.endPage}`
                  : prevFile.endPage,
              };
              segments.push(b);
              segments.push({
                file: fileIndexPathMap[prevFile.file],
                page: page.numPage - 1,
              });
            }
          }
        } else {
          if (prevFile.file !== currentFile) {
            let isMultiple = prevFile.endPage !== prevFile.startPage;
            let b = {
              file: fileIndexPathMap[prevFile.file],
              page: isMultiple
                ? `${prevFile.startPage}:${prevFile.endPage}`
                : prevFile.endPage,
            };
            segments.push(b);
            prevFile.file = currentFile;
            prevFile.index = fileIndexPathMap[currentFile];
            prevFile.startPage = page.numPage - 1;
            prevFile.endPage = page.numPage - 1;
          } else {
            if (page.numPage - 1 === prevFile.endPage + 1) {
              prevFile.endPage = page.numPage - 1;
            } else {
              let isMultiple = prevFile.endPage !== prevFile.startPage;
              let b = {
                file: fileIndexPathMap[prevFile.file],
                page: isMultiple
                  ? `${prevFile.startPage}:${prevFile.endPage}`
                  : prevFile.endPage,
              };
              segments.push(b);
              prevFile.endPage = page.numPage - 1;
              prevFile.startPage = page.numPage - 1;
            }
          }
        }
      }
      return segments;
    }

    function toDownload(data) {
      putFileAttribute(data);
      useStores.commit("changeWorkStatus", "convertAfter");
    }

    let dragging = ref(false);

    let allSelected = ref(false);
    let cutAllSelected = ref(false);
    function selectedAll() {
      if (selectText.value === "file") {
        cutAllSelected.value = !cutAllSelected.value;
        if (cutAllSelected.value) setAllCutIndex();
        else clearAllCutIndex();
        pageCanvass[pageCanvass.length - 1].cuted = false;
      }
      if (selectText.value === "page") {
        allSelected.value = !allSelected.value;
        pageCanvass.forEach((v) => {
          v.selected = allSelected.value;
        });
      }
    }

    let currentSelectedCanvass = computed(() => {
      if (selectText.value === "page")
        return pageCanvass.filter((p) => p.selected);
      if (selectText.value === "file")
        return fileCanvass.filter((p) => p.selected);
      return [];
    });

    let isDown = ref(false);
    let prvePageIndex = ref(-1);
    let prveActivePageIndexs = reactive([]);

    function resetPrveActivePageIndexs() {
      if (prveActivePageIndexs.length > 0) {
        for (let i = 0; i < prveActivePageIndexs.length; i++) {
          currentCanvass.value[prveActivePageIndexs[i].index].selected =
            prveActivePageIndexs[i].status;
        }
      }
      prveActivePageIndexs.length = 0;
    }

    function choose(index) {
      if (currentStatus.value === 'working') return;
      if (!isDown.value) {
        prvePageIndex.value = index;
        prveActivePageIndexs.length = 0;
        currentCanvass.value[index].selected =
          !currentCanvass.value[index].selected;
      } else {
        if (prvePageIndex.value !== -1) {
          if (prvePageIndex.value >= index) {
            resetPrveActivePageIndexs();
            for (let i = 1; i < prvePageIndex.value - index; i++) {
              prveActivePageIndexs.push({
                index: index + i,
                status: currentCanvass.value[index + i].selected,
              });
              currentCanvass.value[index + i].selected =
                currentCanvass.value[prvePageIndex.value].selected;
            }
          } else if (prvePageIndex.value < index) {
            resetPrveActivePageIndexs();
            for (let i = 1; i < index - prvePageIndex.value; i++) {
              prveActivePageIndexs.push({
                index: prvePageIndex.value + i,
                status: currentCanvass.value[prvePageIndex.value + i].selected,
              });
              currentCanvass.value[prvePageIndex.value + i].selected =
                currentCanvass.value[prvePageIndex.value].selected;
            }
          }
          // prvePageIndex.value = -1;
        }
        prveActivePageIndexs.push({
          index: index,
          status: currentCanvass.value[index].selected,
        });
        currentCanvass.value[index].selected =
          currentCanvass.value[prvePageIndex.value].selected;
      }

      if (currentSelectedCanvass.value.length === currentCanvass.value.length) {
        allSelected.value = true;
      }
      if (!currentCanvass.value[index].selected) {
        allSelected.value = false;
      }
    }
    function closeErrorHint() {
      isAddFileError.value = false;
      addErrorFileName.value = "";
    }

    function downShift() {
      isDown.value = true;
    }

    function upShift() {
      isDown.value = false;
    }

    function addKeyDown(e) {
      if (e.key && e.key.toLowerCase() === "shift") downShift();
    }
    function addKeyUp(e) {
      if (e.key && e.key.toLowerCase() === "shift") upShift();
    }
    onMounted(() => {
      createData(files, paths);
      document.addEventListener("keydown", addKeyDown);
      document.addEventListener("keyup", addKeyUp);
      document.body.addEventListener("touchstart", hideMask);
    });
    onUnmounted(() => {
      document.removeEventListener("keydown", addKeyDown);
      document.removeEventListener("keyup", addKeyUp);
      document.removeEventListener("touchstart", hideMask);
    });

    let { t } = useI18n();
    let splitTip = computed(() => {
      let soustr = {
        sourceStr: t("splitPage.tip"),
        tag: "div",
        attrs: {
          class: ["flex"],
        },
        child: [
          {
            tag: "img",
            target: "%img%",
            attrs: {
              src: require("@/assets/images/work/icon_up_shift.svg"),
            },
          },
          {
            tag: "span",
            target: "%shift%",
            text: t("splitPage.tipKey"),
          },
        ],
      };
      return i18nHTML(soustr);
    });

    let cuts = computed(() => {
      return pageCanvass.filter((p) => p.cuted);
    });

    function cutsIndexMap() {
      let res = {};
      let count = 0;

      for (let i = 0; i < pageCanvass.length; i++) {
        if (cuts.value.length <= 0) {
          res[i] = null;
        } else {
          if (pageCanvass[i].cuted) {
            res[i] = count;
            count++;
          } else {
            res[i] = count;
          }
        }
      }
      return res;
    }

    function setCutIndex() {
      let _cutsMap = cutsIndexMap();
      pageCanvass.forEach((p, index) => {
        p.cutIndex = _cutsMap[index];
      });
    }
    function setAllCutIndex() {
      pageCanvass.forEach((p, index) => {
        p.cutIndex = index;
        p.cuted = pageCanvass.length - 1 !== index;
      });
    }
    function clearAllCutIndex() {
      pageCanvass.forEach((p) => {
        p.cutIndex = null;
        p.cuted = false;
      });
    }
    function toggleCuts() {
      if (cuts.value.length === pageCanvass.length) {
        clearAllCutIndex();
      } else {
        setAllCutIndex();
      }
    }
    function cut(index) {
      if (currentStatus.value === 'working') return;
      pageCanvass[index].cuted = !pageCanvass[index].cuted;
      setCutIndex();
      cutAllSelected.value = cuts.value.length === pageCanvass.length - 1;
    }

    function cloneCanvasToImg(origin) {
      let img = document.createElement("img")
      img.src = origin.toDataURL("image/png")
      img.style.width = origin.style.width
      img.style.height = origin.style.height
      return img
    }

    function onReadyDragEnd() {
      readyDrag.value = false;
    }

    function draggableEnd() {
      dragging.value = false;
      onReadyDragEnd();
    }
    function draggableStart() {
      dragging.value = true;
      if (store.work === 'merge') {
        if (selectText.value === 'file') {
          fileLongPressedOver.value = true;
        }
        if (selectText.value === 'page') {
          pageLongPressedOver.value = true;
        }
      }
      try{
        let draggingNode = document.querySelector(".sortable-chosen");
        let targetNode = draggingNode.parentNode.lastElementChild;
        if (!Array.from(targetNode.classList).includes("sortable-drag")) return;
        let viewPage = draggingNode.querySelector('.view-page');
        viewPage.classList.remove("scaled-canvas");
        let origin = draggingNode.querySelector('canvas');
        let img = cloneCanvasToImg(origin);
        let parentCanvas = targetNode.querySelector(".canvas-parent");
        let canvas = targetNode.querySelector("canvas");
        parentCanvas.removeChild(canvas);
        parentCanvas.appendChild(img);
      }catch (e){
        console.log(e);
      }
    }

    let canvassStyle = computed(() => {
      return {'--repeatNum': store.work === "split" && selectText.value === "file"  ? 1 : 2}
    })

    return {
      getIsMobile,
      longPressed,
      readyDrag,
      onReadyDrag,
      onReadyDragEnd,
      pageLongPressed,
      fileLongPressed,
      longPressedOver,
      exitLongPressed,
      hideMask,
      onLongPressed,
      draggableStart,
      draggableEnd,
      cutsIndexMap,
      cuts,
      toggleCuts,
      cut,
      selectedPages,
      splitLongButtonText,
      splitTip,
      prvePageIndex,
      prveActivePageIndexs,
      selectedAll,
      allSelected,
      cutAllSelected,
      currentSelectedCanvass,
      choose,
      payType,
      currentStatus,
      toFilesView,
      toPagesView,
      dragging,
      toWork,
      styleButton,
      createData,
      changeInput,
      fileInput,
      addFile,
      isAddFileError,
      addErrorFileName,
      closeErrorHint,
      showCanvas,
      totalPage,
      currentPage,
      currentPageImgUrl,
      currentIndex,
      enlarge,
      shrink,
      isEnlarge,
      prePage,
      nextPage,
      pageCanvass,
      fileCanvass,
      remove,
      names,
      selectText,
      selectTextAndCurrentStatusText,
      selectViewFile,
      selectViewPage,
      getFileTypeImgUrl,
      getFirst4Filename,
      utils,
      totalSize,
      numFiles,
      isMultipleFile,
      next,
      store,
      paintingNextPage,
      paintingNextFile,
      pdfPageViews,
      pdfFileViews,
      canvassStyle,
    };
  },
  created() {},
};
</script>

<style scoped>
.merge * {
  user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  -webkit-touch-callout:none !important;
}
.merge {
  background: #fafafa;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  user-select: none;
}
.merge {
  font-weight: normal;
  font-size: 14px;
  width: 720px;
  margin: 0 auto;
  padding: 28px;
  display: grid;
  grid-template-columns: 1fr 244px 244px;
  grid-template-rows: auto 36px;
  grid-row-gap: 18px;
  grid-column-gap: 18px;
}
.file-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}
.merge .file-name > div:not(:last-child) {
  margin-bottom: 4px;
}
.merge .file-name > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.merge .file-name img {
  width: 28px;
  height: 28px;
  margin-right: 6px;
  cursor: pointer;
}
.merge .title {
  font-weight: bold;
  margin-bottom: 6px;
}
.merge .checked {
  border: 2px solid #00a3ff;
  padding: 14px;
}
.merge-select {
  padding: 15px;
  min-width: 244px;
  min-height: 118px;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  border: 1px solid #efefef;
}
.merge .select-right {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 3;
  grid-column-end: 4;
}
.merge .select-left {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}
.merge-select > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.merge-select .var-radio__wrap {
  margin-right: 6px;
}
.file-property {
  padding-left: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.file-property span:not(:last-child) {
  margin-right: 12px;
}
.file-property span:last-child {
  position: relative;
  margin-left: 12px;
}
.file-property span:last-child::before {
  padding-left: 12px;
  box-sizing: border-box;
  content: "";
  height: 12px;
  width: 18px;
  text-align: center;
  border-left: 1px solid #dad9d9;
  position: absolute;
  top: 4px;
  left: -12px;
}
.merge .action button {
  width: 100%;
}
.merge .action {
  grid-column-start: 2;
  grid-column-end: 4;
  position: relative;
}
.merge .action .action-selected {
  background: #dbdbdb;
  cursor: not-allowed;
}

.merge-view {
  min-height: 220px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  background: #fafafa;
}
.merge-view .nav {
  display: flex;
  justify-content: center;
  align-items: stretch;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: normal;
  font-size: 14px;
  color: #454545;
  margin: 0 auto 24px auto;
  text-align: center;
  white-space: nowrap;
}
.merge-view .nav .left-nav {
  border-radius: 4px 0 0 4px;
}
.merge-view .nav .right-nav {
  border-radius: 0 4px 4px 0;
}
.merge-view .nav > div {
  padding: 6px 18px;
  cursor: pointer;
  flex: 1;
  box-sizing: border-box;
  min-width: 8.5em;
  color: #7d7d7d;
  user-select: none;
}
.merge-view .nav .checked-nav {
  color: #ffffff;
  background: #454545;
  cursor: default;
}

.canvass {
  display: grid;
  grid-template-columns: repeat(6, 1fr);;
}
.flip-list-file-move,
flip-list-page-move {
  transition: transform 0.5s;
}

.merge-view .merge-view-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 34px;
  align-items: center;
}
.merge-view .merge-view-footer {
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  position: sticky;
  bottom: 14px;
}
.merge-view .merge-view-footer .status-tag {
  margin-left: 8px;
}
.merge-view .merge-view-footer .status-tag,
.merge-view .merge-view-footer .status-tag > div {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.merge-view .merge-view-footer .status-tag > div > * {
  cursor: pointer;
}
.merge-view .merge-view-footer button {
  height: 32px;
  margin-left: 24px;
}
.merge-view .merge-view-footer img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.merge-view .filler {
  width: 102%;
  height: 0;
  background-color: #ffffff;
  position: sticky;
  bottom: 0;
}

.mask {
  z-index: 11;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}
.mask .mask-action {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 54px;
  color: #ffffff;
  background: #454545;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0;
}
.mask .mask-action .quit-img {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 14px;
  cursor: pointer;
}
.mask .mask-action .tags-name {
  margin-left: 22px;
  max-width: 34%;
  display: block;
}
.mask .mask-action .tags {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.tags-file-name {
  margin: 0 4px;
}

.tags-file-name, .mask .mask-action .tags-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tags i {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.enlarged {
  height: calc((100% - 54px) * 0.9) !important;
  width: auto !important;
  z-index: 13;
  position: fixed;
  top: calc(50% - 27px);
  transform: translate(-50%, -50%);
  left: 50%;
}

.bar {
  position: absolute;
  top: -6px;
  left: 0;
}
.hint {
  position: absolute;
  left: 0;
  bottom: calc( 100% + 1px );
  background: rgba(207, 234, 254, 0.85);
  background-clip: padding-box;
  font-size: 14px;
  color: #02A4FF;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 6px 0;
}
.hint svg {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.err-hint {
  color: #ffffff;
  font-size: 12px;
  width: 100%;
  background: rgba(241, 0, 0, 0.78);
  background-clip: padding-box;
  box-sizing: border-box;
  padding: 4px 8px;
  height: 24px;
  position: absolute;
  left: 0;
  bottom: 100%;
  justify-content: space-between;
}
.err-hint .err-hint-info {
  justify-content: flex-start;
}
.merge-view .merge-view-footer .err-hint .cancel {
  cursor: pointer;
  margin: 0 0 0 6px;
}
.err-hint .hint-name {
  font-weight: 700;
}
.merge-view-footer button {
  background: var(--button-color);
}
.add-file-icon {
  border-radius: 50%;
}
.status-tag .add-file-icon {
  background-color: var(--button-color);
}

.going {
  background: #dbdbdb !important;
  cursor: progress;
}
.notWork {
  background: #dbdbdb !important;
  cursor: not-allowed;
}
.select-all {
  font-size: 14px;
  padding-left: 24px;
  margin-left: 24px;
  border-left: 1px solid #dbdbdb;
}
.select-all > div {
  width: 16px;
  height: 16px;
  box-sizing: border-box;
}

.select-all img {
  width: 16px;
  height: 16px;
}
.select-all .not-selected {
  margin-right: 6px;
  border: 1px solid #dbdbdb;
}

.tip {
  color: #454545;
  font-size: 14px;
}
.tip .flex {
  background: red;
  border-radius: 2px;
  padding: 4px 6px;
  margin: 0 4px;
}
.tip img {
  width: 12px;
  height: 12px;
  margin-right: 4px;
}
.merge-tip {
  color: #c4c4c4;
}
.merge-tip svg {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.mb {
  display: none;
}

@media all and (max-width: 570px) and (min-width: 320px) {
  .merge {
    width: 100%;
    padding: 18px;
    font-size: 16px;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: auto auto auto auto;
    box-sizing: border-box;
    grid-column-gap: 0;
    grid-row-gap: 14px;
  }

  .merge .add-file {
    display: flex;
    grid-column: 2/3;
    grid-row: 1/2;
    font-size: 14px;
    justify-content: flex-end;
    align-items: center;
    padding-left: 0;
    white-space: nowrap;
  }
  .merge .add-file img{
    margin-right: 8px;
  }
  .merge .add-file .add-file-icon{
    background: #00A3FF;
  }

  .merge .action {
    margin-top: 4px;
    grid-column: 1/3;
    grid-row: 4/5;
  }

  .merge .merge-select:first-child {
    margin-top: 8px;
  }
  .merge-select {
    min-width: 100%;
    box-sizing: border-box;
    grid-column: 1/3;
    min-height: 120px;
    justify-content: space-between;
  }

  .merge .select-left {
    grid-column: 1/3;
    grid-row: 2/3;
  }
  .merge .select-right {
    grid-column: 1/3;
    grid-row: 3/4;
  }

  .merge-select .var-radio__wrap {
    order: 1;
  }

  .mb {
    display: flex;
  }
  .pc{
    display: none
  }
  .merge-view {
    align-items: center !important;
  }
  .merge-tip-show {
    opacity: 0;
  }
}

@media all and (min-width: 765px) {
  .merge-tip {
    display: none;
  }
}
@media all and (max-width: 1020px) and (min-width: 570px) {
  .merge {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto auto;
    box-sizing: border-box;
  }

  .merge .file-property {
    grid-column: 2/3;
    grid-row: 1/2;
  }

  .merge .action {
    grid-column: 1/3;
    grid-row: 3/4;
  }

  .merge-select {
    min-width: 180px;
  }

  .merge .select-left {
    grid-column: 1/2;
    grid-row: 2/3;
  }
  .merge .select-right {
    grid-column: 2/3;
    grid-row: 2/3;
  }
  .merge-view {
    align-items: center !important;
  }
}


@media all and (max-width: 1020px) and (min-width: 320px) {
  .err-hint {
    height: auto;
    padding: 8px 14px;
    align-items: self-start;
  }
  .err-hint .err-hint-info {
    align-items: flex-start;
  }

  .mask .mask-action {
    padding: 16px 14px;
    height: auto;
    min-height: 52px;
  }
  .mask .mask-action .quit-img {
    right: 12px;
    width: 16px;
    height: 16px;
  }
  .mask .mask-action .tags-name {
    margin-left: 0;
  }
  .merge-view .merge-view-main {
    margin-bottom: 24px;
  }
  .split-tip {
    display: none;
  }
  .merge-view .nav {
    margin: 0 auto 14px auto;
  }

  .merge-view .nav > div {
    padding: 7px 22px;
  }
}

@media all and (max-width: 1020px) and (min-width: 890px) {
  .canvass {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media all and (max-width: 890px) and (min-width: 570px) {
  .canvass {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media all and (max-width: 720px) and (min-width: 570px) {
  .canvass {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media all and (max-width: 570px) and (min-width: 320px) {
  .canvass {
    grid-template-columns: repeat(var(--repeatNum), 1fr);
  }

  .canvass-column {
    row-gap: 38px;
  }

  :deep(.canvass-column .cut-view) {
    right: 62px;
    transform: rotate(90deg);
    top: 116px;
  }

  .enlarged {
    max-width: 82%;
    height: auto !important;
    width: 82% !important;
  }

  .merge-view .merge-view-footer {
    padding: 16px;
  }

  .merge-view .column-footer {
    padding-top: 24px;
    flex-direction: column;
  }

  .merge-view .merge-view-footer button {
    height: 44px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .merge-view .merge-view-footer button:not[class=mb] {
    height: 44px;
    width: 108px;
  }
  .merge-view .merge-view-footer .long-button {
    margin: 0 0 0 0;
  }
  .merge-view .merge-view-footer .mt-24 {
    margin: 24px 0 0 0;
  }

  .merge-view .merge-view-footer .status-tag {
    justify-content: space-between;
    width: 100%;
    margin: 0;
  }
  .merge-view .merge-view-footer .left-tag {
    justify-content: flex-start;
  }

  .tags-file-name {
    margin: 0 14px;
  }
  .mask .mask-action .tags-name {
    margin-right: 14px;
  }
  .tags-file-name, .mask .mask-action .tags-name {
    max-width: 115px;
  }

  .select-all {
    border: none;
    padding: 0;
    margin: 0;
  }

}
</style>
