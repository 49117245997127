<template>
  <div class="email">
    <IInput
      name="email"
      :type="'text'"
      :icon="require('@/assets/images/account/icon-email.png')"
      :placeholder="$t('email.name')"
      :hint="
        hint || $t('error.emailFormatErr') || 'The email format is incorrect'
      "
      @updateValue="updateValue"
      @oninput="oninput"
      :hasParentHint="hasParentHint || hasHint"
    >
    </IInput>
  </div>
</template>

<script>
import { ref } from "vue";
import IInput from "./IInput";
import utils from "../util/utils";

export default {
  name: "IEmail",
  components: { IInput },
  props: {
    hint: {
      type: String,
      default: "",
    },
    hasParentHint: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    let hasHint = ref(false);

    function updateValue(e) {
      if (!e) {
        hasHint.value = false;
        return;
      }
      if (utils.verifyEmail(e)) {
        hasHint.value = false;
        context.emit("updateValue", e);
      } else {
        hasHint.value = true;
      }
    }
    function oninput(v) {
      hasHint.value = false;
      context.emit("oninput", v);
    }

    let isHide = ref(true);

    return {
      hasHint,
      isHide,
      updateValue,
      oninput,
    };
  },
  created() {},
};
</script>

<style scoped>
:deep(.input) {
  text-transform: lowercase;
}

:deep(.input::-webkit-input-placeholder) {
  text-transform: none !important;
  color: #7d7d7d;
}

:deep(.input::placeholder) {
  text-transform: none !important;
  color: #7d7d7d;
}

:deep(.input :-moz-placeholder) {
  text-transform: none !important;
  color: #7d7d7d;
}

:deep(.input ::-moz-placeholder) {
  text-transform: none !important;
  color: #7d7d7d;
}

:deep(.input :-ms-input-placeholder) {
  text-transform: none !important;
  color: #7d7d7d;
}
@media all and (max-width: 1020px) and (min-width: 320px) {
  .email {
    width: 100%;
  }
}
</style>
