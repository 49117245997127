<template>
  <svg v-if="dir === 'left'" :width="iwidth" :height="iheight" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2461_14837)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3536 2.35355C10.1583 2.15829 9.84171 2.15829 9.64645 2.35355L3.98959 8.01041C3.79433 8.20567 3.79433 8.52225 3.98959 8.71751C3.99078 8.7187 3.99197 8.71988 3.99317 8.72105L9.64647 14.3744C9.84173 14.5696 10.1583 14.5696 10.3536 14.3744C10.5488 14.1791 10.5488 13.8625 10.3536 13.6672L5.05027 8.36394L10.3536 3.06066C10.5488 2.8654 10.5488 2.54882 10.3536 2.35355Z" :fill="color"/>
    </g>
    <defs>
      <clipPath id="clip0_2461_14837">
        <rect width="16" height="16" :fill="color"/>
      </clipPath>
    </defs>
  </svg>

  <svg v-if="dir === 'right'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2461_14822)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.64645 2.35355C5.84171 2.15829 6.15829 2.15829 6.35355 2.35355L12.0104 8.01041C12.2057 8.20567 12.2057 8.52225 12.0104 8.71751C12.0092 8.7187 12.008 8.71988 12.0068 8.72105L6.35353 14.3744C6.15827 14.5696 5.84169 14.5696 5.64643 14.3744C5.45116 14.1791 5.45116 13.8625 5.64643 13.6672L10.9497 8.36394L5.64645 3.06066C5.45118 2.8654 5.45118 2.54882 5.64645 2.35355Z" :fill="color"/>
    </g>
    <defs>
      <clipPath id="clip0_2461_14822">
        <rect width="16" height="16" :fill="color" transform="matrix(-1 0 0 1 16 0)"/>
      </clipPath>
    </defs>
  </svg>



</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Direc",
  props: {
    dir: {
      type: String,
      default: "left"
    },
    iwidth: {
      type: String,
      default: "16"
    },
    iheight: {
      type: String,
      default: "16"
    },
    color: {
      type: String,
      default: "#ffffff"
    }
  },
});
</script>
