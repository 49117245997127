<template>
  <div class="inputs">
    <div v-for="(item, index) in inputList" :key="`vcode${index}`">
      <input
        type="tel"
        maxlength="1"
        v-model="item.val"
        class="border-input"
        :class="{ hint: hint }"
        @keyup.delete="deleteText(index)"
        @keyup.left="leftMove(index)"
        @keyup.right="rightMove(index)"
        @keyup="nextFocus($event, index)"
        @keydown="changeValue($event, index)"
        @focus="inputFocus(index)"
      />
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "VerifyCode",
  components: {},
  emits: ["resetHint", "enterOver"],
  props: {
    hint: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    let inputList = reactive([
      { val: "" },
      { val: "" },
      { val: "" },
      { val: "" },
      { val: "" },
    ]);

    function deleteText(index) {
      if (index < 1) return;
      let dom = document.getElementsByClassName("border-input");
      let lastInput = dom[index - 1];
      if (!inputList[index].val) lastInput.focus();
    }

    function rightMove(index) {
      if (index >= inputList.length - 1) return;
      let dom = document.getElementsByClassName("border-input");
      let nextInput = dom[index + 1];
      nextInput.focus();
    }

    function leftMove(index) {
      if (index < 1) return;
      let dom = document.getElementsByClassName("border-input");
      let lastInput = dom[index - 1];
      lastInput.focus();
    }


    function nextFocus(el, index) {
      let dom = document.getElementsByClassName("border-input");
      let currInput = dom[index];
      let nextInput = dom[index + 1];
      let lastInput = dom[index - 1];

      if (el.key && el.key.toString().toLowerCase() === "tab") return;
      if (el.keyCode === 8 || (el.key && el.key.toString().toLowerCase() === "backspace")) {
        if (index !== 0) {
          lastInput.focus();
        }
        return;
      }

      if (el.key && el.key !==  "Unidentified" && el.key.toString().toLowerCase().length > 2) return;
      if (el.key && el.key.toString().toLowerCase().length === 1) inputList[index].val = el.key;

      if (index < inputList.length - 1) {
        nextInput.focus();
        context.emit("resetHint");
      } else {
        currInput.blur();
        context.emit("enterOver");
      }
    }

    function changeValue(el, index) {
      if (el.key && el.key !==  "Unidentified" && el.key.toString().toLowerCase().length > 2) {
        return;
      }
      inputList[index].val = "";
    }

    function clearlyCode() {
      inputList.forEach((i) => {
        i.val = "";
      });
    }

    function inputFocus() {
      // if (index === inputList.length - 1) return;
      // if (exitsVals.value.length === inputList.length && props.hint) {
      //   clearlyCode();
      //   context.emit("resetHint");
      // }
    }

    return {
      deleteText,
      rightMove,
      leftMove,
      inputFocus,
      inputList,
      changeValue,
      nextFocus,
      clearlyCode,
    };
  },
  created() {},
};
</script>

<style scoped>
.border-input {
  background: #fafafa;
  width: 48px;
  height: 48px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  box-shadow: none;
}
.inputs > div:not(:last-child) {
  margin-right: 30px;
}
input {
  outline: none;

  box-sizing: border-box;
}
.inputs .border-input:focus {
  /*border-color: #05d0fa !important;*/
  /*box-shadow: 0 0 8px 4px rgb(9 243 220 / 20%);*/
}
.inputs {
  display: flex;
  justify-content: space-between;
}
.hint {
  background: #fff2f2;
  border: 1px solid #fed5d5;
  border-radius: 4px;
  color: #fb3636;
}

@media all and (max-width: 570px) and (min-width: 320px) {
  .border-input {
    width: 44px;
    height: 44px;
  }
  .inputs {
    justify-content: center;
  }
  .inputs > div:not(:last-child) {
    margin-right: 20px;
  }
  .resend {
    font-size: 16px;
  }
}
</style>
