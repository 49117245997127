<template>
  <div class="left link" style="position: relative">
    <hr class="line" />
    <div class="app-name">{{ $t("app") }}</div>
    <div class="us" v-if="showLinks">
      <a target="_blank" href="https://www.linkedin.com/in/nut-pdf-062062229/">
        <img src="@/assets/images/sitemap/linkedIn.svg" alt="load error"
      /></a>
      <a target="_blank" href="https://www.instagram.com/nutpdf/">
        <img src="@/assets/images/sitemap/instagram.svg" alt="load error"
      /></a>
      <a
        target="_blank"
        href="https://www.youtube.com/channel/UCyTPZ-9yxUXq-OL5FzDREiw"
      >
        <img src="@/assets/images/sitemap/youTube.svg" alt="load error"
      /></a>
      <a target="_blank" href="https://twitter.com/NutPdf">
        <img src="@/assets/images/sitemap/twitter.svg" alt="load error"
      /></a>
      <a
        target="_blank"
        href="https://www.facebook.com/NutPdf-102499025660055/?view_public_for=102499025660055"
      >
        <img src="@/assets/images/sitemap/facebook.svg" alt="load error"
      /></a>
    </div>
  </div>


  <div class="product right">
    <span class="title">{{ $t("siteMap.product") }}</span>
    <div style="height: 14px"></div>
    <a
      :href="`/index.html#/product?from=${route.name}${
        route.name === 'work' ? '&work=' + store.work : ''
      }`"
      >{{ $t("siteMap.pricing") }}</a
    >
  </div>


  <div class="about right">
    <span class="title">{{ $t("siteMap.company") }}</span>
    <div style="height: 14px"></div>
    <a href="/index.html#/about">{{ $t("siteMap.about") }}</a>
    <div style="height: 14px"></div>
    <a href="/index.html#/contact">{{
        $t("siteMap.contactUs")
      }}</a>
    <div style="height: 14px"></div>
    <a v-if="showLinks" href="javascript:void(0);" @click="tawkToggle">{{
        $t("siteMap.inside")
      }}</a>
  </div>

</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "SiteMap",
  components: {},
  props: {},
  setup() {
    const email = ref(`${process.env.VUE_APP_EMAIL}`);
    let route = useRoute();
    let store = useStore().state;

    let showLinks = ref(process.env.VUE_APP_APP === 'NutPDF');

    function tawkToggle() {
      window.Tawk_API.toggle()
    }
    return {
      tawkToggle,
      store,
      route,
      email,
      showLinks,
    };
  },
  created() {},
};
</script>

<style scoped>
.site-map {
  width: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e5e5e5;
}
.app-name {
  margin-bottom: 28px;
}
a {
  font-size: 14px;
}
.title {
  font-weight: 700;
  margin-top: 40px;
}
.left {
  font-weight: 700;
  font-size: 24px;

  text-align: start;
}
.left .us {
  height: 24px;
}
.left .us a:not(:last-child) {
  margin-right: 14px;
}

.left img {
  width: 24px;
  height: 24px;
}
@media (any-hover: hover){
  .left img:hover {
    filter: invert(48%) sepia(80%) saturate(3266%) hue-rotate(177deg)
      brightness(104%) contrast(103%);
}
}
.left img:active {
  filter: invert(48%) sepia(80%) saturate(3266%) hue-rotate(177deg)
  brightness(104%) contrast(103%);
}

.right,
.left {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: start;
}
.link {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}
.product {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
}
.about {
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 3;

}

.help a,
.help span,
.about span,
.about a,
.product span,
.product a {
  padding-left: 14px;
}
.line {
  position: absolute;
  width: 920px;
  top: -40px;
  border-bottom-color: #e5e5e5;
}

@media all and (max-width: 640px) and (min-width: 320px) {
  .left {
    margin: 0;
  }
  .app-name {
    margin-bottom: 32px;
  }

  .link {
    padding: 0;
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .left .us a:not(:last-child) {
    margin-right: 0px;
  }
  .left .us {
    height: 32px;
    justify-content: space-between;
    display: flex;
    width: 100%;
  }
  .left img {
    width: 32px;
    height: 32px;
  }
  .about {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: start;
  }
  .about span,
  .about a,
  .help span,
  .help a,
  .product span,
  .product a {
    padding-left: 0px;
  }

  .product {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    text-align: start;
  }
  .right,
  .left {
    margin: 0 0 32px 0;
  }
  .line {
    width: 100%;
    top: -32px;
    border-bottom-color: #d9d9d9;
  }
}
@media all and (max-width: 920px) and (min-width: 640px) {
  .link > div {
    padding-left: 24px;
  }

  .link {
    position: relative;
  }
}
</style>
