<template>
  <svg :width="iwidth" :height="iheight" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" :fill="color"/>
    <rect x="5" y="9" width="10" height="2" rx="1" fill="white"/>
  </svg>




</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "RoundDelete",
  props: {
    iwidth: {
      type: String,
      default: "20"
    },
    iheight: {
      type: String,
      default: "20"
    },
    color: {
      type: String,
      default: "#CB0000"
    }
  },
});
</script>
