<template>
  <div class="convert">
    <div
      class="convert-info"
      :class="{
        'info-grid': store.workStatus === 'convertBefore',
        'convert-info-over': restart,
      }"
    >
      <img class="iconPC" :src="iconPC" alt="load error" />
      <img class="iconMB" :src="iconMB" alt="load error" />
      <span class="file-name" :title="fullFilename">{{ filename }}</span>
      <slot></slot>
    </div>
    <div class="flex">
      <button
        v-if="restart"
        class="restart-button short-button"
        @click="back"
        :style="{
          color: store.tools[store.work].textColor,
          border: '1px solid' + store.tools[store.work].textColor,
        }"
      >
        <RestartIcon
          :width="'18'"
          :height="'18'"
          :color="store.tools[store.work].textColor"
        />
        {{ $t("convertPage.startOver") }}
      </button>
      <button
        class="but"
        :class="{ 'short-button': restart }"
        @click="clickBut"
        :style="{ backgroundColor: buttonColor }"
      >
        <img class="small-icon small-icon-8" :src="behaviorIcon" />
        <span>{{ text }}</span>
      </button>
    </div>
    <a
      v-if="restart && showTip"
      href="javascript:void(0);"
      @click="showTawk"
      class="convert-tip"
    >
      {{$t("convertPage.startOverTip")}}
    </a>
  </div>
</template>

<script>
// import { toRefs, reactive, ref } from 'vue'
import { useStore } from "vuex";
import RestartIcon from "@/components/icons/RestartIcon";
import Button from "@/components/Button";
import { ref } from "vue";

export default {
  name: "ConvertBefore",
  components: { Button, RestartIcon },
  props: {
    imgStyle: {
      type: Object,
      default: () => {
        return { width: "72px", height: "72px", marginRight: "14px" };
      },
    },
    buttonColor: {
      default: "#00a3ff",
      type: String,
    },
    iconPC: {
      default: "",
      type: String,
    },
    iconMB: {
      default: "",
      type: String,
    },
    behaviorIcon: {
      type: String,
      default: "",
    },
    filename: {
      default: "",
      type: String,
    },
    fullFilename: {
      default: "",
      type: String,
    },
    text: {
      default: "",
      type: String,
    },
    restart: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, context) {
    function clickBut() {
      context.emit("click-but");
    }
    let useStores = useStore();
    let store = useStores.state;
    function back() {
      useStores.commit("clearCurrentFileProperty");
      useStores.commit("change", { key: "workStatus", value: "chooseFile" });
    }

    function showTawk() {
      window.Tawk_API.toggle();
    }

    let showTip = ref(process.env.VUE_APP_APP === 'NutPDF');
    return {
      store,
      back,
      clickBut,
      showTawk,
      showTip,
    };
  },
  created() {},
};
</script>

<style scoped>
.convert {
  padding-top: 30px;
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
}
.but {
  color: #ffffff;
}
.iconMB {
  display: none;
}
img {
  width: 72px;
  margin-right: 20px;
}
.convert .convert-info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  margin-bottom: 10px;
}
.convert .convert-info .iconPC {
  width: 72px;
  height: 72px;
  margin-right: 14px;
}
.convert .convert-info .iconMB {
  width: 28px;
  height: 28px;
  margin-right: 5px;
  justify-self: end;
}
.restart-button {
  font-size: 16px;
  display: none;
  background: #ffffff;

  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 11px;
}

.restart-button svg {
  margin-right: 8px;
}
.convert .short-button {
  padding: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 120px;
  height: 44px;
  white-space: nowrap;
}
.convert-tip {
  font-weight: 400;
  margin-top: 14px;
  font-size: 14px;
  text-align: center;
  text-decoration-line: underline;
  cursor: pointer;
}

@media all and (max-width: 640px) and (min-width: 320px) {
  .convert {
    box-sizing: border-box;
    padding: 43px 38px 38px 38px;
    height: auto;
  }
  .convert .info-grid {
    display: grid;
  }
  .convert .convert-info {
    margin-bottom: 28px;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    row-gap: 18px;
  }
  .convert .convert-info .file-name {
    font-size: 18px;
    grid-row: 1/2;
    grid-column: 2/3;
  }
  .convert .convert-info img {
    grid-row: 1/2;
    grid-column: 1/2;
  }
  .iconMB {
    display: block;
  }
  .iconPC {
    display: none;
  }
  .convert .convert-info-over {
    margin-top: 18px;
    margin-bottom: 46px;
  }
  .restart-button {
    display: block;
  }
  .convert-tip {
    font-size: 12px;
  }
}
@media all and (min-width: 640px) {
  .convert .short-button {
    min-width: 200px;
    order: 1;
  }
}
</style>
