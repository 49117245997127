const en = {
  app: `${process.env.VUE_APP_APP}`,
  pro: {
    name: "PRO",
  },
  useCookieAuth: {
    title: "We use cookies",
    desc: "This website uses cookies %cookie% and similar technologies (together “cookies”) that are essential for the operations of this website and its core functions. Other cookies will only be placed with your consent. For more details visit our %privacy%.",
    buttonText: "Got it",
  },
  error: {
    // 账户不存在
    accountNotExist: "Account does not exist",
    // 账户存在
    accountExist: "Account is exist",
    emailFormatErr: "The email format is incorrect",
    wrongPassword: "Wrong password",
    comparePasswordErr: "Inconsistent passwords",
    // 密码长度较短
    passwordHintTextShort: "Password must be min. 8 characters",

    passwordHintTextLong:
      "Password must be a combination of numbers,letters, and special characters",
    codeVerifyErrText: "This verification code is incorrect.",
    // 未输入完整的验证码强行校验时触发
    codeShortText: "Please enter full verification code.",
    // 不是正常操作进入页面时触发
    illogicalText: "Please use the correct steps to log in or sign up.",
    // 未知错误
    unknownError: "Unknown error",
    sendCodeErr: "Send code fail.",
    // 发生频繁
    sendCodeFrequently: "Send too frequently.",

    // 401 -sid未提供或未找到指定的帐户sid
    // 403 - 找到的帐号sid，但无权操作指定资源
    // 415 - 不支持的源或目标格式
    convertERR400: "Unacceptable files",
    convertERR401: "Invalid account",
    convertERR403:
      "The account does not have the right to operate the specified resources",
    convertERR415: "Unsupported Media Type",
    convertERR422: "This file is invalid, please check and retry.",
    convertERR500: "Unknown error, Please start over",
    convertERR504: "Time out",
    convertERR522: "The file is locked, please unlock it and try again.",
  },
  action: {
    download: "Download",
    next: "Next",
    pending: "Pending",
  },
  privacyPolicy: {
    title: "Privacy Policy",
  },
  termsConditions: {
    title: "Terms & Conditions",
  },
  account: {
    name: "Account",
  },
  head: {
    say: "Hi, %userName%",
    signOut: "Sign Out",
  },
  payPage: {
    title: `Buy ${process.env.VUE_APP_APP} PRO`,
    // 支付页面
    desc: "unlimited access",
    creditcards: "Credit Cards",
    grabpay: "Grab Pay",
    alipayglobal: "Alipay Global",
    alipaydesc: "You will be redirected to Alipay to finish your payment.",
    alreadypaid: "Already paid?",
    freshmem: "Refresh Membership",
    loading: "Loading...",
    success: {
      pc: {
        title: "Payment Successful",
        desc: "You will be redirected to previous page in %time% seconds.",
      },
      mb: {
        title: "Payment Successful",
        desc: "You will be redirected to previous <br>page in %time% seconds.",
      },
    },
    error: {
      pc: {
        title: "Error!",
        desc: "Please try another payment method",
      },
      mb: {
        title: "Error!",
        desc: "Please try another payment method",
      },
    },
  },
  productPage: {
    title: "Plan That Fits Your Budget",
    shortTermButtonText: "Short Term",
    longTermButtonText: "Long Term",
    // 价格后面显示的时间单位, 如 5.99/月
    showPeriod: "month",
    unit: "$",
    unitText: "",
    days: "days",
    pro_0: {
      butText: "Sign Up",
      // 使用 , 分割
      rules: `Access to all ${process.env.VUE_APP_APP} tools,Process up to 3 docs per day,File size up to 15M`,
      cardBilledtext: "",
    },
    pro_30d: {
      billed: "For 30 days unlimited access.",
      butText: "Buy PRO",
      // 订单页面的plan使用
      plan: "PRO member for 30 days",
      // 使用 , 分割
      rules: `Access to all ${process.env.VUE_APP_APP} tools,Unlimited document processing,File size up to 1G,File storage`,
      cardBilledtext: "",
    },
    pro_90d: {
      billed:
        "For 90 days unlimited access.<br>Billed as one payment of %totalPrice% SGD.",
      butText: "Buy PRO",
      plan: "PRO member for 90 days",
      // 使用 , 分割
      rules: `Access to all ${process.env.VUE_APP_APP} tools,Unlimited document processing,File size up to 1G,File storage`,
      cardBilledtext: "Billed as  %totalPrice%",
    },
    pro_180d: {
      billed:
        "For 180 days unlimited access.<br>Billed as one payment of %totalPrice% SGD.",
      butText: "Buy PRO",
      plan: "PRO member for 180 days",
      // 使用 , 分割
      rules: `Access to all ${process.env.VUE_APP_APP} tools,Unlimited document processing,File size up to 1G,File storage`,
      cardBilledtext: "Billed as  %totalPrice%",
    },
    pro_365d: {
      billed:
        "For 365 days unlimited access.<br>Billed as one payment of %totalPrice% SGD.",
      butText: "Buy PRO",
      plan: "PRO member for 365 days",
      // 使用 , 分割
      rules: `Access to all ${process.env.VUE_APP_APP} tools,Unlimited document processing,File size up to 1G,File storage`,
      cardBilledtext: "Billed as  %totalPrice%",
    },
  },
  home: {
    banner: {
      mainSlogan: "All you need for PDF",
      subSlogan:{
        pc: "All the tools you’ll need to be more productive <br>and work smarter with documents.",
        mb: "All the tools you’ll need to be more <br>productive and work smarter with <br>documents.",
      },
      buttonText: "Explore All PDF Tools",
    },
    toolSlogan: "Most Popular Tools",
    toolHint: "See All Tools ",
    showBlock: {
      title: "Keep Your Tasks Simple",
      block1: {
        pc: {
          mainTitle: "Work Directly on Your Files",
          details:
            "Do more than just view PDFs. Highlight and add text, images, shapes, and freehand annotations to your documents. You can connect to 20 other tools to enhance your files further.",
        },
        mb: {
          mainTitle: "Work Directly on Your Files",
          details:
            "Do more than just view PDFs.<br>Highlight and add text, images, shapes,<br>and freehand annotations to your documents.<br>You can connect to 20 other tools to enhance your files further.",
        },
      },
      block2: {
        pc: {
          mainTitle: "Create the Perfect Document",
          details: `File too big? Compress it. Need a specific format? Convert it. Things getting chaotic? Merge and split files, or remove excess pages. ${process.env.VUE_APP_APP} has it all.`,
        },
        mb: {
          mainTitle: "Create the Perfect Document",
          details: `File too big? Compress it.<br>Need a specific format? Convert it.<br>Things getting chaotic? Merge and split files, or remove excess pages.<br>${process.env.VUE_APP_APP} has it all.`,
        },
      },
    },
    plans: {
      title: "Plans & Pricing",
      desc: `Get unlimited access to ${process.env.VUE_APP_APP} tools with pricing that’s right for you.`,
      buttonText: "Compare Plans",
      superiorProductPeriod: "month",
      superiorProductDesc: "Billed for one month, unlimited access.",
    },
    chooseUs: {
      title: `Why Choose ${process.env.VUE_APP_APP}?`,
      showBlock: {
        block1: {
          pc: {
            tag: "Security Standards",
            desc: `Your safety isour priority. ${process.env.VUE_APP_APP} is<br>  ISO 27001, GDPR, and CCPA<br> compliant.`,
          },
          mb: {
            tag: "Security Standards",
            desc: `Your safety isour priority. ${process.env.VUE_APP_APP} <br>is ISO 27001, GDPR, and CCPA<br> compliant.`,
          },
        },
        block2: {
          pc: {
            tag: "AI Convert Core",
            desc: "We use advanced AI technology for<br> high-fidelity conversion to maximize<br> the preservation of the document's<br> original style.",
          },
          mb: {
            tag: "AI Convert Core",
            desc: "We use advanced AI technology <br>for high-fidelity conversion to <br>maximize the preservation of the <br>document's original style.",
          },
        },
        block3: {
          pc: {
            tag: "24/7 Customer Support",
            desc: "Get all the help you need with our<br> round-the-clock customer support.",
          },
          mb: {
            tag: "24/7 Customer Support",
            desc: "Get all the help you need with <br>our round-the-clock customer<br> support.",
          },
        },
      },
    },
  },
  footer: {
    copyright: "All rights reserved.",
    company: `${process.env.VUE_APP_COMPANY}, `,
  },
  workspacePage: {
    name: "Workspace",
    recents: "Recents",
    welcome: "Welcome to",
    free: {
      desc: "Free with limited document processing.",
      to: "Upgrade to PRO",
    },
    paidAndExpired: {
      desc: "PRO Member expired at",
      to: "Renew to PRO",
      to2: "Orders & Invoices",
    },
    paid: {
      desc: "PRO Member till",
      to: "Orders & Invoices",
    },
    allTools: "All Tools",
    table: {
      name: "Name",
      size: "Size",
      date: "Date",
      action: "Action",
    },
  },
  convertPage: {
    mergeAndSplitToolName: "Split & Merge",
    convertFromPDFToolName: "Convert from PDF",
    convertToPDFToolName: "Convert to PDF",
    to: "to",
    worker: "Converter",
    choose: "Choose",
    file: "file",
    dropFile: "or drop file here",
    uploading: "Uploading",
    extracting: "Extracting",
    convert: "Convert",
    converting: "Converting",
    unit: "PAGES",
    startOver: "Start Over",
    startOverTip: "Any questions, contact us",
    targetFileErrorDesc: {
      PDF: "This file does not seem to be a PDF",
      Word: "This file does not seem to be a Word",
      Excel: "This file does not seem to be a Excel",
      JPG: "This file does not seem to be a image",
      PPT: "This file does not seem to be a PPT",
    },
    convertErrTitle: "Oops!",
  },
  verifyEmailPage: {
    title: "Verify your email",
    signUpTip: "Don’t have an account?",
    signUpText: "Sign Up",
  },
  verifyCodePage: {
    title: "The verification code has been sent to",
    resentText: "Resend code",
  },
  signUpPage: {
    name: "Sign Up",
    title: "Create your account",
    buttonText: "Sign Up",
    loginTip: "Already have an account?",
    loginText: "Login",
    termsTip:
      "By creating an account, you agree<br>to our&nbsp;%terms%&nbsp;and our&nbsp;%policy%&nbsp;",
  },
  loginPage: {
    name: "Login",
    title: "Login to ",
    forgotTip: "Forgot your password?",
    signUpTip: "Don’t have an account?",
    signUpText: "Sign up",
  },
  profilePage: {
    name: "Profile",
    security: "Security",
    plan: "Plan",
    firstName: "First Name",
    lastName: "Last Name",
    save: "Save",
    saveSuccessDesc: "Save successful",
    saveErrorDesc: "Save failed",
    changePassword: "Change",
    orderAndInvoices: "Orders & Invoices",
  },
  changePasswordPage: {
    title: "Change password",
    currentPasswordPlaceholder: "Current password",
    newPasswordPlaceholder: "New password",
    confirmPasswordPlaceholder: "Confirm password",
    buttonText: "Change",
    authPasswordErr: "The password is incorrect!",
  },
  resetPasswordPage: {
    title: "Reset your password",
    buttonText: "Reset",
    newPasswordPlaceholder: "New password",
    confirmPasswordPlaceholder: "Confirm password",
    inconsistentPassword: "Inconsistent passwords",
  },
  orderPage: {
    title: "Orders & Invoices",

    table: {
      date: "Order Date",
      plan: "Plan",
      paid: "Paid",
      invoice: "Invoice",
    },
  },
  email: {
    name: "Email",
  },
  password: {
    name: "Password",
  },
  tools: {
    name: "Tools",
    loginTitle: "Tools",
    notLoginTitle: "All Tools",
    toolDescText: {
      ptw: "Convert PDFs to editable Word documents",
      pte: "Convert PDFs to editable Excel spreadsheets",
      ptpp: "Convert PDFs to editable PowerPoint presentations",
      ptj: "Extract images from your PDF or save each page as a separate image",
      wtp: "Convert Word documents to PDF files",
      etp: "Convert Excel spreadsheets to PDF documents",
      pptp: "Convert PowerPoint presentations to PDF documents",
      jtp: "Transform JPG, PNG, BMP, GIF, and TIFF images to PDF",
      merge: "Combine multiple PDFs into one unified document",
      split: "Extract pages from your PDF or save each page as a separate PDF",
    },
    feature: {
      ptw: {
        efficient: {
          title: "Quick and easy conversion",
          desc: "With just a simple drag-and-drop, you can convert PDF to Word within seconds. There’s no file size limit nor even the need to register to use our service.",
        },
        privacy: {
          title: "We keep your files safe!",
          desc: "We care about privacy. All files will be deleted from our servers forever after one hour. To know more about how much we care, read our privacy policy.",
        },
        multiPlatform: {
          title: "All platforms are welcome!",
          desc: "Our PDF to Word converter works on all computers - no matter if you use Mac, Windows or Linux.",
        },
        safe: {
          title: "PDF to Word in the best quality",
          desc: "PDF to Word conversion is hard. To bring you the very best quality to convert your PDF files, we have partnered with E-ICEBLUE - the best solution provider on the market.",
        },
        reliable: {
          title: "Convert a PDF to a DOC in seconds",
          desc: "It is seriously ridiculously easy with our tool to convert files online. Try it and you will love it.",
        },
        convenient: {
          title: "Magic conversion in the cloud",
          desc: "We have many servers in the cloud which do nothing else than converting PDF to Word files. So, lean back and let them do the work.",
        },
      },
      wtp: {
        efficient: {
          title: "Quick and Efficient Processing",
          desc: "Word to PDF conversion is a piece of cake. To begin, upload a Word file, sit back, and we should have your PDF ready in no time. We accept both DOC and DOCX files.",
        },
        privacy: {
          title: "Secure Online PDF Software",
          desc: `Have no worries! We permanently remove converted files from our servers after 1 hour. Create a free ${process.env.VUE_APP_APP} account if you want to store converted files online instead.`,
        },
        multiPlatform: {
          title: "Any Device, Any Operating System",
          desc: "Being an online browser-based software, you can conveniently access our Doc to PDF converter anytime, anywhere—from your PC, phone, or tablet.",
        },
        safe: {
          title: "Other Supported File Types",
          desc: "The fun doesn’t stop there. With this all-in-one PDF converter, you can transform other file formats to PDF, such as JPG, PNG, Excel, and PPT.",
        },
        reliable: {
          title: "Advanced Word to PDF Conversion",
          desc: `If you convert plenty of files daily, check out ${process.env.VUE_APP_APP} Pro, where you can process as many Word files to PDF as you’d like, all in one go, up to 15 GB per file.`,
        },
        convenient: {
          title: "Work on the Cloud",
          desc: "We want to enable a seamless digital work environment. Once you convert a DOC file to PDF—freely preview, edit, and store it—all on the cloud.",
        },
      },
      pte: {
        efficient: {
          title: "Instantly convert PDF to Excel",
          desc: "With this online application, you can quickly export PDF documents into Excel files. All data will retain its original format, including every sheet, table, row, and column.",
        },
        privacy: {
          title: "Accurate conversion",
          desc: "We've teamed up with E-ICEBLUE to ensure that our conversion algorithm is top-notch. You will be able to resume work on your Microsoft Excel spreadsheets within seconds.",
        },
        multiPlatform: {
          title: "Optical Character Recognition",
          desc: "Scanned documents aren’t an issue. With the latest OCR technology, this online tool can effortlessly extract text and figures from PDFs into editable Excel documents.",
        },
        safe: {
          title: "Secure cloud conversion",
          desc: "Our cloud servers process PDFs non-stop and always remove them one-hour post-conversion. You can access our platform on any web browser and operating system.",
        },
        reliable: {
          title: "Every size allowed",
          desc: `To handle large datasets, ${process.env.VUE_APP_APP} can process up to 1GB per file. With a Pro account, you can even save multiple PDF files to Excel format simultaneously.`,
        },
        convenient: {
          title: "Offline conversion",
          desc: `Do check out the desktop version of ${process.env.VUE_APP_APP} if you wish to convert PDF files locally. It comes with all the handy tools we have online, along with a free reader, for all your PDF needs.`,
        },
      },
      etp: {
        efficient: {
          title: "Easy, single click conversion",
          desc: "The most popular online tool to quickly transform your Microsoft Excel spreadsheets into PDF documents for ease of sharing and storage.",
        },
        privacy: {
          title: "Safe conversion",
          desc: "All your Excel and PDF files will be permanently deleted from our server after one hour. Check out our privacy policy below for more information.",
        },
        multiPlatform: {
          title: "Platform Neutral",
          desc: "We convert Excel to PDF across all platforms, no matter which OS you are using - Windows, Mac or Linux.",
        },
        safe: {
          title: "Excel to PDF in no time!",
          desc: "Your Excel file is quickly converted using automatic settings. The generated PDF will look exactly the way you want with no need to choose any complicated options!",
        },
        reliable: {
          title: "Create a PDF with just one click",
          desc: "Just upload the file, we convert your XLSX or XLS to PDF and our servers do the rest. Can we make it any easier?",
        },
        convenient: {
          title: "Conversion in the cloud",
          desc: "Your Excel to PDF conversion happens in the cloud and won’t use any of your computer’s capacity. Now isn’t that cool?",
        },
      },
      ptpp: {
        efficient: {
          title: "Transform PDF files to PPTX slides",
          desc: "Use our online service to convert PDF to PPT in a heartbeat. The output PowerPoint presentation will retain its original formatting, for you to revise and edit.",
        },
        privacy: {
          title: "Safety first!",
          desc: "You care about privacy. We care about privacy. That's why all files are deleted from our servers one hour after conversion. Check out our privacy policy for more info.",
        },
        multiPlatform: {
          title: "Any operating system works",
          desc: "Conversion from PDF to PPT works on all computers - the operating system you use doesn't matter.",
        },
        safe: {
          title: "Quick and easy conversion!",
          desc: "If your PDF is in a presentation format, the conversion should be no problem. Just try it and see the result with your own eyes.",
        },
        reliable: {
          title: "PDF to PowerPoint in top quality",
          desc: `PDF to PPT conversion is extremely complicated. To bring you great quality, ${process.env.VUE_APP_APP} has partnered with E-ICEBLUE that offers the best conversion out there.`,
        },
        convenient: {
          title: "All in the cloud",
          desc: "Our servers in the cloud process your files and you don't need to install anything on your computer. Isn't that great?",
        },
      },
      pptp: {
        efficient: {
          title: "Turn PPT files to PDF in one click",
          desc: "Use our online converter to quickly transform your PowerPoint presentations into PDF documents for easy sharing. All slides will preserve the formatting and layout.",
        },
        privacy: {
          title: "Privacy is Guaranteed",
          desc: "We care about security. After converting from PPT to PDF, all your files will be permanently deleted from our servers. Please read our privacy policy for more details.",
        },
        multiPlatform: {
          title: "All operating systems are supported",
          desc: "Our PPT to PDF converter works on all platforms. Regardless of which OS you are using - Windows, Mac or Linux.",
        },
        safe: {
          title: "Conversion is made simple",
          desc: "Your PPT file is converted to PDF without any additional options. So just upload the file, sit back, relax and we’ll take care of the rest!",
        },
        reliable: {
          title: "Both PPT and PPTX are supported",
          desc: "We convert both PPT and PPTX formats. All you need to do is upload the files and we will convert them.",
        },
        convenient: {
          title: "Cloud-based servers",
          desc: "We convert your PPT to PDF in the cloud. This means you can access from anywhere and we save your computer from doing all the hard work.",
        },
      },
      ptj: {
        efficient: {
          title: "PDF to JPG conversion made easy",
          desc: "The best online web app to transform PDFs into high-quality JPG images within seconds. No file size limit nor registration is required. Simply upload your file and let us work our magic.",
        },
        privacy: {
          title: "Convert PDF pages to Images",
          desc: "Use the PDF to JPEG converter to transform entire PDF pages into high-quality JPG images. All image quality and resolution will remain intact.",
        },
        multiPlatform: {
          title: "Extract images in your PDF",
          desc: "Alternatively, you can easily extract every single embedded image in your PDF file into JPG format, for your convenience.",
        },
        safe: {
          title: "Secure online conversion",
          desc: `${process.env.VUE_APP_APP} adopts SSL connections to guarantee maximum security for your files. We delete all documents one hour after conversion.`,
        },
        reliable: {
          title: "Portable cloud conversion",
          desc: "Our online service can change PDF to images in any browser and operating system: Windows, Mac, and Linux. No software installation is required.",
        },
        convenient: {
          title: "Work on the go",
          desc: "On the road a lot without internet access? Do check out our desktop version, which allows you to convert PDF documents to JPG, PNG, GIF, TIFF, and BMP offline.",
        },
      },
      jtp: {
        efficient: {
          title: "Fast and easy conversion",
          desc: "You can drag and drop your JPG image into the toolbox above to quickly transform it into a PDF document. Various settings are available for you to adjust the format of the output file.",
        },
        privacy: {
          title: "File protection guaranteed",
          desc: "We use SSL encryption to ensure that your images are entirely secure throughout the transfer. Nobody will have access to your files, which we will also erase after one hour of processing.",
        },
        multiPlatform: {
          title: "All platforms supported",
          desc: "Our online JPG to PDF converter is browser-based and functions independently from your OS. Because of this, you can freely access our application using a Mac, Windows or Linux.",
        },
        safe: {
          title: "Add additional documents",
          desc: "After the initial file upload, there is also an option for you to add more images, in case you wish to save and combine multiple image files into one PDF with our online service.",
        },
        reliable: {
          title: "Other image formats",
          desc: "This online tool also functions as an all-in-one image to PDF converter. As such, you can also add GIF, BMP, TIFF, and PNG to save them to PDF format. Diversity rules!",
        },
        convenient: {
          title: "Convert in the cloud",
          desc: "The JPEG to PDF conversion happens in the cloud and will not deplete any capacity from your CPU. Better yet, no time will be wasted on software installation. Cool, isn't it?",
        },
      },
      merge: {
        efficient: {
          title: "Simple online tool to merge PDFs",
          desc: "Our PDF merger allows you to quickly combine multiple PDF files into one single PDF document, in just a few clicks. No signup is needed to use this online tool.",
        },
        privacy: {
          title: "Secure PDF merging online",
          desc: "All the files you upload, as well as the file generated on our server, will be deleted permanently within an hour. Read our privacy policy below for more details.",
        },
        multiPlatform: {
          title: "Works for Windows, Mac, and Linux",
          desc: "The web app is browser-based. So, it works for all operating systems including Mac, Windows, and Linux.",
        },
        safe: {
          title: "Easy PDF combining with a preview",
          desc: "By dragging your pages in the editor area you can rearrange them or delete single pages. Also, you can add more PDFs to combine them and merge them into one single document.",
        },
        reliable: {
          title: "A reliable service",
          desc: "To merge PDFs or just to add a page to a PDF you usually have to buy expensive software. This online service is safe and secure.",
        },
        convenient: {
          title: "Processing in the cloud",
          desc: "Our servers in the cloud will handle the pdf creation for you once you have combined your files. So, it won’t drain any capacity from your computer.",
        },
      },
      split: {
        efficient: {
          title: "Two-click separation process",
          desc: "Instantly divide your PDF into individual one-pagers, or extract specific pages to form a new PDF document. You can also add and extract pages from multiple PDFs simultaneously.",
        },
        privacy: {
          title: "Secure PDF splitting online",
          desc: "We have a privacy policy that explains exactly how important security and your privacy is to us. We delete all your files permanently from our servers one hour after upload.",
        },
        multiPlatform: {
          title: "Splitting PDFs on all platforms",
          desc: "As a web application, you can split PDFs on all operating systems using the latest web browsers. It works on Windows, Mac, and Linux.",
        },
        safe: {
          title: "Split PDF with preview",
          desc: "Our PDF splitter gives you a preview of the pages you want to split. You can select pages in the visual interface or just type in the number of the pages you want to extract.",
        },
        reliable: {
          title: "Affordable online software",
          desc: "Usually, to split a PDF you have to spend a lot of money on premium software. We offer it at affordable monthly rates.",
        },
        convenient: {
          title: "PDF processing in the cloud",
          desc: "All the hard splitting, extracting and deleting work happens in the cloud. So it won't use any of your computer's resources.",
        },
      },
    },
  },
  plan: {
    free: {
      tag: "Free",
      desc: "Limited document processing",
    },
    paidAndExpired: {
      tag: "PRO Member expired at ",
      desc: "Limited document processing",
    },
    paid: {
      tag: "PRO Member till ",
      desc: "Unlimited document processing",
    },
  },
  siteMap: {
    company: "Company",
    about: "About",
    contactUs: "Contact Us",
    inside: "Help Online",
    product: "Product",
    pricing: "Pricing",
    help: "Help",
  },
  contact: {
    name: "Contact Us",
    address: "#41-01 Hong Leong Building,<br/>16 Raffles Quay, Singapore 048581"
  },
  limitRule: {
    notLogin: {
      convert: {
        tag: "Do you have more to do?",
        desc: "Sign up for more processing times",
        action: "Sign up",
      },
      upload: {
        tag: "Oops! The file is too large.",
        desc: "The file size is limited to 5M,sign up to remove the limitation.",
        action: "Sign up",
      },
    },
    login: {
      upload: {
        tag: "Oops! The file is too large.",
        desc: `The file size is limited to 15M, upgrade to ${process.env.VUE_APP_APP} PRO to get unlimited access.`,
        action: "Upgrade to PRO",
      },
      convert: {
        tag: "Do you have more to do?",
        desc: `Upgrade to ${process.env.VUE_APP_APP} PRO to get unlimited access.`,
        action: "Upgrade to PRO",
      },
    },
    paidAndExpired: {
      upload: {
        tag: "Oops! The file is too large.",
        desc: `The file size is limited to 15M, renew to ${process.env.VUE_APP_APP} PRO to get unlimited access.`,
        action: "Renew to PRO",
      },
      convert: {
        tag: "Do you have more to do?",
        desc: `Renew to ${process.env.VUE_APP_APP} PRO to get unlimited access.`,
        action: "Renew to PRO",
      },
    },
  },
  about: {
    name: `We’re ${process.env.VUE_APP_APP}`,
    content: `Made in Singapore 2019, growing from a single tool to a PDF toolset, ${process.env.VUE_APP_APP} provides simple, secure, and reliable answers to the world's PDF challenges with more than 20 PDF tools - for work, for home, for life.`,
  },
  splitPage: {
    name: "Split",
    // 文件属性中的文件个数
    files: "Files",
    splitFile: "Split 1 PDF into many",
    splitFileDesc:
      "For example, split a book into chapters or individual pages. You get multiple PDFs.",
    splitPage: "Extract pages",
    splitPageDesc:
      "For example, extract a single chapter or page from a book. You get 1 PDF.",
    navLeftText: "Extract Pages",
    navRightText: "Split",
    addMore: "Add more",
    page: "page",
    pages: "pages",
    workLoadingText: "Extracting...",
    workButtonText: "Extract",
    workButtonMobileText: "Extract to 1 PDF",
    workLoadingSplitText: "Splitting...",
    workButtonSplitText: "Split",
    workButtonSplitMobileText: "Split to %totalNumber% PDFs",
    selectAll: "Select all",
    SplitAllPage: "Split after every page",
    tip: "Press&nbsp;%img%%shift%&nbsp;to select multiple pages at once.",
    tipKey: "Shift",
  },
  mergePage: {
    name: "Merge",
    // 文件属性中的文件个数
    files: "Files",
    mergeFile: "Merge files",
    mergeFileDesc: "Arrange and combine entire PDFs any way you like.",
    mergePage: "Merge pages",
    mergePageDesc: "Select, arrange, and combine individual pages of PDFs.",
    navLeftText: "Merge files",
    navRightText: "Merge pages",
    addMore: "Add more",
    addErrorText:
      "could not be added. The file might be encrypted, corrupted or an unsupported file type.",
    workLoadingText: "Merging...",
    workButtonText: "Merge",
    tip: "Long press to start editing",
    exitEditButtonText: "Done",
    editHint: "Drag and drop to reorder pages",
    confirmRemove: "Delete",
  },
};
export default en;
