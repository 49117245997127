import { tools } from "@/common/tools";
import utils from "@/util/utils";

const _origin = window.location.origin;
const _defaultHTML = "index.html";
let work = utils.getQuery("work");
if (!Object.keys(tools).includes(work)) {
  work = Object.keys(tools)[0];
}
let from = utils.getQuery("from");
let path = utils.getQuery("path");
let _local = `${tools[work].local}.html` + (path ? `?path=${path}` : "");

let backRoute = {
  buy: {
    product: {
      toLocal: `${_defaultHTML}#/workspace`,
    },
    home: {
      toLocal: `${_defaultHTML}#/workspace`,
    },
    workspace: {
      toLocal: `${_defaultHTML}#/workspace`,
    },
    tool: {
      toLocal: `${_defaultHTML}#/workspace`,
    },
    about: {
      toLocal: `${_defaultHTML}#/workspace`,
    },
    profile: {
      toLocal: `${_defaultHTML}#/workspace`,
    },
    work: {
      toLocal: _local,
    },
  },
  signUp: {
    home: {
      toLocal: `${_defaultHTML}#/`,
    },
    tool: {
      toLocal: `${_defaultHTML}#/tool`,
    },
    about: {
      toLocal: `${_defaultHTML}#/about`,
    },
    login: {
      toLocal: `${_defaultHTML}#/login`,
    },
    product: {
      toLocal: `${_defaultHTML}#/product`,
    },
    work: {
      toLocal: `${_local}`,
    },
  },
};

function toBackNow(mode) {
  if (!from || !Object.keys(backRoute[mode]).includes(from)) from = "home";
  window.location.href = `${_origin}/${backRoute[mode][from].toLocal}`;
}
function getBackUrl(mode) {
  if (!from || !Object.keys(backRoute[mode]).includes(from)) from = "home";
  return `${_origin}/${backRoute[mode][from].toLocal}`;
}
export { toBackNow, getBackUrl };
