<template>
  <div
    class="wrapper"
    :style="{
      backgroundColor: backgroundColor,
      border:
        store.workStatus === 'mergeOrSplitBefore' ||
        store.workStatus === 'mergeOrSplitView'
          ? 'none'
          : '1px solid' + borderColor,
    }"
  >
    <slot></slot>
  </div>
  <div class="filler how"></div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "WorkArea",
  components: {},
  props: {
    backgroundColor: {
      type: String,
      default: "#f5f5f5",
    },
    borderColor: {
      type: String,
      default: "#f5f5f5",
    },
    highStyle: {
      type: Object,
      default: () => {
        return { border: "2px dashed #6990f2" };
      },
    },
  },
  setup() {
    let store = useStore().state;
    return {
      store,
    };
  },
  created() {},
};
</script>

<style scoped>
::selection {
  color: #fff;
  background: #6990f2;
}
.wrapper {
  width: 100%;
  max-width: 920px;
  min-height: 220px;
  /*padding: 2rem;*/
  /*box-shadow: -2px 5px 12px 15px rgb(0 0 0 / 5%);*/
  /*background: #f5f5f5;*/
  /*border: 1px dashed #cccccc;*/
  border-radius: 2px;
  margin-bottom: 36px;
}

.filler {
  z-index: 3;
  height: 14px;
  width: 100%;
  background-color: #ffffff;
}
.how {
  position: sticky;
  bottom: 0;
}
@media all and (max-width: 640px) and (min-width: 320px) {
  .wrapper {
    margin: 0 24px 46px 24px;
    width: calc(100% - 48px);
    min-height: 210px;
  }
}
@media all and (max-width: 1020px) and (min-width: 640px) {
  .wrapper {
    margin: 0 40px 46px 40px;
    width: calc(100% - 80px);
  }
}
</style>
