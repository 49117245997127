<template>
  <button type="button" :style="styleObject" @click="toWork(work)">
    <img :src="imgUrl" alt="load error" />
    <span>{{ buttonText }}</span>
  </button>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import utils from "@/util/utils";
export default {
  name: "ColumnToolBlock",
  components: {},
  props: {
    work: {
      default: "",
      type: String,
    },
    imgUrl: {
      default: "",
      type: String,
    },
    buttonText: {
      default: "",
      type: String,
    },
    style: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    let useStores = useStore();
    let store = useStores.state;
    let styleObject = computed(() => {
      return {
        "--button-color--active": props.style.activeTextColor,
        "--button-background-color--active": props.style.activeBackground,

        "--button-color--hover": props.style.hoverTextColor,
        "--button-background-color--hover": props.style.hoverBackground,
      };
    });

    function toWork(work) {
      try {
        utils.changeRecents(store.account.code, work);
        let _local = store.tools[work].local;
        utils.toWork(_local);
      } catch (e) {
        console.log(e);
      }
    }

    return {
      store,
      toWork,
      styleObject,
    };
  },
};
</script>

<style scoped>
button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 8px 8px 14px;
  background-color: transparent;
  color: #454545;
  font-weight: 400;
  width: 100%;
  height: auto;
}

button:hover {
  color: var(--button-color--hover);
  background-color: var(--button-background-color--hover);
}

button:active {
  color: var(--button-color--active);
  background-color: var(--button-background-color--active);
}

img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

@media all and (max-width: 570px) and (min-width: 320px) {
  button {
    font-size: 16px;
    padding: 0;
  }
}
</style>
