<template>
  <div class="file" v-if="pdfSize">
    <span v-if="numPages">{{ numPages }} {{ $t("convertPage.unit") }} </span>
    <span>{{ pdfSize }}</span>
  </div>
</template>

<script>
// import { toRefs, reactive, ref } from 'vue'

export default {
  name: "FileProperty",
  components: {},
  props: {
    numPages: {
      default: 0,
      type: Number,
    },
    pdfSize: {
      default: "",
      type: String,
    },
  },
  setup() {},
  created() {},
};
</script>

<style scoped>
.file {
  font-size: 14px;
  font-weight: 700;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1px 0 31px;
  border-left: 1px solid #d9d9d9;
  margin-left: 34px;
}
.file span:not(:first-child) {
  margin-left: 10px;
}

@media all and (max-width: 640px) and (min-width: 320px) {
  .file {
    margin: 0;
    padding: 0;
    border-left: none;
    font-weight: 400;
    grid-row: 2/3;
    grid-column: 1/3;
  }
}
</style>
