import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import utils from "../util/utils";

class Account {
  static current = null;
  static store_key = `${process.env.VUE_APP_STORAGE_PREX}acc`;
  static store_byb_key = `${process.env.VUE_APP_STORAGE_PREX}byb`;

  constructor(data) {
    Object.assign(this, data);
  }

  asCurrent() {
    Account.current = this;
  }

  fresh() {
    return utils
      .getMe()
      .then((res) => {
        Account.storage(res.data.sid);
        Object.assign(this, res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static storage(value, key = this.store_key) {
    localStorage.setItem(key, value);
  }
  static isFirst() {
    return !this.getByb();
  }
  static getByb() {
    let result = null;
    try {
      let _r = localStorage.getItem(this.store_byb_key);
      if (_r.length > 40) {
        try {
          result = JSON.parse(_r).sid
          Account.storage(result, this.store_byb_key);
        } catch (e){
          console.log(e);
        }
      }

    } catch (e) {
      console.log(e);
    }
    return result;
  }

  static accountSource(referrer) {
    const udid = this._genUdid();
    const data = {
      channel: "official",
      device: { udid: udid },
    };

    if (referrer) {
      data["referrer"] = referrer;
    }
    return data;
  }
  static anonymous(referrer) {
    return axios.post(
      `${process.env.VUE_APP_HOST_GAS}/api/accounts/anonymous`,
      this.accountSource(referrer)
    );
  }
  static nonAnonymous(referrer) {
    return axios.post(
      `${process.env.VUE_APP_HOST_GAS}/api/accounts`,
      this.accountSource(referrer)
    );
  }

  static _genUdid() {
    return `BR-${uuidv4()}`;
  }

  static create(referrer) {
    return new Promise((resolve, reject) => {
      this.anonymous(referrer)
        .then((res) => {
          const acc = new Account(res.data);
          Account.storage(res.data.sid);
          resolve(acc);
        })
        .catch((err) => {
          console.log(`Create account failed: ${err}`);
          reject(null);
        });
    });
  }

  static load() {
    let data = localStorage.getItem(this.store_key);
    let acc = null;
    if (data) {
      try {
        if (data.length > 40) {
          data = JSON.parse(data).sid
          Account.storage(data);
        }
        acc = new Account({ sid: data });
      } catch (err) {
        console.log(err);
      }
    }

    return acc;
  }

  static async toggle() {
    let _bybAccSid = this.getByb();
    try {
      if (!_bybAccSid) {
        let _bybAcc = await this.createByb();
        _bybAccSid = _bybAcc.sid;
      }
      Account.storage(_bybAccSid);
      utils.setCookie("sid", _bybAccSid);
    } catch (e) {
      console.log(e);
    }
  }

  static createByb() {
    return new Promise((resolve, reject) => {
      this.anonymous()
        .then((res) => {
          Account.storage(res.data.sid, this.store_byb_key);
          resolve(res.data);
        })
        .catch((err) => {
          console.log(`Create account failed: ${err}`);
          reject(null);
        });
    });
  }
}

const AccountStoreModule = {
  namespaced: true,
  state: () => ({
    id: "",
    code: "",
    email: "",
    first_name: "",
    last_name: "",
    has_password: false,
    vip: { expire: "", level: 0 },
  }),
  mutations: {
    all(state, payload) {
      Object.keys(state).forEach((key) => {
        if (key in payload) {
          state[key] = payload[key];
        }
      });
    },
  },
  actions: {
    async init(context, referrer) {
      let acc = Account.load();
      if (!acc) {
        acc = await Account.create(referrer);
      }

      try {
        utils.setCookie("sid", acc.sid);
        let res = await utils.getMe();
        acc = new Account(res.data);
        context.commit("all", res.data);
        acc.asCurrent();
      } catch (err) {
        console.log(`Init app failed: ${err}`);
      }
    },
    async fresh(context) {
      if (!Account.current) {
        return;
      }

      const acc = Account.current;
      await acc.fresh();
      context.commit("all", acc);
    },
  },
};

export default Account;
export { AccountStoreModule };
