<template>
  <form
    action="#"
    ref="from"
    @click="uploadFileClick"
    @drop="drop"
    @dragleave="dragleave"
    @dragover="dragover"
  >
    <input
      multiple="multiple"
      :accept="rule.accept"
      @change="changeInput"
      @click.stop=""
      ref="fileInput"
      type="file"
      name="file"
      hidden
    />
    <div class="head flex">
      <img class="head-img" :src="store.tools[store.work].iconMain" />
    </div>

    <div
      class="but button flex"
      :style="{ backgroundColor: backgroundColorButton }"
    >
      <img
        class="small-icon-8 icon"
        src="@/assets/images/work/icon_folder.png"
      />
      <span>{{
        `${$t("convertPage.choose")} ${rule.name} ${$t("convertPage.file")}`
      }}</span>
    </div>
    <span class="or">{{
      $t("convertPage.dropFile") || "or drop file here"
    }}</span>
  </form>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "ChooseFile", // 选择文件
  components: {},
  props: {
    backgroundColorButton: {
      type: String,
      default: "#00A3FF",
    },
    rule: {
      type: Object,
      default: () => {
        return { name: "PDF", accept: "" };
      },
    },
    icon: String,
    name: String,
  },
  setup(props, context) {
    let store = useStore().state;
    let from = ref(null);
    let fileInput = ref(null);
    function uploadFileClick() {
      fileInput.value.click();
    }

    function changeInput(event) {
      let files = event.target.files;
      context.emit("choose", files);
      fileInput.value.value = null;
    }

    document.ondragover = function (e) {
      e.preventDefault();
    };
    document.ondrop = function (e) {
      e.preventDefault();
    };
    function dragleave(e) {
      // 脱离区域
      context.emit("filedragleave", e);
    }
    function dragover(e) {
      e.preventDefault();
      // 区域内
      context.emit("filedragover", e);
    }
    function drop(e) {
      // 释放拖放
      let list = e.dataTransfer.files;
      context.emit("filedragoverend", list);
    }

    return {
      store,
      drop,
      dragleave,
      dragover,
      from,
      fileInput,
      changeInput,
      uploadFileClick,
    };
  },
  created() {},
};
</script>

<style scoped>
form {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  border-radius: 2px;
  box-sizing: border-box;
  padding-top: 10px;
}
form .head {
  justify-content: center;
  margin-bottom: 10px;
}
form .head-img {
  pointer-events: none;
  width: 92px;
  height: 92px;
}
form .button {
  margin-bottom: 14px;
}
.or {
  font-size: 14px;
  margin: 0 0 0 0;
}
.icon {
  width: 18px;
  height: 18px;
}
.button {
  color: #ffffff;
}
@media all and (max-width: 640px) and (min-width: 320px) {
  form {
    padding: 28px 38px 38px 38px;
  }
  form .head {
    margin-bottom: 18px;
  }
  form .button {
    margin-bottom: 0;
  }
  .or {
    display: none;
  }
}
@media all and (max-width: 1020px) and (min-width: 640px) {
  .wrapper {
    margin: 0 40px 46px 40px;
    width: calc(100% - 80px);
  }
}
</style>
