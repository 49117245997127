<template>
  <div
    class="cookie-use"
    :class="{
      mask: !scroll
    }"
  >
    <div class="hint-box flex"
      :style="{position: scroll ? 'fixed' : 'absolute'}"
    >
      <h1 class="title" v-html="$t('useCookieAuth.title')"></h1>
      <div class="desc" :class="locale === 'en' ? 'hyphens' : 'hyphens-other'" v-html="tip"></div>
      <div class="button flex">
        <button @click="setUseAuth">{{$t('useCookieAuth.buttonText')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import { computed, ref } from "vue";
import { i18nHTML } from "@/language/i18n";
import { useI18n } from "vue-i18n";
import utils from "@/util/utils";
import Button from "@/components/Button";

export default {
  name: "CookieUse",
  components: { Button },
  setup(props, context) {
    let route = useRoute();
    const { t, locale } = useI18n();

    let tip = computed(() => {
      let souObj = {
        sourceStr: t("useCookieAuth.desc"),
        child: [
          {
            tag: "img",
            target: "%cookie%",
            attrs: {
              src: require("@/assets/images/icon_cookie.svg"),
            },
          },
          {
            tag: "a",
            target: "%privacy%",
            text: t("privacyPolicy.title"),
            attrs: {
              href: "/index.html#/privacyPolicy",
            },
          },
        ],
      };
      return i18nHTML(souObj);
    });

    let scroll = ref(route.name === 'privacyPolicy' || route.name === 'terms')
    function setUseAuth() {
      utils.setCookie('ccu', true)
      context.emit("authCookie")
    }

    return { tip, setUseAuth, route, scroll, locale };
  },
  created() {},
};
</script>

<style scoped>
.mask {
  z-index: 2000000010 !important;
}
.hint-box {
  background: #FFFFFF;
  border-radius: 8px;
  width: 400px;
  padding: 18px;
  height: auto;
  right: 24px;
  bottom: 24px;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
}
.title {
  font-size: 18px;
  font-weight: 700;
}
.desc {
  margin: 8px auto 24px auto;
  font-size: 14px;
  line-height: 20px;
}
.button {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}
.button button {
  font-size: 16px;
  min-width: 88px;
  height: 36px;
}

.hyphens {
  hyphens:auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
}

.hyphens-other {
  overflow-wrap: break-word;
  word-break: break-all;
}

@media all and (max-width: 765px) and (min-width: 320px) {
  .hint-box {
    left: 2.13%;
    right: 2.13%;
    bottom: 1.2%;
    width: auto;
    height: auto;
  }
  .button button {
    font-size: 14px;
    min-width: 104px;
    height: 44px;
  }

}

</style>
