<template>
  <span class="badge" :class="{ 'radius-badge': isRadiusBadge }">
    <span>
      {{ $t("pro.name") }}
    </span>
  </span>
</template>

<script>
export default {
  name: "Badge",
  props: {
    isRadiusBadge: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.badge {
  font-weight: bold;
  font-size: 14px;
  color: #ffe8ae;
  padding: 0 5px;
  background-color: #2f2f2f;
  margin-left: 6px;
  border-radius: 2px;
}
.radius-badge {
  border: 1px solid #ffe8ae;
  box-sizing: border-box;
  border-radius: 10px;
}
.badge span {
  /*background: linear-gradient(180deg, #FFC178 20.83%, #FFF4D9 51.56%, #FEAA47 86.46%);*/
  /*color: transparent;*/
  -webkit-background-clip: text;
  background-clip: text;
}
</style>
