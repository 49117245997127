<template>
  <div class="bar overflow" :style="styleObject"></div>
</template>

<script>
import { computed } from "vue";

export default {
  name: "Bar",
  components: {},
  props: {
    style: {
      type: Object,
      default: () => {
        return {
          width: "420px",
          height: "6px",
          borderRadius: "2px",
          backgroundColor: "#00A3FF",
          lineBackgroundColor: "#9CDBFF",
        };
      },
    },
  },
  setup(props) {
    let styleObject = computed(() => {
      return {
        "--width": props.style.width,
        "--height": props.style.height,
        "--border-radius": props.style.borderRadius,
        "--background": props.style.backgroundColor,
        "--border": `1px solid ${props.style.backgroundColor}`,
        "--bar-background": `repeating-linear-gradient(320deg, ${props.style.lineBackgroundColor},
           ${props.style.lineBackgroundColor} 3px, transparent 3px, transparent 6px, ${props.style.lineBackgroundColor} 6px)`,
      };
    });

    return {
      styleObject,
    };
  },
  created() {},
};
</script>

<style scoped>
.bar {
  position: relative;
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background: var(--background);
  border: var(--border);
  box-sizing: border-box;
}
.bar::before {
  background: var(--bar-background);
}
.bar::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 72px;
  background-position: 0 0;
  background-repeat: no-repeat;
  animation: move 1.45s linear infinite;
}
.overflow {
  overflow: hidden;
}

@keyframes move {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 56px;
  }
}
</style>
