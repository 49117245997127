const zh_tc = {
  app: `${process.env.VUE_APP_APP}`,
  pro: {
    name: "PRO",
  },
  useCookieAuth: {
    title: "我們將使用 cookies",
    desc: "本網站使用 cookie %cookie% 和類似技術（統稱為“cookie”），這些技術對於本網站的運營及其核心功能至關重要。其他 cookie 只會在您同意的情況下放置。有關更多詳細信息，請訪問我們的 %privacy% 。",
    buttonText: "好 的"
  },
  error: {
    // 賬戶不存在
    accountNotExist: "該帳戶不存在",
    // 賬戶存在
    accountExist: "該帳戶已存在",
    emailFormatErr: "Email 格式不正確",
    wrongPassword: "密碼錯誤",
    comparePasswordErr: "密碼不一致",
    // 密碼長度較短
    passwordHintTextShort: "密碼長度必須8位或以上",

    passwordHintTextLong: "密碼必須包含字母，數字，符號中的2到3種",
    codeVerifyErrText: "驗證碼不正確",
    // 未輸入完整的驗證碼強行校驗時觸發
    codeShortText: "驗證碼不正確",
    // 不是正常操作進入頁面時觸發
    illogicalText: "請重新登錄",
    // 未知錯誤
    unknownError: "未知錯誤",
    sendCodeErr: "驗證碼發送失敗",
    // 發生頻繁
    sendCodeFrequently: "驗證碼發送太頻繁",

    // 401 - sid未提供或未找到指定的帳戶sid
    // 403 - 找到的帳號sid，但無權操作指定資源
    // 415 - 不支持的源或目標格式
    // 504 - 超時
    convertERR400: "Unacceptable files",
    convertERR401: "Invalid account",
    convertERR403:
      "The account does not have the right to operate the specified resources",
    convertERR415: "Unsupported Media Type",
    convertERR422: "該文件無效，請檢查並重試。",
    convertERR500: "Unknown error, Please start over",
    convertERR504: "Time out",
    convertERR522: "該文件被鎖定，請解鎖後重試。",
  },
  action: {
    download: "下載",
    next: "下一步",
    pending: "請等待",
  },
  privacyPolicy: {
    title: "隱私政策",
  },
  termsConditions: {
    title: "使用條款",
  },
  account: {
    name: "帳戶",
  },
  head: {
    say: "您好！%userName%",
    signOut: "退出登錄",
  },
  payPage: {
    title: `購買 ${process.env.VUE_APP_APP} PRO`,
    // 支付頁面
    desc: "不受限製的文件處理",
    creditcards: "Credit Cards",
    grabpay: "Grab Pay",
    alipayglobal: "Alipay Global",
    alipaydesc: "請在跳轉至支付寶後完成支付",
    alreadypaid: "已經支付？",
    freshmem: "點擊刷新",
    loading: "正在加載...",
    success: {
      pc: {
        title: "支付成功",
        desc: "即將跳轉到之前工作的頁面（%time%秒）。",
      },
      mb: {
        title: "支付成功",
        desc: "即將跳轉到<br>之前工作的頁面（%time%秒）。",
      },
    },
    error: {
      pc: {
        title: "出錯啦！",
        desc: "請選擇其它支付方式",
      },
      mb: {
        title: "出錯啦！",
        desc: "請選擇其它支付方式",
      },
    },
  },
  productPage: {
    title: "請根據您的預算選擇",
    shortTermButtonText: "短期",
    longTermButtonText: "長期",
    // 價格後面顯示的時間單位, 如 5.99/月
    showPeriod: "月",
    unit: "$",
    unitText: "",
    days: "天",
    pro_0: {
      butText: "註冊",
      // 使用 , 切分
      rules: `訪問所有的 ${process.env.VUE_APP_APP} 工具,每天最多處理3個文件,文件體積最大15M`,
      cardBilledtext: "",
    },
    pro_30d: {
      billed: "30天不受限製的文件處理",
      butText: "購買 PRO",
      // 訂單頁面的plan使用
      plan: "30天 PRO會員",
      // 使用 , 切分
      rules: `訪問所有的 ${process.env.VUE_APP_APP} 工具,不受限製的文件處理,文件體積最大1G,文件儲存`,
      cardBilledtext: "",
    },
    pro_90d: {
      billed: "90天不受限製的文件處理<br>一次性支付 %totalPrice%.",
      butText: "購買 PRO",
      plan: "90天 PRO會員",
      // 使用 , 切分
      rules: `訪問所有的 ${process.env.VUE_APP_APP} 工具,不受限製的文件處理,文件體積最大1G,文件儲存`,
      cardBilledtext: "Billed as  %totalPrice%",
    },
    pro_180d: {
      billed: "180天不受限製的文件處理<br>一次性支付 %totalPrice%.",
      butText: "購買 PRO",
      plan: "180天 PRO會員",
      // 使用 , 切分
      rules: `訪問所有的 ${process.env.VUE_APP_APP} 工具,不受限製的文件處理,文件體積最大1G,文件儲存`,
      cardBilledtext: "Billed as  %totalPrice%",
    },
    pro_365d: {
      billed: "365天不受限製的文件處理<br>一次性支付 %totalPrice%.",
      butText: "購買 PRO",
      plan: "365天 PRO會員",
      // 使用 , 切分
      rules: `訪問所有的 ${process.env.VUE_APP_APP} 工具,不受限製的文件處理,文件體積最大1G,文件儲存`,
      cardBilledtext: "Billed as  %totalPrice%",
    },
  },
  home: {
    banner: {
      mainSlogan: "一站式 PDF 處理",
      subSlogan: {
        pc: "轉換、合並、切分，<br>您需要的工具這都有",
        mb: "轉換、合並、切分，<br>您需要的工具這都有"
      },
      buttonText: "查看所有 PDF 工具",
    },
    toolSlogan: "最熱門的 PDF 工具",
    toolHint: "查看所有工具",

    showBlock: {
      title: "非常簡單，交給我們",
      block1: {
        pc: {
          mainTitle: "在線編輯您的文件",
          details:
            "PDF 不僅是用來看的。<br>在您的文檔中使用高亮標註、添加圖文形狀、備註等。<br>文檔處理格外犀利。",
        },
        mb: {
          mainTitle: "在線編輯您的文件",
          details:
            "PDF 不僅是用來看的。在您的文檔中，<br>使用高亮標註、添加圖文形狀、備註等。<br>文檔處理格外犀利。",
        },
      },
      block2: {
        pc: {
          mainTitle: "創建完美文檔",
          details:
            "文件太大了？壓縮就好。需要特定格式嗎？隨手轉換。<br>文件亂七八糟難以管理？那就合並、切分、刪除冗余頁面。",
        },
        mb: {
          mainTitle: "創建完美文檔",
          details:
            "文件太大了？壓縮就好。<br>需要特定格式嗎？隨手轉換。<br>文件亂七八糟難以管理？<br>那就合並、切分、刪除冗余頁面。",
        },
      },
    },
    plans: {
      title: "計劃及價格",
      desc: `通過購買合適的計劃，不受限製地使用 ${process.env.VUE_APP_APP}`,
      buttonText: "比較價格",
      superiorProductPeriod: "月",
      superiorProductDesc: "按月付費，無使用限製",
    },
    chooseUs: {
      title: `為何選擇 ${process.env.VUE_APP_APP} ？`,
      showBlock: {
        block1: {
          pc: {
            tag: "安全標準",
            desc: `保證您的文檔安全，是我們的首要任務。<br>${process.env.VUE_APP_APP} 符合 ISO 27001，GDPR，CCPA等標準。`,
          },
          mb: {
            tag: "安全標準",
            desc: `保證您的文檔安全，<br>是我們的首要任務。<br>${process.env.VUE_APP_APP} 符合 ISO 27001，GDPR，CCPA等標準。`,
          },
        },
        block2: {
          pc: {
            tag: "AI 人工智能轉換",
            desc: "我們采用先進的人工智能技術，在轉換時<br>盡可能地保留文檔的原始風格。",
          },
          mb: {
            tag: "AI 人工智能轉換",
            desc: "我們采用先進的人工智能技術，<br>在轉換時盡可能地保留文檔的原始風格。",
          },
        },
        block3: {
          pc: {
            tag: "24/7 全天候客服支持",
            desc: "晝夜不間斷客服支持，隨時為您服務。",
          },
          mb: {
            tag: "24/7 全天候客服支持",
            desc: "晝夜不間斷客服支持，<br>隨時為您服務。",
          },
        },
      },
    },
  },
  footer: {
    copyright: "All rights reserved.",
    company: `${process.env.VUE_APP_COMPANY}, `,
  },
  workspacePage: {
    name: "工作區",
    recents: "最近訪問",
    welcome: "歡迎使用 ",
    free: {
      desc: "免費受限製使用",
      to: "升級至 PRO會員",
    },
    paidAndExpired: {
      desc: "PRO會員到期時間",
      to: "更新 PRO會員",
      to2: "訂單 & 發票",
    },
    paid: {
      desc: "PRO 會員有效期至",
      to: "訂單 & 發票",
    },
    allTools: "全部工具",
    table: {
      name: "文件名",
      size: "大小",
      date: "日期",
      action: "操作",
    },
  },
  convertPage: {
    mergeAndSplitToolName: "合並 & 切分",
    convertFromPDFToolName: "從 PDF 轉換",
    convertToPDFToolName: "轉換成 PDF",
    to: "轉",
    worker: "轉換器",
    choose: "選擇",
    file: "文件",
    dropFile: "或拖放文件至此處",
    uploading: "正在上傳",
    extracting: "正在抽取",
    convert: "轉換",
    converting: "正在轉換",
    unit: "頁",
    startOver: "重新開始",
    startOverTip: "遇到問題，立即聯繫",
    targetFileErrorDesc: {
      PDF: "該文件內容無法識別為 PDF",
      Word: "該文件內容無法識別為 Word",
      Excel: "該文件內容無法識別為 Excel",
      JPG: "該文件內容無法識別為圖像",
      PPT: "該文件內容無法識別為 PPT",
    },
    convertErrTitle: "出錯啦！",
  },
  verifyEmailPage: {
    title: "驗證您的 Email",
    signUpTip: "還沒有帳戶？",
    signUpText: "註冊",
  },
  verifyCodePage: {
    title: "驗證碼已發送至",
    resentText: "重新發送",
  },
  signUpPage: {
    name: "註冊",
    title: "創建帳戶",
    buttonText: "註冊",
    loginTip: "已經有帳戶？",
    loginText: "登錄",
    termsTip:
      "創建帳戶即表示您同意<br>我們的&nbsp;%terms%&nbsp;和&nbsp;%policy%&nbsp;",
  },
  loginPage: {
    name: "登錄",
    title: "登錄至 ",
    forgotTip: "忘記密碼？",
    signUpTip: "還沒有帳戶？",
    signUpText: "註冊",
  },
  profilePage: {
    name: "個人信息",
    security: "安全",
    plan: "付費計劃",
    firstName: "名",
    lastName: "姓",
    save: "保存",
    saveSuccessDesc: "保存成功",
    saveErrorDesc: "保存失敗",
    changePassword: "修改",
    orderAndInvoices: "訂單 & 發票",
  },
  changePasswordPage: {
    title: "修改密碼",
    currentPasswordPlaceholder: "舊密碼",
    newPasswordPlaceholder: "新密碼",
    confirmPasswordPlaceholder: "確認密碼",
    buttonText: "修改",
    authPasswordErr: "舊密碼不正確",
  },
  resetPasswordPage: {
    title: "重置密碼",
    buttonText: "重置",
    newPasswordPlaceholder: "新密碼",
    confirmPasswordPlaceholder: "確認密碼",
    inconsistentPassword: "密碼不一致",
  },
  orderPage: {
    title: "訂單 & 發票",

    table: {
      date: "日期",
      plan: "計劃",
      paid: "支付",
      invoice: "發票",
    },
  },
  email: {
    name: "Email",
  },
  password: {
    name: "密碼",
  },
  tools: {
    name: "工具",
    loginTitle: "工具",
    notLoginTitle: "所有工具",
    toolDescText: {
      ptw: "把PDF文檔轉換為可編輯的Word文檔",
      pte: "把PDF文檔轉換為可編輯的Excel文檔",
      ptpp: "把PDF文檔轉換為可編輯的PowerPoint幻燈片",
      ptj: "把PDF文檔按頁轉換為JPG文檔",
      wtp: "把Word文檔轉換為PDF文檔",
      etp: "把Excel文檔轉換為PDF文檔",
      pptp: "把PowerPoint幻燈片轉換為PDF文檔",
      jtp: "把JPG、PNG、BMP、GIF、TIFF圖像轉換為PDF文檔",
      merge: "把多個PDF文檔合並為一個PDF文檔",
      split: "從PDF文檔提取特定頁面，或將每一頁分別儲存為不同的PDF文檔",
    },
    feature: {
      ptw: {
        efficient: {
          title: "如何把PDF轉換成Word",
          desc: "將PDF拉至拖放區或點擊按鈕，以將文件上傳至我們的平臺。文件轉換完成後，便可供您下載。",
        },
        privacy: {
          title: "我們確保您文件的安全！",
          desc: "我們尊重隱私權。在文件轉換一小時後，它們將永遠從我們的服務器刪除。如欲獲取更多相關信息，請閱讀我們的隱私政策。",
        },
        multiPlatform: {
          title: "適用於所有操作系統！",
          desc: "我們的PDF轉Word轉換器適用於所有計算機，包括Mac、Windows及Linux。",
        },
        safe: {
          title: "提供最高質量的PDF轉Word服務",
          desc: "將PDF轉換為Word文檔的過程並不簡單，因此我們同市面上最佳轉換工具提供商 E-ICEBLUE 聯手，為您提供最優質的轉換服務。",
        },
        reliable: {
          title: "數秒內將PDF轉成DOC",
          desc: "文件轉換服務過程非常輕松，保證您一試便愛上。",
        },
        convenient: {
          title: "雲端轉換服務",
          desc: "我們擁有多個將PDF轉換成Word文件的雲端服務器，因此您可無需操心，放心讓我們處理一切。",
        },
      },
      wtp: {
        efficient: {
          title: "快速和高效的處理",
          desc: "Word到PDF的轉換是小菜一碟。請上傳一個Word文件來開始，然後坐下來放松，我們在短時間內就會準備好你的PDF。DOC和DOCX文件都可以處理。",
        },
        privacy: {
          title: "安全的在線PDF軟件",
          desc: `不用擔心! 轉換後1小時，我們就將文件從服務器上永久刪除。如果你想在線存儲轉換後的文件，請創建一個免費的 ${process.env.VUE_APP_APP} 帳戶。`,
        },
        multiPlatform: {
          title: "任何設備，任何操作系統",
          desc: "作為一個基於瀏覽器的在線軟件，你可以隨時隨地從你的PC、手機或平板電腦方便地訪問我們的Doc到PDF轉換器。",
        },
        safe: {
          title: "其他受支持的文件類型",
          desc: "樂趣遠不止於此。有了這款多合一的PDF轉換器，你也可以將其他文件格式轉換成PDF，如JPG、PNG、Excel和PPT。",
        },
        reliable: {
          title: "高級Word到PDF轉換",
          desc: `如果你每天轉換大量的文件，請了解一下${process.env.VUE_APP_APP} PRO，使用這款軟件，你可以根據自己的需要將許多Word文件一次過處理成PDF，每個文件最大允許1GB。`,
        },
        convenient: {
          title: "雲端作業",
          desc: "我們希望促成一個無縫的數字工作環境。一旦你將一個DOC文件轉換為PDF —— 自由預覽、編輯和存儲 —— 所有這些都在雲端完成。",
        },
      },
      pte: {
        efficient: {
          title: "如何把PDF轉成Excel",
          desc: "將PDF拉至拖放區或點擊相關按鈕，以將它上傳至我們的平臺。然後我們會將它轉換可編輯的電子表格供您下載。",
        },
        privacy: {
          title: "最佳轉換質量！",
          desc: "PDF轉Excel過程非常難。為了提供最佳質量，我們與市場上最優秀的解決方案提供商 E-ICEBLUE 建立了合作。",
        },
        multiPlatform: {
          title: "Windows, Mac及Linux！",
          desc: "無論您使用的是甚麽操作系統，我們的PDF轉Excel轉換器都可於您的計算機運作。",
        },
        safe: {
          title: "我們保障您文件的隱私！",
          desc: "您的隱私權對我們很重要。在文件轉換一小時後，所有文件將永遠從我們的服務器刪除。要了解更多信息，請閱讀我們的隱私政策。",
        },
        reliable: {
          title: "轉換所有電子表格！",
          desc: "並非所有文件皆可被轉換成Excel。請確保您的PDF文件內含電子表格，我們便能輕松地將之轉換成Excel。",
        },
        convenient: {
          title: "雲端轉換",
          desc: "我們的雲端服務器不間斷地處理PDF文件。無需下載任何軟件，我們自會為您處理所有轉換程序。很棒對吧？",
        },
      },
      etp: {
        efficient: {
          title: "如何把Excel文件轉成PDF",
          desc: "將Excel文件拖放至方框內，或點擊「選擇文件」按鈕以瀏覽並上傳文件。文件將立即被轉換成PDF並可下載。",
        },
        privacy: {
          title: "瞬間將Excel轉成PDF！",
          desc: "您的Excel文件會按照自動設置快速轉換。生成的PDF將會與您想要的樣子一模一樣，無需進行任何復雜的選擇！",
        },
        multiPlatform: {
          title: "支持所有操作系統",
          desc: "我們可在所有操作系統，包括Windows、Mac及Linux，將Excel轉成PDF。",
        },
        safe: {
          title: "安全轉換",
          desc: "在文件轉換一小時後，我們的服務器將永遠刪除您所有的Excel與PDF文件。請查看下方的隱私政策，以獲取詳情。",
        },
        reliable: {
          title: "單擊創建PDF",
          desc: "僅需上傳文件，然後讓我們的服務器處理您的xls或xlxs文件。還有比這更簡單的方法嗎？",
        },
        convenient: {
          title: "雲端轉換",
          desc: "在雲端將Excel轉成PDF，而無需使用到計算機的容量。很酷對不？",
        },
      },
      ptpp: {
        efficient: {
          title: "如何將PDF轉換成PPT",
          desc: "首先您要拖放或直接點擊選擇文件按鈕以上傳PDF。文件會在線上轉換，完成後您就能下載文件。",
        },
        privacy: {
          title: "輕松、快速的轉換過程！",
          desc: `PDF轉PPT的過程極為復雜。為了給您提供最佳解決方案，${process.env.VUE_APP_APP} 已與市面上最佳轉換工具提供商 E-ICEBLUE 合作。`,
        },
        multiPlatform: {
          title: "適用於所有操作系統",
          desc: "PDF轉換PPT服務適用於所有電腦 – 您的操作系統不會影響轉換。",
        },
        safe: {
          title: "安全性是我們的首要考量！",
          desc: "您關心隱私問題，我們亦關心隱私問題。因此所有文件會在轉換後一小時內從我們的服務器裏刪除。如希望知道更多，請參閱我們的私隱政策。",
        },
        reliable: {
          title: "最佳質量的PDF - Powerpoint轉換",
          desc: `PDF轉PPT的過程非常復雜。為了獲得優質文件質量，${process.env.VUE_APP_APP} 與 E-ICEBLUE 達成了合作夥伴關系。E-ICEBLUE 是市面上可提供最佳文件轉換質量的解決方案提供商。`,
        },
        convenient: {
          title: "雲端服務",
          desc: "我們的雲端伺服器會處理您的文件，您無須下載任何軟件至您的計算機。很棒哩？",
        },
      },
      pptp: {
        efficient: {
          title: "如何轉換PPT文件成PDF",
          desc: "拖放文件至方框或選擇「選擇文件」選項以上傳文件。我們的服務器會將PPT轉換成PDF，您就可以下載文件。",
        },
        privacy: {
          title: "轉換文件就這樣簡單",
          desc: "無須額外工作，就可將您的PPT文件轉成PDF。上傳文件，坐下來，放松，我們就會為您辦妥余下的工作！",
        },
        multiPlatform: {
          title: "支持所有操作系統",
          desc: "我們的PPT轉PDF轉換器適用於所有系統平臺，無論您使用那一種系統平臺 - Windows、Mac或Linux。",
        },
        safe: {
          title: "保障私隱",
          desc: "我們十分重視安全。將PPT轉換成PDF後，我們的服務器將永久刪除您的文件。若希望得到更多信息，請參閱隱私政策。",
        },
        reliable: {
          title: "支持PPT與PPTX",
          desc: "舊ppt和新pptx格式我們都能轉換。您只須上傳文件，我們就會轉換。",
        },
        convenient: {
          title: "基於雲端的服務器",
          desc: "我們在雲端將您的PPT轉換成PDF。這意味著您可以隨時隨地訪問，您的計算機就不用處理這些麻煩的工作。",
        },
      },
      ptj: {
        efficient: {
          title: "如何將PDF轉換成JPG",
          desc: "僅需將PDF拖放到上面的方框中，即可將PDF轉換成JPG。之後您可以選擇有意保存的圖像，將之儲存在計算機。",
        },
        privacy: {
          title: "轉換PDF中的全部圖片",
          desc: "轉換後，圖片將以可下載的單獨圖片文件形式呈現在您面前。您也可以將全部圖片作為一個ZIP文件下載下來。",
        },
        multiPlatform: {
          title: "支持所有操作系統",
          desc: "PDF轉JPG應用程序在瀏覽器內運作，這意味著只要您使用最新的瀏覽器，便可在任何操作系統，如Windows、Mac及Linux享用我們的服務！",
        },
        safe: {
          title: "安全在線轉換服務",
          desc: "您的PDF及JPG文件在轉換完成一個小時後，就會從我們的服務器刪除。無人能查閱或下載這些文件，以保障您的隱私。",
        },
        reliable: {
          title: "JPG格式是質量的保證",
          desc: "JPG是網絡上最常見的圖片格式。我們的轉換服務輸出的始終是JPG文件，即便PDF中的圖片是以其他格式保存的。",
        },
        convenient: {
          title: "在雲端轉換",
          desc: "從PDF提取圖片的操作是在雲端進行的，因此它不會占用電腦的任何空間，而且您可以在任何地方訪問PDF轉JPG工具。",
        },
      },
      jtp: {
        efficient: {
          title: "如何將JPG轉換成PDF",
          desc: "將JPG文件拖放到方框中。您可以立即對文件進行排序，或者使用您偏愛的設置。點擊「馬上創建PDF」按鈕，並下載PDF文件。",
        },
        privacy: {
          title: "輕松、快速的轉換過程",
          desc: "即使沒有任何文檔轉換經驗的人，也能將圖片輕松快速轉換為PDF。只需輕輕點擊一下，即可下載您的PDF！",
        },
        multiPlatform: {
          title: "支持所有操作系統",
          desc: "JPG轉PDF轉換器基於瀏覽器運作，無需依賴OS操作系統，因此適合用於所有操作系統，包括Mac、Windows以及Linux。",
        },
        safe: {
          title: "安全地在線處理文件",
          desc: "您的隱私會受到保護。沒有人能夠訪問您的JPG與PDF文件，這些文件將在一個小時後從我們的服務器上永久被刪除。",
        },
        reliable: {
          title: "支持大部分圖像格式",
          desc: "JPG是最常用的圖片格式，但我們同時支持多種圖檔格式，如GIF、BMP、PNG及TIFF。故您也可以使用我們的程序轉換這些圖檔。",
        },
        convenient: {
          title: "在雲端轉換",
          desc: "圖片轉換在雲端進行，因此並不耗費您的計算機容量。",
        },
      },
      merge: {
        efficient: {
          title: "如何合並PDF",
          desc: "將您的PDF或者多個PDF拖放到上方的方框內。當頁面出現時，您就可以開始編輯。然後按下方的按鈕下載您的PDF。",
        },
        privacy: {
          title: "帶預覽功能的簡易PDF合並",
          desc: "將頁面拖動到編輯區域，以重新排序或刪除部份頁面。同時，您亦可以增加多個PDF並將它們合並為一個文件。",
        },
        multiPlatform: {
          title: "適用於Mac、Windows以及Linux",
          desc: "網上PDF合並應用程式是經過瀏覽器操作的，所以適合於所有操作系統，包括Mac、Windows以及Linux上使用。",
        },
        safe: {
          title: "安全在線PDF合並",
          desc: "所有您上載以及由我們的服務器產生的文件會在一個小時內被永久刪除。有關詳情，請參閱我們的私隱政策。",
        },
        reliable: {
          title: "可靠的服務",
          desc: "通常您要為合並PDF或在PDF增加頁面而購買昂貴的軟件。這個線上的服務既安全又可靠。",
        },
        convenient: {
          title: "在雲端處理中",
          desc: "當您完成合並您的PDF後，我們的雲瑞服務器會為您處理該文件，所以過程不會耗費您計算機的資源。",
        },
      },
      split: {
        efficient: {
          title: "兩次點擊的切分流程",
          desc: "快速將PDF切分為互相獨立的單頁文件，或者提取指定頁面形成新的PDF文稿。您還可以同時在多個PDF中添加和提取頁面。",
        },
        privacy: {
          title: "具備預覽的PDF切分",
          desc: "我們的PDF切分器為您希望切分的頁面提供預覽。您可以在視訊介面選擇頁面，或直接輸入您希望提取的頁碼作選擇。",
        },
        multiPlatform: {
          title: "在所有平臺切分PDF",
          desc: "您可以在所有操作系統上用最新的網頁瀏覽器使用我們的線上應用來切分PDF。不論是Windows、Mac或Linux都能使用。",
        },
        safe: {
          title: "在線安全的PDF切分",
          desc: "我們的私隱政策會向您解釋安全性以及您的私隱對我們而言有多重要。我們會在您上傳文件後一小時內從我們的服務器裏永久刪除所有文件。",
        },
        reliable: {
          title: "實惠的在線應用程式",
          desc: "一般而言，若要切分一個PDF文件，您要花很多錢購買高級的軟件才能做到。我們則是以經濟實惠的按月價格提供此服務。",
        },
        convenient: {
          title: "雲端處理PDF",
          desc: "所有切分、提取與刪除工作都在雲端進行，因此不會耗費您計算機的資源。",
        },
      },
    },
  },
  plan: {
    free: {
      tag: "免費",
      desc: "受限製的文件處理",
    },
    paidAndExpired: {
      tag: "PRO會員到期時間 ",
      desc: "受限製的文件處理",
    },
    paid: {
      tag: "PRO 會員有效期至 ",
      desc: "不受限製的文件處理",
    },
  },
  siteMap: {
    company: "公司",
    about: "關於",
    contactUs: "聯系我們",
    inside: "在線幫助",
    product: "產品",
    pricing: "價格",
    help: "幫助",
  },
  contact: {
    name: "聯系我們",
    address: "#41-01 Hong Leong Building,<br/>16 Raffles Quay, Singapore 048581"
  },
  limitRule: {
    notLogin: {
      convert: {
        tag: "還有文檔需要處理？",
        desc: "註冊以獲得更多免費處理次數",
        action: "註冊",
      },
      upload: {
        tag: "出錯啦！文件太大了。",
        desc: "文件不能大於5M，註冊用戶沒有該限製",
        action: "註冊",
      },
    },
    login: {
      upload: {
        tag: "出錯啦！文件太大了。",
        desc: "文件不能大於15M，升級至 PRO會員 可以無限製處理文檔。",
        action: "升級至 PRO會員",
      },
      convert: {
        tag: "還有文檔需要處理？",
        desc: "升級至 PRO會員 可以無限製處理文檔。",
        action: "升級至 PRO會員",
      },
    },
    paidAndExpired: {
      upload: {
        tag: "出錯啦！文件太大了。",
        desc: "文件不能大於15M，更新 PRO會員 可以無限製處理文檔。",
        action: "更新 PRO會員",
      },
      convert: {
        tag: "還有文檔需要處理？",
        desc: "更新 PRO會員 可以無限製處理文檔。",
        action: "更新 PRO會員",
      },
    },
  },
  about: {
    name: `我們是 ${process.env.VUE_APP_APP} 團隊`,
    content: `2019年創立於新加坡，從單一工具發展到 PDF 工具集，${process.env.VUE_APP_APP} 通過20多種PDF工具為世界上各式各樣的 PDF 使用需求，提供簡單、安全和可靠的解決方案。`,
  },
  splitPage: {
    name: "切分",
    // 文件屬性中的文件個數
    files: "文件",
    splitFile: "將1個PDF切分為多個",
    splitFileDesc:
      "例如，將一本書切分為若幹章節或獨立頁面。這樣就能獲得多個PDF。",
    page: "個頁面",
    pages: "個頁面",
    splitPage: "提取頁面",
    splitPageDesc: "例如，從一本書中提取一個章節或頁面。這樣就能獲得一個PDF。",
    navLeftText: "提取頁面",
    navRightText: "切分",
    addMore: "添加文件",
    workLoadingText: "正在提取...",
    workButtonText: "提取",
    workButtonMobileText: "提取為 1個 PDF",
    workLoadingSplitText: "正在切分...",
    workButtonSplitText: "切分",
    workButtonSplitMobileText: "切分成 %totalNumber%個 PDF",
    selectAll: "選擇所有",
    SplitAllPage: "切分每一頁",
    tip: "按&nbsp;%img%%shift%&nbsp;鍵一次選擇多個頁面。",
    tipKey: "Shift",
  },
  mergePage: {
    name: "合並",
    // 文件屬性中的文件個數
    files: "文件",
    mergeFile: "合並文件",
    mergeFileDesc: "隨心所欲排布拼接整個PDF文件。",
    mergePage: "合並頁面",
    mergePageDesc: "選擇，排布，再拼接PDF文件裏的每個單獨的頁面。",
    addMore: "添加文件",
    navLeftText: "合並文件",
    navRightText: "合並頁面",
    addErrorText: "無法添加。該文件可能已加密、損壞或文件類型不受支持。",
    workLoadingText: "正在合並...",
    workButtonText: "合並",
    tip: "長按即可開始編輯",
    exitEditButtonText: "完成",
    editHint: "拖拽以排序",
    confirmRemove: "確認刪除",
  },
};
export default zh_tc;
