<template>
  <div class="off">
    <span class="i-tag">{{
      $t(`limitRule.${accType}.${store.workLimitType}.tag`)
    }}</span>

    <span
      class="desc"
      v-html="$t(`limitRule.${accType}.${store.workLimitType}.desc`)"
    ></span>

    <button
      class="button flex"
      @click="goNext"
      :style="{
        background: store.tools[store.work].textColor,
        border: '2px solid' + store.tools[store.work].textColor,
      }"
    >
      {{ $t(`limitRule.${accType}.${store.workLimitType}.action`) }}
    </button>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import { useStore } from "vuex";
export default {
  name: "ConvertOff",
  components: {},
  props: {},
  setup() {
    const store = useStore().state;

    let headTopup = inject("headTopup");

    function goNext() {
      if (document.body.clientWidth <= 765 && !store.isLogin) {
        headTopup();
        return;
      }
      if (store.isLogin)
        window.location.href = `/index.html#/product?from=work&path=${store.currentFile.convertFileUrl}`;
      else
        window.location.href = `/index.html#/signUp?&work=${store.work}&from=work&path=${store.currentFile.convertFileUrl}`;
    }

    let accType = computed(() => {
      const expire = new Date(store.account.vip.expire);
      const level = Number(store.account.vip.level);
      const date = new Date();
      // if (store.isLogin && date <= expire && level > 0) return "paid";
      if (store.isLogin && date > expire && level > 0) return "paidAndExpired";
      if (!store.isLogin) return "notLogin";
      if (store.isLogin && level < 1) return "login";
      return "login";
    });

    return {
      accType,
      goNext,
      store,
    };
  },
  created() {},
};
</script>

<style scoped>
.off {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
}

.i-tag {
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 14px;
}

.desc {
  font-weight: 400;
  font-size: 14px;
}

.button {
  box-sizing: border-box;
  border-radius: 4px;
  width: 420px;
  height: 48px;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  margin-top: 28px;
  cursor: pointer;
}

@media all and (max-width: 640px) and (min-width: 320px) {
  .off {
    padding: 45px 24px 0 24px;
    text-align: center;
  }
  .off .i-tag {
    font-size: 18px;
  }
  .button {
    width: 254px;
    height: 44px;
  }
}
@media all and (max-width: 1020px) and (min-width: 640px) {
  .button {
    width: 60%;
    height: 46px;
    min-width: 320px;
  }
}
</style>
