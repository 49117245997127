<template>
  <div class="copyright">
    © {{ new Date().getFullYear() }} {{ $t("footer.company") }}
    {{ $t("footer.copyright") }}
  </div>
</template>

<script>
// import { toRefs, reactive, ref } from 'vue'

export default {
  name: "Copyright",
  components: {},
  // setup (props, context) {
  //
  // },
  // created () {
  // }
};
</script>

<style scoped>
.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}
</style>
