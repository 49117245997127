import { createI18n } from "vue-i18n";
import en from "./en";
import zh from "./zh";
import zh_tc from "./zh_tc";
import jp from "./jp";
import ko from "./ko";
import utils from "@/util/utils";

const i18n = createI18n({
  locale:
    window.localStorage.getItem(
      `${process.env.VUE_APP_STORAGE_PREX}locale-language`
    ) || utils.getSystemLanguage() || "en",
  messages: {
    en,
    zh,
    zh_tc,
    jp,
    ko,
  },
  fallbackLocale: "en",
});

const I18N_RULE = /%[^%]+%/g;
function i18nReplace(sourceStr, resultList = []) {
  if (resultList && Array.isArray(resultList) && resultList.length > 0) {
    const _matchList = sourceStr.matchAll(I18N_RULE);
    let res = sourceStr;
    const _ms = Array.from(_matchList);
    if (_ms && _ms.length > 0) {
      for (let i = 0; i < _ms.length; i++) {
        const m = _ms[i];
        const r = m[0].replaceAll("%", "");
        if (resultList[i] && Object.keys(resultList[i]).includes(r)) {
          res = res.replace(m[0], resultList[i][r] || "");
        } else {
          res = res.replace(m[0], " ");
        }
      }
      return res;
    } else {
      return sourceStr;
    }
  } else {
    return sourceStr;
  }
}

function i18nHTML(targetObj) {
  if (!targetObj.sourceStr) return "";
  let _sourceStr = targetObj.sourceStr;
  let _child = targetObj.child;
  let mas = [];
  if (!targetObj.tag) {
    const _matchList = _sourceStr.matchAll(I18N_RULE);
    const _ms = Array.from(_matchList);
    if (_ms && _ms.length > 0) {
      for (let i = 0; i < _ms.length; i++) {
        const m = _ms[i];
        mas.push(m[0]);
      }
    }
    let tags = {};
    if (_child && Array.isArray(_child)) {
      _child.forEach((c) => {
        let tag = document.createElement(c.tag);
        if (c.attrs && typeof c.attrs === "object") {
          Object.keys(c.attrs).forEach((att) => {
            tag.setAttribute(att, c.attrs[att]);
          });
        }
        tag.innerHTML = c.text;
        tags[c.target] = utils.nodeToHtml(tag);
      });
    }
    let result = _sourceStr;
    for (let i = 0; i < mas.length; i++) {
      result = result.replaceAll(mas[i], tags[mas[i]]);
    }
    return result;
  } else {
    if (!_child || !Array.isArray(_child)) return "";
    let parentDom = document.createElement(targetObj.tag);
    if (targetObj.attrs && typeof targetObj.attrs === "object") {
      Object.keys(targetObj.attrs).forEach((att) => {
        if (targetObj.attrs[att] && Array.isArray(targetObj.attrs[att])) {
          parentDom.setAttribute(att, targetObj.attrs[att].join(" "));
        } else {
          parentDom.setAttribute(att, targetObj.attrs[att]);
        }
      });
    }
    parentDom.innerHTML = targetObj.text || "";
    let tags = {};
    if (_child && Array.isArray(_child)) {
      _child.forEach((c) => {
        let tag = document.createElement(c.tag);
        if (c.attrs && typeof c.attrs === "object") {
          Object.keys(c.attrs).forEach((att) => {
            tag.setAttribute(att, c.attrs[att]);
          });
        }
        tag.innerHTML = c.text;
        tags[c.target] = utils.nodeToHtml(tag);
        parentDom.appendChild(tag);
      });
    }
    const _matchList = _sourceStr.matchAll(I18N_RULE);
    const _ms = Array.from(_matchList);
    if (_ms && _ms.length > 0) {
      for (let i = 0; i < _ms.length; i++) {
        const m = _ms[i];
        mas.push(m[0]);
      }
    }
    let result = _sourceStr;
    let repstr = "";
    for (let i = 0; i < mas.length; i++) {
      result = result.replaceAll(mas[i], tags[mas[i]]);
      repstr += tags[mas[i]];
    }
    return result.replaceAll(repstr, utils.nodeToHtml(parentDom));
  }
}

export { i18nReplace, i18nHTML, i18n };
