<template>
  <transition name="left" class="v-enter-active v-leave-active">
    <div class="signup">
      <div class="title">{{ $t("signUpPage.title") }}</div>
      <Brand class="brand"></Brand>
      <IEmail
        class="eml"
        ref="eml"
        @updateValue="updateValueEmail"
        @oninput="oninputEmail"
        :has-parent-hint="hasHintEmail"
        :hint="hintEmail"
      >
      </IEmail>
      <IPassword
        @updateValue="updateValuePassword"
        :has-parent-hint="hasHintPassword"
        :show-grid="true"
        @oninput="oninputPassword"
        @keypress.enter="buttonClick"
      ></IPassword>
      <Button
        class="button"
        @click="buttonClick"
        :status="going ? 'going' : 'before'"
      >
        {{ $t("signUpPage.buttonText") }}
      </Button>

      <div style="height: 14px"></div>
      <div class="tip terms-tip" v-html="termsTip"></div>
      <div style="height: 15px"></div>
      <hr />
      <div style="height: 14px"></div>
      <div class="tip">
        {{ $t("signUpPage.loginTip") }}
        <a href="javascript:void(0);" @click="toPage">{{
          $t("signUpPage.loginText")
        }}</a>
      </div>
    </div>
  </transition>
</template>
<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import IEmail from "../components/IEmail";
import IPassword from "../components/IPassword";
import Brand from "../components/Brand";
import Button from "../components/Button";
import utils from "../util/utils";
import { useI18n } from "vue-i18n";
import { i18nHTML } from "@/language/i18n";
export default {
  name: "SignUp",
  components: {
    Brand,
    IEmail,
    IPassword,
    Button,
  },
  props: {
    isHead: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { t, locale } = useI18n();
    let router = useRouter();
    let route = useRoute();
    let stores = useStore();
    let store = stores.state;
    let hintEmail = ref("");
    let email = ref(store.currentEmail);
    let hasHintEmail = ref(false);
    let password = ref(store.currentPassword);
    let hasHintPassword = ref(false);
    let going = ref(false);
    function updateValueEmail(e) {
      email.value = e;
    }
    function updateValuePassword(e) {
      password.value = e;
    }
    function oninputEmail(e) {
      email.value = e;
      hasHintEmail.value = false;
    }
    function oninputPassword(e) {
      password.value = e;
      hasHintPassword.value = false;
    }
    function toPage() {
      if (props.isHead) context.emit("headToggle");
      else router.push({ name: "login" });
    }

    function goNext() {
      stores.commit("change", { key: "currentEmail", value: email.value });
      stores.commit("change", {
        key: "currentPassword",
        value: password.value,
      });
      stores.commit("resetTimeInterval");
      if (props.isHead) context.emit("toVerifyCode", "sign");
      else {
        router.push({
          name: "vPage",
          params: {
            email: email.value,
            password: password.value,
            source: "signUp",
          },
          query: {
            from: route.query.from,
            work: route.query.work || "",
            path: route.query.path || "",
          },
        });
      }
    }

    function buttonClick() {
      if (!email.value || !utils.verifyEmail(email.value)) {
        hasHintEmail.value = true;
        return;
      } else {
        hasHintEmail.value = false;
      }
      if (!password.value) {
        hasHintPassword.value = true;
        return;
      } else {
        hasHintPassword.value = false;
      }
      going.value = true;
      hintEmail.value = "";
      utils
        .getAccountExist(email.value)
        .then((res) => {
          if (!res.data) {
            utils
              .sendCode(email.value, false, "register", locale.value)
              .then(() => {
                goNext();
              })
              .catch((e) => {
                if (e.response.status === 429) {
                  goNext();
                } else {
                  hasHintEmail.value = true;
                }
              })
              .finally(() => {
                going.value = false;
              });
          } else {
            hasHintEmail.value = true;
            hintEmail.value =
              t("error.accountExist") || "Account is exist, please login!";
            going.value = false;
          }
        })
        .catch((e) => {
          console.log("unknown mistake", e);
          going.value = false;
        });
    }

    let termsTip = computed(() => {
      let souObj = {
        sourceStr: t("signUpPage.termsTip"),
        child: [
          {
            tag: "a",
            target: "%policy%",
            text: t("privacyPolicy.title"),
            attrs: {
              href: "#/privacyPolicy",
            },
          },
          {
            tag: "a",
            target: "%terms%",
            text: t("termsConditions.title"),
            attrs: {
              href: "#/terms",
            },
          },
        ],
      };
      return i18nHTML(souObj);
    });

    onMounted(() => {
      if (window.document.body.clientWidth < 765) {
        try {
          document
            .getElementsByClassName("eml")[0]
            .getElementsByTagName("input")[0]
            .focus();
        } catch (e) {
          console.log(e);
        }
      }
    });

    return {
      toPage,
      going,
      email,
      hintEmail,
      hasHintEmail,
      hasHintPassword,
      password,
      updateValueEmail,
      oninputEmail,
      oninputPassword,
      updateValuePassword,
      buttonClick,
      termsTip,
    };
  },
  // created () {
  // }
};
</script>

<style scoped>
.signup {
  margin-top: 48px;
  margin-left: auto;
  margin-right: auto;
  width: 360px;
  text-align: center;
  font-size: 14px;
}
.signup .title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 28px;
}
.brand {
  margin-bottom: 28px;
}
.eml {
  margin-bottom: 14px;
}
.button {
  margin-top: 24px;
}
a {
  color: #00a3ff;
  text-decoration: none;
}
.tip {
  color: #7b7b7b;
}

@media all and (max-width: 1020px) and (min-width: 320px) {
  .signup {
    margin: 0 auto;
    box-sizing: border-box;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .signup .title {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .tip,
  button {
    font-size: 16px;
  }
  .terms-tip {
    font-size: 14px;
  }
  .brand {
    margin-bottom: 0;
  }
  .eml {
    margin-bottom: 18px;
  }
  .button {
    margin-top: 28px;
  }
  hr {
    width: 100%;
  }
}
@media all and (max-width: 1020px) and (min-width:765px) {
  .signup {
    margin: 40px auto;
  }
  .brand {
    margin-bottom: 24px;
  }
}
</style>
