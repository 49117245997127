export let files = {
  word: {
    name: "Word",
    accept:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword",
    suffixName: "docx",
    formatIncluded: ["docx", "doc"],
    icon: require("@/assets/images/file/word/icon_word.png"),
    iconSmall: require("@/assets/images/file/word/icon_s_word.png"),
  },
  pdf: {
    name: "PDF",
    accept: "application/pdf",
    suffixName: "pdf",
    formatIncluded: ["pdf"],
    icon: require("@/assets/images/file/pdf/icon_pdf.png"),
    iconSmall: require("@/assets/images/file/pdf/icon_s_pdf.png"),
  },
  ppt: {
    name: "PPT",
    accept:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    suffixName: "pptx",
    formatIncluded: ["pptx"],
    icon: require("@/assets/images/file/ppt/icon_ppt.png"),
    iconSmall: require("@/assets/images/file/ppt/icon_s_ppt.png"),
  },
  jpg: {
    name: "JPG",
    accept: "image/jpg,image/png,image/gif,image/jpeg,image/bmp,image/tiff",
    suffixName: "jpg",
    formatIncluded: ["png", "jpg", "bmp", "gif", "tif", "jpeg", "tiff"],
    icon: require("@/assets/images/file/img/icon_jpg.png"),
    iconSmall: require("@/assets/images/file/img/icon_s_jpg.png"),
  },
  excel: {
    name: "Excel",
    accept:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    suffixName: "xlsx",
    formatIncluded: ["xls", "xlsx"],
    icon: require("@/assets/images/file/excel/icon_excel.png"),
    iconSmall: require("@/assets/images/file/excel/icon_s_excel.png"),
  },
  mergepdf: {
    name: "PDF",
    accept: "application/pdf",
    suffixName: "pdf",
    formatIncluded: ["pdf"],
    icon: require("@/assets/images/file/mergepdf/icon_mergepdf.png"),
    iconSmall: require("@/assets/images/file/pdf/icon_s_pdf.png"),
  },
  splitpdf: {
    name: "PDF",
    accept: "application/pdf",
    suffixName: "pdf",
    formatIncluded: ["pdf"],
    icon: require("@/assets/images/file/mergepdf/icon_mergepdf.png"),
    iconSmall: require("@/assets/images/file/pdf/icon_s_pdf.png"),
  },
};
