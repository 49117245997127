<template>
  <div style="flex: 1"></div>
  <div class="tool-sitemap-main">
    <ColumnToolBlock
      v-if="showTool && isLongClient"
      :show="showTool && isLongClient"
    ></ColumnToolBlock>
    <SiteMap></SiteMap>
  </div>
</template>

<script>
import ColumnToolBlock from "@/components/ColumnToolBlock";
import SiteMap from "@/components/SiteMap";
import { computed, onMounted, onUnmounted, ref } from "vue";

export default {
  name: "ColumnToolsAndSiteMap",
  components: { ColumnToolBlock, SiteMap },
  props: {
    showTool: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    let size = ref(document.body.offsetWidth);
    let isLongClient = computed(() => {
      return size.value > 640;
    });
    function setSize() {
      size.value = document.body.offsetWidth;
    }
    onMounted(() => {
      window.addEventListener("resize", setSize);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", setSize);
    });

    return { isLongClient };
  },
  created() {},
};
</script>

<style scoped>
.tool-sitemap-main {
  width: 100%;
  /*flex: 1 1 0%;*/
  display: grid;
  grid-template-columns: repeat(5, auto);
  /*grid-template-rows: minmax(0, auto) minmax(auto, 172px);*/
  grid-column-gap: 82px;
  overflow: hidden;
}
@media all and (max-width: 640px) and (min-width: 320px) {
  .tool-sitemap-main {
    grid-template-rows: auto auto;
    grid-template-columns: 5fr 6fr;
    grid-column-gap: 8px;
    margin: 0 24px;
    width: calc(100% - 48px);
  }
  .choose-us .block .flex img {
    margin-right: 26px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }
}
@media all and (max-width: 1020px) and (min-width: 640px) {
  .tool-sitemap-main {
    grid-column-gap: 5%;
  }
}
</style>
