<template>
  <div class="footer">
    <div :style="{ 'background-color': themeColor }" class="main">
      <Copyright class="copyright"></Copyright>
      <div class="vary">
        <div class="func-list flex">
          <a href="/index.html#/privacyPolicy">{{
            $t("privacyPolicy.title")
          }}</a>
          <a href="/index.html#/terms">{{ $t("termsConditions.title") }}</a>
          <a class="list" href="javascript:void(0)" @click.stop="toggle">
            <img src="@/assets/images/icon_language.png" alt="load error" />{{
              languages[locale]
            }}
            <div class="language" v-show="show">
              <div
                :class="{ current: locale === key }"
                v-for="(lo, key) in languages"
                :key="key + lo"
                @click.stop="changeLanguage(key)"
              >
                {{ lo }}<span>✓</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import Copyright from "./Copyright";
import { useI18n } from "vue-i18n";

export default {
  name: "Footer",
  components: { Copyright },
  props: {
    themeColor: String,
  },
  setup() {
    const { locale, availableLocales } = useI18n();
    function changeLanguage(language) {
      locale.value = language;
      window.localStorage.setItem(
        `${process.env.VUE_APP_STORAGE_PREX}locale-language`,
        language
      );
      hied();
    }
    let show = ref(false);
    function toggle() {
      show.value = !show.value;
    }
    function hied() {
      show.value = false;
    }

    onMounted(() => {
      document.body.addEventListener("click", () => {
        hied();
      });
    });

    const languages = reactive({});
    return {
      changeLanguage,
      availableLocales,
      locale,
      languages,
      toggle,
      hied,
      show,
    };
  },
  created() {
    this.languages = this.$languages;
  },
};
</script>

<style scoped>
.footer {
  border-top: 1px solid #e5e5e5;
  /*width: 100%;*/
}
.main {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  height: 48px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: 100%;
  max-width: 920px;
  color: #454545;
}
a {
  color: #454545;
}
ul li {
  list-style: none;
}
.func-list a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.func-list a:not(:last-child) {
  padding-right: 18px;
  position: relative;
  white-space: nowrap;
}
.func-list a:not(:last-child)::after {
  content: "";
  height: 12px;
  width: 18px;
  text-align: center;
  border-right: 1px solid #e5e5e5;
  position: absolute;
  top: 3px;
  right: 0;
}
.func-list a:not(:first-child) {
  padding-left: 18px;
}
.func-list img {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}

.list > div > div:hover {
  font-weight: 700 !important;
}
.list:hover > div > div {
  font-weight: 400 !important;
}
.list {
  height: 20px;
  line-height: 20px;
  min-width: 92px;
  box-sizing: border-box;
}
.list:active {
  color: #454545;
}
.list:hover {
  color: #454545;
}
.list .language {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  position: absolute;
  bottom: 48px;
  right: 0;
  color: #7d7d7d;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  border-radius: 2px 2px 0 0;
}
.language::before {
  content: "";
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
  background-color: #ffffff;
  border-radius: 2px;
  position: absolute;
  height: 10px;
  width: 10px;
  transform: rotate(225deg);
  bottom: -6.5px;
  right: 63px;
}
.language .current > span {
  visibility: inherit;
}

.language > div:first-child {
  border-radius: 2px 2px 0 0;
}
.language > div:not(:last-child) {
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e5e5;
}
.language > div > span {
  margin-left: 6px;
  visibility: hidden;
}
.language > div:hover {
  background-color: #3e3e3e;
  color: #ffffff !important;
}
.language > div {
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
}
.language > div:not(:first-child) {
  padding-top: 8px;
}
.language > div:last-child {
  z-index: 1;
}

@media all and (max-width: 670px) and (min-width: 320px) {
  .footer {
    border-top: none;
  }
  .main {
    box-sizing: border-box;
    padding-bottom: 24px;
    flex-direction: column;
    height: auto;
  }
  .copyright {
    border-top: 1px solid #d9d9d9;
    padding-top: 24px;
    margin: 0 24px 18px 24px;
    width: calc(100% - 48px);
    box-sizing: border-box;
  }
  .func-list a {
    justify-content: flex-start;
    position: relative;
  }
  .list .language {
    bottom: 40px;
    right: 0;
  }
}
@media all and (max-width: 1020px) and (min-width: 670px) {
  .main {
    padding: 0 24px;
    width: auto;
  }
}
</style>
