<template>
  <div class="parent" :style="{ 'background-color': themeColor}">
    <div class="head">
      <div class="flex">
        <a class="App" href="/index.html#/">{{ $t("app") }}</a>
        <div class="flex func-list">
          <div
            class="tool-pc flex"
           @click.stop.prevent="topupPCTool">
            <a
              @mouseenter.self="isToolHover = true"
              @mouseleave.self="isToolHover = false"
              href="javascript:void(0);"
              class="flex func-list-item">
              {{$t("tools.name")}}
              <DropArrow
                :dir="isPCToolTopup ? 'top' : 'down'"
                :color="isToolHover ? '#00A3FF': '#454545'"
              ></DropArrow>
            </a>
            <div class="mask" v-if="isPCToolTopup" @click.stop="hidePCToolTopup"></div>
          </div>
<!--          <a-->
<!--            class="vertical-dividing-line"-->
<!--            href="javascript:void(0);"-->
<!--            @click="toWorkspace"-->
<!--            v-if="store.isLogin"-->
<!--            :class="{ checked: route.path === '/workspace' }"-->
<!--            >{{ $t("workspacePage.name") || "Workspace" }}</a-->
<!--          >-->
<!--          <a-->
<!--            href="/index.html#/tool"-->
<!--            :class="{ checked: route.path === '/tool' }"-->
<!--            >{{ $t("tools.name") || "Tools" }}</a-->
<!--          >-->
          <!--        <a-->
          <!--          href="#/document"-->
          <!--          v-if="store.isLogin"-->
          <!--          :class="{ checked: route.path === '/document' }"-->
          <!--          >{{ $t("documents.name") || "Documents" }}</a-->
          <!--        >-->
        </div>
      </div>
      <div class="vary">
        <div class="option-box">
          <div class="flex" v-if="!store.isLogin">
            <a :href="loginUrl">{{ $t("loginPage.name") }}</a>
            <button class="sign-up" @click="toSignUp">
              {{ $t("signUpPage.name") }}
            </button>
          </div>
          <span v-else class="option-box-head" @click.stop="toggleTag">
            {{
              i18nReplace($t("head.say"), [
                {
                  userName:
                    store.account.first_name || store.account.last_name
                      ? `${store.account.first_name || ""} ${
                          store.account.last_name || ""
                        }`
                      : utils.noPassByEmail(store.account.email),
                },
              ])
            }}
          </span>
          <ul
            class="option-list"
            :style="{ display: displayList ? 'block' : 'none' }"
          >
            <li @click.stop="toProfile">
              <img src="@/assets/images/account/icon-person.png" alt="load error" />
              <span>{{ $t("account.name") }}</span>
            </li>
            <li class="spacer"></li>
            <li v-if="store.isLogin" @click.stop="signOut">
              <img src="@/assets/images/account/icon-signout.png" alt="load error" />
              <span>{{ $t("head.signOut") }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="icons">
        <img
          v-if="store.isLogin"
          @click="toWorkspace"
          src="@/assets/images/icon_home.svg"
          alt="load error"
        />
        <img @click="showTools" src="@/assets/images/icon_tools.svg" alt="load error" />
        <img @click="showAccount" src="@/assets/images/icon_menu.svg" alt="load error" />
      </div>
      <div v-if="isPCToolTopup" class="topup-cloumn-tool-block" @click.stop="">
        <ColumnToolBlock :sortNormal="true"></ColumnToolBlock>
      </div>
      <div class="popup mask" v-if="isPopup" @click.self="hidePopUp">
        <div class="popup-box tools" v-if="isToolsUp">
          <img
            class="icon-cancel"
            @click="hidePopUp"
            src="@/assets/images/icon_cancel.svg"
            alt="load error"
          />

          <div class="tool-item">
            <div class="title">
              {{ $t("convertPage.convertToPDFToolName") }}
            </div>
            <div class="tool-item-info">
              <div :key="'head' + key" v-for="(item, key) in toPDFTools">
                <ColumnToolBox
                  :style="{
                    hoverTextColor: item.hoverTextColor,
                    hoverBackground: item.hoverBackground,
                    activeTextColor: item.activeTextColor,
                    activeBackground: item.activeBackground,
                  }"
                  :button-text="`${store.files[item.from].name} ${$t(
                    'convertPage.to'
                  )} ${store.files[item.to].name}`"
                  :img-url="item.icon"
                  :work="key"
                ></ColumnToolBox>
              </div>
            </div>
          </div>

          <div class="tool-item">
            <div class="title">
              {{ $t("convertPage.convertFromPDFToolName") }}
            </div>
            <div class="tool-item-info">
              <div :key="'head' + key" v-for="(item, key) in fromPDFTools">
                <ColumnToolBox
                  :style="{
                    hoverTextColor: item.hoverTextColor,
                    hoverBackground: item.hoverBackground,
                    activeTextColor: item.activeTextColor,
                    activeBackground: item.activeBackground,
                  }"
                  :button-text="`${store.files[item.from].name} ${$t(
                    'convertPage.to'
                  )} ${store.files[item.to].name}`"
                  :img-url="item.icon"
                  :work="key"
                ></ColumnToolBox>
              </div>
            </div>
          </div>

          <div class="tool-item">
            <div class="title">
              {{ $t("convertPage.mergeAndSplitToolName") }}
            </div>
            <div class="tool-item-info">
              <div :key="'head' + key" v-for="(item, key) in otherTools">
                <ColumnToolBox
                  :style="{
                    hoverTextColor: item.hoverTextColor,
                    hoverBackground: item.hoverBackground,
                    activeTextColor: item.activeTextColor,
                    activeBackground: item.activeBackground,
                  }"
                  :button-text="`${
                    item.type === 'merge'
                      ? $t('mergePage.name')
                      : $t('splitPage.name')
                  } ${store.files[item.to].name}`"
                  :img-url="item.icon"
                  :work="key"
                ></ColumnToolBox>
              </div>
            </div>
          </div>
        </div>

        <div class="popup-box acc" v-if="isAccountInfoUp">
          <img
            class="icon-cancel"
            @click="hidePopUp"
            src="@/assets/images/icon_cancel.svg"
            alt="load error"
          />

          <div class="name">
            {{
              i18nReplace($t("head.say"), [
                {
                  userName:
                    store.account.first_name || store.account.last_name
                      ? `${store.account.first_name || ""} ${
                          store.account.last_name || ""
                        }`
                      : utils.noPassByEmail(store.account.email),
                },
              ])
            }}!
          </div>
          <span class="time" v-if="payType === 'free'"
            >{{ `${$t("app")} ${$t("workspacePage.free.desc")}` }}&nbsp;&nbsp;
            <a :href="`#/product?from=${route.name}`">{{
              $t("workspacePage.free.to")
            }}</a>
          </span>
          <span class="time" v-if="payType === 'paidAndExpired'"
            >{{
              `${$t("app")} ${$t("workspacePage.paidAndExpired.desc")} ${
                meExpire || ""
              }`
            }}&nbsp;&nbsp;
            <a :href="`#/product?from=${route.name}`">{{
              $t("workspacePage.paidAndExpired.to")
            }}</a>
          </span>
          <span class="time" v-if="payType === 'paid'">
            {{ `${$t("app")} ${$t("workspacePage.paid.desc")}` }}&nbsp;
            <span>{{ meExpire }}</span>
          </span>

          <div class="flex acc-list">
            <div class="flex" @click.stop="toProfile">
              <img src="@/assets/images/account/icon_black_person.svg" alt="load error" />
              <span>{{ $t("account.name") }}</span>
            </div>
            <div class="flex" v-if="store.isLogin" @click.stop="signOut">
              <img
                src="@/assets/images/account/icon_black_signout.svg"
                alt="load error"
              />
              <span>{{ $t("head.signOut") }}</span>
            </div>
          </div>
        </div>

        <div class="popup-box" v-if="isLoginUp">
          <img
            class="icon-cancel"
            @click="hidePopUp"
            src="@/assets/images/icon_cancel.svg"
            alt="load error"
          />
          <Login
            :isHead="true"
            @toVerifyEmail="showVerifyEmail"
            @headToggle="signAndLoginToggle"
          ></Login>
        </div>

        <div class="popup-box" v-if="isSignUpUp">
          <img
            class="icon-cancel"
            @click="hidePopUp"
            src="@/assets/images/icon_cancel.svg"
            alt="load error"
          />
          <SignUp
            :isHead="true"
            @toVerifyCode="toVerifyCode"
            @headToggle="signAndLoginToggle"
          ></SignUp>
        </div>

        <div class="popup-box flex buttons" v-show="isSignAndLoginButtonUp">
          <img
            class="icon-cancel"
            @click="hidePopUp"
            src="@/assets/images/icon_cancel.svg"
            alt="load error"
          />
          <Button class="button" @click="toSignUpUp">
            {{ $t("signUpPage.buttonText") }}
          </Button>
          <Button class="button white" @click="toLoginUp">
            <span>{{ $t("loginPage.name") }}</span>
          </Button>
        </div>

        <div class="popup-box flex" v-if="isVerifyEmailUp">
          <img
            class="icon-cancel"
            @click="hidePopUp"
            src="@/assets/images/icon_cancel.svg"
            alt="load error"
          />
          <VerifyEmail
            :isHead="true"
            @toVerifyCode="toVerifyCode"
            @toSignUpUp="toSignUpUp"
          ></VerifyEmail>
        </div>

        <div class="popup-box flex" v-if="isVerifyCodeUp">
          <img
            class="icon-cancel"
            @click="hidePopUp"
            src="@/assets/images/icon_cancel.svg"
            alt="load error"
          />
          <VerifyPage
            :isHead="true"
            :isSign="isSign"
            @toResetPasswd="toResetPasswd"
          ></VerifyPage>
        </div>

        <div class="popup-box flex" v-if="isSetPasswordUp">
          <img
            class="icon-cancel"
            @click="hidePopUp"
            src="@/assets/images/icon_cancel.svg"
            alt="load error"
          />
          <ResetPassword></ResetPassword>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch, inject } from "vue";
import { useStore } from "vuex";
import utils from "../util/utils";
import { useRoute, useRouter } from "vue-router";
import Account from "../store/account";
import { i18nReplace } from "@/language/i18n";
import { otherTools, toPDFTools, fromPDFTools } from "@/store/typeTools";
import ColumnToolBox from "@/components/ColumnToolBox";
import Button from "@/components/Button";
import ColumnToolBlock from "@/components/ColumnToolBlock";
import DropArrow from "@/components/icons/DropArrow";
import SignUp from "@/views/SignUp";
import Login from "@/views/Login";
import VerifyEmail from "@/views/VerifyEmail";
import VerifyPage from "@/views/VerifyPage";
import ResetPassword from "@/views/ResetPassword";

export default {
  name: "Head",
  props: {
    themeColor: String,
  },
  components: {
    ColumnToolBlock,
    Button,
    ColumnToolBox,
    Login,
    SignUp,
    VerifyEmail,
    VerifyPage,
    ResetPassword,
    DropArrow,
  },
  setup() {
    let route = useRoute();
    let router = useRouter();
    let useStores = useStore();
    let store = useStores.state;

    let displayList = ref(false);

    let isPCToolTopup = ref(false);
    let isToolHover = ref(false);

    function hidePCToolTopup() {
      isPCToolTopup.value = false;
      isToolHover.value = false;
    }

    function topupPCTool() {
      hiedList();
      isPCToolTopup.value = !isPCToolTopup.value;
    }


    async function signOut() {
      hidePopUp();
      hiedList();
      window.location.href = "/index.html#/";
      utils.clearCookie("sid");
      window.localStorage.removeItem(Account.store_key);
      useStores.commit("reset");
      await Account.toggle();
      await useStores.dispatch("account/init");
    }

    function toSignUp() {
      if (route.path.includes("/work/")) {
        window.location.href = `/index.html#/signUp?from=work&work=${store.work}`;
      } else {
        window.location.href = `/index.html#/signUp?from=${route.name}`;
      }
    }

    let loginUrl = computed(() => {
      let _url = "/index.html#/login";
      if (route.path.includes("/work/")) {
        _url += `?from=work&work=${store.work}`;
      } else {
        let _from = route.query.from ? route.query.from : route.name;
        _url += `?from=${_from}`;
      }
      return _url;
    });

    let belong = ref("");

    function changeBelog(hash) {
      belong.value = hash;
    }

    function toLogin() {
      hiedList();
      window.location.href = "/index.html#/login";
    }

    function toProfile() {
      hidePopUp();
      hiedList();
      window.location.href = "/index.html#/profile";
    }

    function toggleTag() {
      hidePCToolTopup();
      displayList.value = !displayList.value;
    }

    function hiedList() {
      displayList.value = false;
    }

    let isSign = ref(false);
    let isPopup = ref(false);
    let isSignAndLoginButtonUp = ref(false);
    let isSignUpUp = ref(false);
    let isLoginUp = ref(false);
    let isAccountInfoUp = ref(false);
    let isToolsUp = ref(false);
    let isVerifyCodeUp = ref(false);
    let isVerifyEmailUp = ref(false);
    let isSetPasswordUp = ref(false);

    function toWorkspace() {
      hidePopUp();
      hidePCToolTopup();
      window.location.href = "/index.html#/workspace";
    }

    watch(isPopup, () => {
      if (isPopup.value) {
        document.body.style.overflow = "hidden";
        document.body.style.position = "sticky";
      } else {
        document.body.style.overflow = "auto";
        document.body.style.position = "static";
      }
    });

    function showAccount() {
      hidePopUp();
      isPopup.value = true;

      if (store.isLogin) isAccountInfoUp.value = true;
      else isSignAndLoginButtonUp.value = true;
    }

    function toVerifyCode(type) {
      isSign.value = type === "sign";
      showVerifyCode();
    }

    function toSignUpUp() {
      hidePopUp();
      isPopup.value = true;
      isSignUpUp.value = true;
    }

    function toLoginUp() {
      hidePopUp();
      isPopup.value = true;
      isLoginUp.value = true;
    }

    function toResetPasswd() {
      hidePopUp();
      isPopup.value = true;
      isSetPasswordUp.value = true;
    }

    function showTools() {
      hidePopUp();
      isPopup.value = true;
      isToolsUp.value = true;
    }

    function showVerifyEmail() {
      hidePopUp();
      isPopup.value = true;
      isVerifyEmailUp.value = true;
    }

    function showVerifyCode() {
      hidePopUp();
      isPopup.value = true;
      isVerifyCodeUp.value = true;
    }

    function hidePopUp() {
      isPopup.value = false;
      isToolsUp.value = false;
      isAccountInfoUp.value = false;
      isSignAndLoginButtonUp.value = false;
      isSignUpUp.value = false;
      isLoginUp.value = false;
      isVerifyCodeUp.value = false;
      isVerifyEmailUp.value = false;
      isSetPasswordUp.value = false;
    }

    const meExpire = inject("meExpire");
    const timeFormat = inject("timeFormat");
    const payType = inject("payType");

    function signAndLoginToggle() {
      isSignUpUp.value = !isSignUpUp.value;
      isLoginUp.value = !isLoginUp.value;
    }

    onMounted(() => {
      document.body.addEventListener("click", () => {
        hiedList();
        hidePCToolTopup();
      });
    });

    return {
      hidePCToolTopup,
      isToolHover,
      topupPCTool,
      isPCToolTopup,
      meExpire,
      timeFormat,
      payType,
      loginUrl,
      i18nReplace,
      store,
      router,
      route,
      utils,
      signOut,
      toLogin,
      toProfile,
      displayList,
      toggleTag,
      belong,
      changeBelog,
      toSignUp,
      isSign,
      isPopup,
      isToolsUp,
      isSignUpUp,
      isLoginUp,
      isAccountInfoUp,
      isSignAndLoginButtonUp,
      isVerifyCodeUp,
      isVerifyEmailUp,
      isSetPasswordUp,
      signAndLoginToggle,
      showTools,
      showAccount,
      hidePopUp,
      toWorkspace,
      toSignUpUp,
      toLoginUp,
      toVerifyCode,
      toResetPasswd,
      showVerifyEmail,
      otherTools,
      toPDFTools,
      fromPDFTools,
    };
  },
  // created () {
  // }
};
</script>

<style scoped>
button {
  height: 26px;
}

a {
  text-decoration: none;
}

.App:hover {
  color: #454545;
}

.App {
  font-weight: 900;
}

.parent {
  z-index: 5;
  position: sticky;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
}

.head {
  position: relative;
  z-index: 5;
  box-sizing: border-box;
  width: 100%;
  max-width: 920px;
  height: 48px;
  font-size: 22px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  background: linear-gradient(0deg, #ffffff, #ffffff);
}

.head span {
  cursor: pointer;
}

.func-list {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  margin-left: 50px;
  justify-content: flex-start;
}
.func-list .tool-pc {
  height: 100%;
  align-items: center;
  padding-right: 16px;
  position: relative;
}
.func-list .tool-pc .func-list-item{
  align-items: center;
  margin-left: 6px;
  height: 100%;
  padding: 14px 0;
  /*border-bottom: 2px solid transparent;*/
}

.func-list .func-list-item:active {
  color: #0063C7 !important;
}
.func-list .tool-pc svg{
  cursor: pointer;
  margin-left: 2px;
}

.func-list .tool-pc .mask {
  top: 48px;
}


.func-list .checked:hover {
  font-weight: 700 !important;
}

.func-list .checked {
  font-weight: 700;
  box-sizing: border-box;
  color: #454545;
  /*border-bottom: 2px solid #454545;*/
}

.func-list a {
  padding: 14px 0;
  /*border-bottom: 2px solid transparent;*/
}

.head .vary .option-box-head {
  position: relative;
  max-width: 170px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.head .vary .option-box-head:hover {
  color: #00a3ff;
}

.head .vary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}

.option-box ul li:hover img {
  filter: brightness(100) !important;
}

.option-box ul li:hover {
  background-color: #3e3e3e;
  color: #ffffff;
}

.option-box ul li:hover img {
  filter: brightness(0);
}

.option-box ul li:last-child {
  border-radius: 0 0 2px 2px;
}

.option-box ul li:not(:last-child, :first-child) {
  border-radius: 0;
}

.option-box ul li img {
  width: 24px;
  height: 24px;
  margin: 0 6px 0 14px;
}

.option-box ul li {
  color: #898989;
  padding: 6px 0px;
  text-align: center;
  list-style: none;
  cursor: pointer;
  font-size: 14px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.option-box ul .spacer {
  width: 90px;
  padding: 0;
  margin: 0 13px;
  border-bottom: 1px solid #e7e7e7;
}

.option-list {
  z-index: 1;
  border-radius: 0 0 2px 2px;
  width: 116px;
  background-color: #fafafa;
  text-align: center;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 48px;
  right: 0px;
}

.option-list:hover ::after {
  background-color: #e2f5ff;
}

.option-list::before {
  content: "";
  border-top: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
  background-color: #fafafa;
  border-radius: 2px;
  top: -6.5px;
  position: absolute;
  right: 20px;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
  z-index: -1;
}
.icons {
  display: none;
  align-items: center;
}
.icons img {
  cursor: pointer;
}
.icons img:not(:first-child) {
  margin-left: 28px;
}

.sign-up {
  margin-left: 28px;
  border-radius: 2px;
}

.popup .popup-box {
  position: absolute;
  top: 68px;
  background: #ffffff;
  border-radius: 8px;
  left: 20px;
  right: 20px;
  padding: 28px 18px 28px 18px;
  text-align: start;
}
.popup .popup-box .icon-cancel {
  position: absolute;
  top: 12px;
  right: 18px;
  cursor: pointer;
}
.popup .popup-box .title {
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 700;
}
.popup .popup-box .title {
  margin-bottom: 8px;
}
.popup .popup-box .title img {
  margin-right: 10px;
}
.popup .popup-box .tool-item:not(:last-child) {
  padding-bottom: 18px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 24px;
}

.tool-item-info {
  display: grid;
  grid-template-columns: 4fr 4fr;
  grid-template-rows: auto;
  grid-column-gap: 11px;
}
.tool-item-info button {
  height: 44px;
  padding: 10px 0 10px 10px;
}

.popup .acc .name,
.popup .acc .time {
  max-width: 290px;
}
.popup .acc .name {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 18px;
}
.popup .acc {
  font-size: 16px;
  padding-bottom: 24px;
}
.popup .acc .acc-list {
  margin-top: 24px;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;
}
.popup .acc .acc-list .flex:not(:last-child) {
  padding-bottom: 24px;
}
.popup .acc .acc-list .flex {
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid #e9e9e9;
}
.popup .acc .acc-list .flex img {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
:deep(.terms-tip a) {
  text-decoration: none;
  color: #00a3ff;
}
.buttons {
  flex-direction: column;
}
.buttons .button {
  width: 100%;
  height: 44px;
  font-size: 16px;
}
.buttons button:not(:first-child) {
  margin-top: 14px;
}
.buttons .white {
  color: #00a3ff;
  background-color: #ffffff;
  border: 1px solid #00a3ff;
}


.topup-cloumn-tool-block {
  display: grid;
  grid-auto-columns: auto auto auto;
  position: absolute;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 28px 48px;
  top: 58px;
  column-gap: 70px;
  z-index: 10;
}

:deep(.topup-cloumn-tool-block .line){
  display: none !important;
}
:deep(.topup-cloumn-tool-block .tool){
  margin: 0;
}

@media all and (max-width: 570px) and (min-width: 320px) {
  .head {
    padding: 0 20px;
  }
  .App {
    font-size: 24px;
    font-weight: 700;
  }
  .head .func-list,
  .head .vary {
    display: none;
  }
  .icons {
    display: flex;
  }

  .popup .acc a {
    text-decoration: underline;
    color: #00a3ff;
  }
}
@media all and (min-width: 570px) {
  .popup .popup-box {
    left: 10%;
    right: 10%;
  }
}
@media all and (max-width: 1020px) and (min-width: 570px) {
  .head {
    padding: 0 24px;
  }
}
</style>
