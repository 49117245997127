<template>
  <div class="feature-box">
    <img :src="icon" alt="load error" />
    <span class="title">{{ title }}</span>
    <div>{{ desc }}</div>
  </div>
</template>

<script>
// import { toRefs, reactive, ref } from 'vue'

export default {
  name: "FeatureBox",
  components: {},
  props: {
    icon: {
      default: "",
      type: String,
    },
    title: {
      default: "",
      type: String,
    },
    desc: {
      default: "",
      type: String,
    },
  },
  setup() {},
  created() {},
};
</script>

<style scoped>
.feature-box {
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  width: 224px;
  justify-self: center;
}
.title {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 14px;
}
.feature-box img {
  margin-bottom: 18px;
  height: 48px;
  width: 48px;
}

@media all and (max-width: 640px) and (min-width: 320px) {
  .feature-box {
    width: 100%;
  }
  .title {
    font-size: 18px;
  }
  .desc {
    font-size: 14px;
  }
}
</style>
