<template>
  <svg v-if="dir === 'down'" :width="iwidth" :height="iheight" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 8H4L8 12L12 8Z" :fill="color"/>
  </svg>

  <svg v-if="dir === 'top'" :width="iwidth" :height="iheight" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 12H4L8 8L12 12Z" :fill="color"/>
  </svg>





</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "DropArrow",
  props: {
    dir: {
      type: String,
      default: "left"
    },
    iwidth: {
      type: String,
      default: "16"
    },
    iheight: {
      type: String,
      default: "16"
    },
    color: {
      type: String,
      default: "#454545"
    }
  },
});
</script>
