<template>
  <div class="feature">
    <FeatureBox
      :icon="require('@/assets/images/feature/efficient.png')"
      :title="$t(`tools.feature.${store.work}.efficient.title`)"
      :desc="$t(`tools.feature.${store.work}.efficient.desc`)"
    />
    <FeatureBox
      :icon="require('@/assets/images/feature/privacy.png')"
      :title="$t(`tools.feature.${store.work}.privacy.title`)"
      :desc="$t(`tools.feature.${store.work}.privacy.desc`)"
    />
    <FeatureBox
      :icon="require('@/assets/images/feature/multiPlatform.png')"
      :title="$t(`tools.feature.${store.work}.multiPlatform.title`)"
      :desc="$t(`tools.feature.${store.work}.multiPlatform.desc`)"
    />
    <FeatureBox
      :icon="require('@/assets/images/feature/safe.png')"
      :title="$t(`tools.feature.${store.work}.safe.title`)"
      :desc="$t(`tools.feature.${store.work}.safe.desc`)"
    />
    <FeatureBox
      :icon="require('@/assets/images/feature/reliable.png')"
      :title="$t(`tools.feature.${store.work}.reliable.title`)"
      :desc="$t(`tools.feature.${store.work}.reliable.desc`)"
    />
    <FeatureBox
      :icon="require('@/assets/images/feature/convenient.png')"
      :title="$t(`tools.feature.${store.work}.convenient.title`)"
      :desc="$t(`tools.feature.${store.work}.convenient.desc`)"
    />
  </div>
</template>

<script>
import FeatureBox from "./FeatureBox";
import { useStore } from "vuex";
export default {
  name: "Feature", // 特征
  components: { FeatureBox },
  setup() {
    let useStores = useStore();
    let store = useStores.state;

    return {
      store,
    };
  },
  created() {},
};
</script>

<style scoped>
.feature {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 80px;
  grid-row-gap: 40px;
  justify-content: center;
  align-content: center;
  margin-bottom: 50px;
}
.item {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin-bottom: 1rem;
}

@media all and (max-width: 640px) and (min-width: 320px) {
  .feature {
    width: auto;
    margin: 0 50px 60px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-row-gap: 32px;
  }
}
@media all and (max-width: 920px) and (min-width: 640px) {
  .feature {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-row-gap: 32px;
    grid-column-gap: 0px;
  }
}
</style>
