<template>
  <div class="view view-loading" ref="view"
       :class="{
          'removed': removed,
           'animate__flipOutY': removed
        }">
    <div
      class="view-page flex"
      :class="{ selector: canOptional }"
      ref="viewPage"
      @mouseenter.self="mouseenter"
      @mouseleave.self="mouseLeave"
    >
      <div class="icons icon pc">
        <img
          @click.stop="enlarge"
          src="@/assets/images/view-pdf/icon-view-amplification.png"
          alt="enl"
        />
        <img
          @click.stop="remove"
          src="@/assets/images/view-pdf/icon-view-delete.png"
          alt="del"
        />
      </div>
      <div class="icon mb remove" v-if="edit && !readyDrag">
        <i @touchstart.stop.prevent="removeConfirm">
          <RoundDelete
            :color="readyRemove ? '#BCBCBC' : '#CB0000'"
          >
          </RoundDelete>
        </i>
      </div>
      <div
        ref="canvasParent"
        @touchend="canvasTouchend"
        @click.stop.prevent="choose"
        class="canvas-parent flex"
        :class="{
          'canvas-parent-selected': page.selected && !isCut,
        }"
      >
        <div
          v-if="canOptional"
          class="flex select-box-parent"
          @click.stop="choose"
          :style="{
            border: page.selected
              ? 'none'
              : '3px solid rgba(255, 255, 255, 0.5)',
          }"
        >
          <img
            v-if="page.selected"
            src="@/assets/images/view-pdf/icon-pitch.svg"
            alt="load error"
          />
          <div v-else class="select-box"></div>
        </div>

        <canvas ref="canvas" class="canvas"></canvas>

        <div v-if="readyRemove" class="mb" @touchstart.stop.prevent="e => e.preventDefault()">
          <div
            class="ready-remove"
            @touchstart.stop.prevent="hideMask"
          >
            <div
              class="ready-remove-button"
              @touchstart.stop.prevent="remove"
            >{{$t("mergePage.confirmRemove")}}</div>
          </div>
        </div>

      </div>
      <div
        v-if="isCut"
        class="flex cut-num"
        :style="{ backgroundColor: currentColor }"
      >
        <span
          :style="{
            color: page.cutIndex || page.cutIndex === 0 ? '#ffffff' : 'inherit',
          }"
          >{{ index + 1 }}</span
        >
      </div>
      <div
        v-else
        :title="page.name"
        class="page-name"
        :class="{ 'page-name-long': page.viewType === 'File' }"
      >
        {{ utils.getHideString(page.name) }}
      </div>
    </div>
    <div v-if="isCut && !isLast" class="cut-view flex">
      <div v-if="page.cuted" class="cuted flex" @click="cut">
        <div class="flex">
          <img src="@/assets/images/work/scissors_cuted.svg" alt="load error" />
        </div>
      </div>
      <div
        v-else
        class="uncuted flex"
        @click="cut"
        @mouseenter.self="scissorsMouseenter"
        @mouseleave.self="scissorsMouseLeave"
      >
        <div v-show="scissorsHover" class="flex">
          <img src="@/assets/images/work/scissors_uncut.svg" alt="load error" />
        </div>
        <div v-show="!scissorsHover" class="flex">
          <img src="@/assets/images/work/dotted_line.svg" alt="load error" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/util/utils";
import RoundDelete from "@/components/icons/RoundDelete";
import { painting } from "@/common/pdfUtils";

import { computed, onMounted, ref, watch } from "vue";
import { onLongPress } from "@vueuse/core";

export default {
  name: "PdfView",
  components: { RoundDelete },
  emits: [
    "enlarge",
    "remove",
    "choose",
    "cut",
    "paintingNextPage",
    "paintingNextFile",
    "onLongPressed",
    "onReadyDrag",
    "onReadyDragEnd",
    "hideMask",
  ],
  props: {
    index: {
      type: Number,
      require: true,
    },
    page: {
      type: Object,
      require: true,
    },
    toRender: {
      type: Boolean,
      require: false,
    },
    isCut: {
      type: Boolean,
      require: false,
    },
    canOptional: {
      type: Boolean,
      require: false,
    },
    edit: {
      type: Boolean,
      require: false,
    },
    readyDrag: {
      type: Boolean,
      require: false,
    },
    isLast: {
      type: Boolean,
      require: false,
    },
  },
  setup(props, context) {

    function getIsMobile() {
      return window.document.body.clientWidth < 765;
    }

    let removed = ref(false);
    let readyRemove = ref(false);

    function removeConfirm() {
      if (readyRemove.value) return;
      hideMask();
      readyRemove.value = true;
    }

    function enlarge() {
      if (props.canOptional) return;
      context.emit("enlarge", props.index);
    }
    function remove() {
      if (props.canOptional) return;
      removed.value = true;
      window.setTimeout(function() {
        context.emit("remove", props.index);
      }, 700)
    }
    function cut() {
      context.emit("cut", props.index);
    }

    let view = ref(null);
    let canvasParent = ref(null);
    let canvas = ref(null);

    let isRendered = false;
    function render() {
      if (isRendered) return;
      painting(props.page.canvas, canvas.value).then(() => {
        isRendered = true;
        context.emit(`paintingNext${props.page.viewType}`, props.index);
        view.value.classList.remove("view-loading");
      });
    }
    watch(
      () => props.toRender,
      (newValue) => {
        if (newValue) {
          render();
        }
      }
    );
    watch(
      () => props.edit,
      (newValue) => {
        if (!newValue) {
          readyRemove.value = false;
        }
      }
    );

    let viewPage = ref(null);
    function mouseenter() {
      if (props.isCut) return;
      if (getIsMobile()) return;
      if (props.canOptional) viewPage.value.classList.add("selector-hover");
      else viewPage.value.classList.add("view-page-hover");
    }
    function mouseLeave() {
      if (props.isCut) return;
      if (getIsMobile()) return;
      if (props.canOptional) viewPage.value.classList.remove("selector-hover");
      else viewPage.value.classList.remove("view-page-hover");
    }

    let scissorsHover = ref(getIsMobile());
    function scissorsMouseenter() {
      if (!props.isCut || getIsMobile()) return;
      scissorsHover.value = true;
    }
    function scissorsMouseLeave() {
      if (!props.isCut || getIsMobile()) return;
      scissorsHover.value = false;
    }

    watch(props.page, () => {
      if (!props.page.cuted) scissorsMouseLeave();
    });

    function choose() {
      if (props.canOptional) {
        context.emit("choose", props.index);
      } else if (getIsMobile() && !props.edit) {
        enlarge();
      }
    }

    function hideMask() {
      readyRemove.value = false;
      context.emit("hideMask")
    }

    const colors = [
      "#E93F33",
      "#EEA700",
      "#00BC00",
      "#09CDCD",
      "#AC20AC",
      "#3949AB",
    ];
    let currentColor = computed(() => {
      if (!props.page.cutIndex && props.page.cutIndex !== 0)
        return "transparent";
      else return colors[props.page.cutIndex % colors.length];
    });


    // longPressed
    const onLongPressCallbackHook = () => {
      if (getIsMobile()) context.emit("onLongPressed");
    }

    onLongPress(canvas, onLongPressCallbackHook, {delay: 1000})

    onLongPress(canvasParent, () => {
      if (props.edit && getIsMobile()) {
        viewPage.value.classList.add("scaled-canvas");
        context.emit("onReadyDrag");
      }
    }, {delay: 200})


    function canvasTouchend() {
      if (props.edit && getIsMobile()) {
        viewPage.value.classList.remove("scaled-canvas");
        context.emit("onReadyDragEnd");
      }
    }
    onMounted(() => {
      if (props.toRender) render();
    });

    return {
      canvasParent,
      getIsMobile,
      hideMask,
      cut,
      currentColor,
      choose,
      viewPage,
      mouseLeave,
      mouseenter,
      scissorsMouseLeave,
      scissorsMouseenter,
      scissorsHover,
      view,
      canvas,
      enlarge,
      remove,
      removed,
      readyRemove,
      removeConfirm,
      utils,
      canvasTouchend,
    };
  },
  created() {},
};
</script>

<style scoped>
* {
  user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  -webkit-touch-callout:none !important;
}
.view {
  position: relative;
}
.view-page {
  box-sizing: border-box;
  width: 148px;
  height: 182px;
  padding: 26px 24px;
  background: transparent;
  position: relative;
}
.canvas:hover {
  /*cursor: all-scroll;*/
}
.view-page-hover {
  background: #e3e3e3;
}
.dragging .view-page {
  background: transparent;
}
.view-page:active {
  background: transparent !important;
}
.view-page .canvas-parent {
  box-sizing: border-box;
  position: relative;
  border: 1px solid #dbdbdb;
  width: 102px;
  height: 132px;
  background-color: #ffffff;
  overflow: hidden;
}
.dragging .sortable-ghost .canvas-parent {
  background: #f1f1f1;
  border: none;
}
.dragging .sortable-ghost .page-name {
  opacity: 0;
}
.dragging .sortable-ghost .canvas-parent .canvas {
  opacity: 0;
}
.selector-hover .canvas-parent,
.view-page .selected {
  cursor: pointer;
  border: 2px solid #00a3ff;
  height: 134px;
  width: 104px;
}
.canvas-parent .select-box {
  border: 2px solid #dbdbdb;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.selector-hover .canvas-parent .select-box {
  border: 2px solid #00a3ff;
}
.view-page .canvas-parent-selected {
  border: 2px solid #00a3ff;
  width: 104px;
  height: 134px;
}
.view-page .canvas-parent .select-box-parent {
  top: 6px;
  left: 6px;
  box-sizing: border-box;
  border: 2px solid rgba(255, 255, 255, 0.5);
  width: 26px;
  height: 26px;
  position: absolute;
  background: #ffffff;
  /*background-clip: padding-box;*/
}

.view-page .canvas-parent > div img {
  width: 20px;
  height: 20px;
  cursor: pointer;
  user-select: none;
}

.view-page .canvas {
  width: 100px;
  height: 130px;
  background: url("~@/assets/images/loading.svg") no-repeat center;
  user-select: none;
}
.selector .canvas {
  cursor: pointer !important;
}

.view-page .icon {
  justify-content: center;
  align-items: center;
  position: absolute;
}

.view-page .icons {
  opacity: 0;
  top: 6px;
  right: 6px;
}
.view-page .remove {
  opacity: 1;
  z-index: 1;
  top: 15px;
  right: 14px;
}
.view-page .remove i{
  width: 20px;
  height: 20px;
}
.dragging .view-page .remove {
  opacity: 0;
}
.view-page-hover .canvas-parent,
.view-page-hover:active,
.view-page-hover .canvas:active {
  cursor: all-scroll;
}
.sortable-chosen {
  cursor: all-scroll !important;
}

.view-page-hover .icons {
  display: flex;
  opacity: 100;
}
.dragging .sortable-ghost .icons,
.view-page:active .icons {
  opacity: 0;
}
.dragging .view-page .icons {
  opacity: 0;
}
.view-page .icons img {
  margin-right: 2px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.view-page .page-name,
.view-page .cut-num {
  text-align: center;
  white-space: nowrap;
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  font-size: 14px;
}
.view-page .page-name-long {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  font-size: 12px;
  width: 100%;
}

.view-page .cut-num {
  bottom: 1px;
  border-radius: 4px;
  min-width: 20px;
  min-height: 20px;
}
.cut-view {
  width: 24px;
  height: 100%;
  position: absolute;
  right: -12px;
  top: 0;
}
.cut-view .uncuted .flex,
.cut-view .cuted .flex {
  height: 100%;
  margin-left: 24px;
}

.cut-view .uncuted,
.cut-view .cuted {
  z-index: 1;
  cursor: pointer;
  height: 88px;
}

.cut-view img {
  cursor: pointer;
  width: 24px;
  height: 100%;
  padding: 2px 0;
  background-color: #fafafa;
  transform: translateX(-12px);
  -webkit-user-drag: none;
}

.mb {
  display: none;
}

.ready-remove {
  box-sizing: border-box;
  background: linear-gradient(0deg, rgba(60, 60, 60, 0.2), rgba(60, 60, 60, 0.2));
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.ready-remove .ready-remove-button {
  z-index: 1;
  background: #CB0000;
  border: 1px solid #CB0000;
  box-sizing: border-box;
  border-radius: 4px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;
  padding: 7px 8px;
}

.scaled-canvas {
  transform: scale(1.1);
}

@media all and (max-width: 765px) and (min-width:320px) {
  .mb {
    display: flex;
  }
  .pc {
    display: none;
  }
  .canvas-parent .select-box {
    border: 1px solid #dbdbdb;
  }

}

</style>
