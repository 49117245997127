<template>
  <div class="vpage">
    <div>
        <div class="title">{{ $t("verifyEmailPage.title") }}</div>
        <div class="title-desc">
          {{ $t("verifyCodePage.title") }}<br />{{ passEmail || " ***" }}
        </div>
      <div>
        <VerificationCode
          ref="VCode"
          :hint="hint"
          @resetHint="resetHint"
          @enterOver="enterOver"
        ></VerificationCode>
        <div class="desc" v-if="desc">{{ desc }}</div>
      </div>
      <Button ref="button" class="button" @click="verifyCode" :status="butStatus">
        {{ $t("action.next") }}
      </Button>
      <div class="resend" v-if="currentEmail">
        <span
          @click="resendCode"
          :class="store.canResendCode ? 'can' : 'cannot'"
          >{{ $t("verifyCodePage.resentText") }}
          <span v-if="!store.canResendCode"
            >({{ store.countTime }}s)</span
          ></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed, inject } from "vue";
import { useRoute, useRouter } from "vue-router";
import VerificationCode from "@/components/VerifyCode";
import Button from "@/components/Button";
import utils from "../util/utils";
import Account from "../store/account";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { toBackNow } from "@/router/back";
export default {
  name: "VerifyPage",
  components: {
    VerificationCode,
    Button,
  },
  props: {
    isHead: {
      type: Boolean,
      default: false,
    },
    isSign: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { t } = useI18n();
    let useStores = useStore();
    let route = useRoute();
    let router = useRouter();
    let store = useStores.state;
    let desc = ref("");
    let VCode = ref(null);
    let hint = ref(false);
    let butStatus = ref("before");

    function resetHint() {
      hint.value = false;
      desc.value = "";
    }

    let button = ref(null);
    function enterOver() {
      try {
        button.value.$el.focus();
      }catch (e){
        console.log(e);
      }
    }

    const currentEmail = store.currentEmail;
    if (!currentEmail) {
      console.log("Please use the correct steps to log in or sign up!");
      router.push({ name: "login" });
    }
    let passEmail = computed(() => {
      return utils.noPassByEmail(store.currentEmail);
    });

    let headTopupHide = inject("headTopupHide");

    function verifyCode() {
      let code = VCode.value.inputList
        .map((e) => e.val)
        .toString()
        .replaceAll(",", "")
      hint.value = false;
      if (!code || code.length < 5) {
        desc.value =
          t("error.codeShortText") ||
          "Please enter a complete verification code.";
        hint.value = true;
        return;
      }
      if (!currentEmail) {
        desc.value =
          t("error.illogicalText") ||
          "Please use the correct steps to log in or sign up.";
        hint.value = true;
        return;
      }
      butStatus.value = "going";
      if (
        (route.params.source === "signUp" || props.isSign) &&
        store.currentPassword
      ) {
        utils
          .sigup(currentEmail, store.currentPassword, code)
          .then((res) => {
            butStatus.value = "ok";
            utils.setCookie("sid", res.data.sid);
            Account.storage(res.data.sid);
            window.localStorage.removeItem(Account.store_byb_key);
            useStores.dispatch("account/init");
            useStores.commit("changeLogin", true);
            // router.push({ path: "/workspace" });
            window.setTimeout(function() {
              headTopupHide();
              toBackNow("signUp");
            }, 500)
          })
          .catch((e) => {
            butStatus.value = "err";
            if (e && e.response && e.response.status === 403) {
              desc.value = t("error.codeVerifyErrText");
            } else if (e && e.response && e.response.status === 409) {
              desc.value = t("error.accountExist");
            } else {
              desc.value = t("error.unknownError");
            }
            hint.value = true;
          })
          .finally(() => {
            butStatus.value = "before"
          });
      } else if (
        route.params.source === "forgot" ||
        (props.isHead && !props.isSign)
      ) {
        utils
          .authCode(currentEmail, code)
          .then((res) => {
            butStatus.value = "ok";
            utils.setCookie("tsid", res.data.sid);
            window.setTimeout(function () {
              if (props.isHead) {
                context.emit("toResetPasswd");
              } else {
                router.push({
                  name: "resetPasswd",
                  params: { email: currentEmail, sid: res.data.sid },
                });
              }
            }, 500)
          })
          .catch((e) => {
            butStatus.value = "err";
            if (e && e.response && e.response.status === 404) {
              desc.value = t("error.accountNotExist");
            } else if (e && e.response && e.response.status === 403) {
              desc.value = t("error.codeVerifyErrText");
            } else {
              desc.value = t("error.unknownError");
              window.setTimeout(function () {
                router.push({ name: "signUp" });
              }, 500);
            }
            hint.value = true;
          })
          .finally(() => {
            butStatus.value = "before"
          });
      }
    }

    function clearly() {
      VCode.value.clearlyCode();
    }
    function resetCan() {
      window.clearInterval(store.timeInterval);
      useStores.commit("change", { key: "canResendCode", value: true });
      useStores.commit("resetTimeInterval");
    }
    function setCountDownInterval() {
      window.clearInterval(store.timeInterval);
      useStores.commit("change", { key: "canResendCode", value: false });
      store.timeInterval = window.setInterval(() => {
        if (store.countTime > 1)
          useStores.commit("change", {
            key: "countTime",
            value: store.countTime - 1,
          });
        else {
          resetCan();
        }
      }, 1000);
    }

    function resendCode() {
      if (!store.canResendCode) return;
      butStatus.value = "before";
      setCountDownInterval();
      clearly();
      resetHint();
      utils
        .sendCode(currentEmail)
        .then(() => {
          useStores.commit("change", { key: "canResendCode", value: false });
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 429) {
            useStores.commit("change", { key: "countTime", value: 59 });
            desc.value = t("error.sendCodeFrequently");
            return;
          }
          resetCan();
          desc.value = t("error.sendCodeErr");
          console.log(e);
        });
    }

    onMounted(() => {
      if (currentEmail && !store.timeInterval) setCountDownInterval();
    });
    return {
      butStatus,
      passEmail,
      clearly,
      VCode,
      store,
      resendCode,
      verifyCode,
      desc,
      currentEmail,
      hint,
      resetHint,
      button,
      enterOver,
    };
  },
  created() {},
};
</script>

<style scoped>
.vpage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 390px;
  height: 100%;
}
.title {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 28px;
  text-align: center;
  white-space: nowrap;
}
.but {
  width: 360px;
}
a {
  font-size: 14px;

  text-align: center;
}
.quiet {
  color: #b6b6b6;
}
.desc {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
  color: #fb3636;
}

.button {
  margin: 14px 0;
}

.resend {
  font-size: 14px;
  text-align: center;
}
.resend .cannot {
  color: #b6b6b6;
  pointer-events: none;
}
.resend .can {
  cursor: pointer;
  color: #00a3ff;
}

.title-desc {
  margin-bottom: 24px;
  font-size: 16px;
  text-align: center;
}

@media all and (max-width: 570px) and (min-width: 320px) {
  .title {
    margin-bottom: 14px;
  }
  .title-desc {
    font-size: 14px;
  }
  .can {
    font-size: 16px;
  }
}
</style>
