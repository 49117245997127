<template>
  <svg
    :width="iwidth"
    :height="iheight"
    viewBox="0 0 14 14"
    :fill="color"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13 7.99963C13 11.3133 10.3137 13.9996 7 13.9996C3.68629 13.9996 1 11.3133 1 7.99963C1 6.27805 1.72508 4.72581 2.88651 3.63164C3.28896 3.2525 3.91794 3.38167 4.22464 3.84172V3.84172C4.53068 4.30078 4.39482 4.91459 4.02575 5.3247C3.38804 6.03334 2.99996 6.97109 2.99996 7.99947C2.99996 10.2086 4.79082 11.9995 6.99996 11.9995C9.2091 11.9995 11 10.2086 11 7.99947C11 6.9695 10.6107 6.03044 9.9712 5.32142C9.6014 4.91139 9.46498 4.29703 9.77127 3.8376V3.8376C10.0777 3.37795 10.706 3.2486 11.1085 3.62695C12.2729 4.72137 13 6.27559 13 7.99963Z"
      :fill="color"
    />
    <path
      d="M12.3096 2.68702L8.6836 6.31298C8.39603 6.60055 7.90371 6.44278 7.83685 6.04163L7.11166 1.69048C7.05526 1.35207 7.34865 1.05868 7.68706 1.11508L12.0382 1.84027C12.4394 1.90713 12.5971 2.39945 12.3096 2.68702Z"
      :fill="color"
    />
  </svg>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "RestartIcon",
  props: {
    iwidth: {
      type: String,
      default: "14",
    },
    iheight: {
      type: String,
      default: "14",
    },
    color: {
      type: String,
      default: "#ffffff",
    },
  },
});
</script>
