<template>
  <div class="input-box">
    <div
      class="input"
      :class="{ 'hint-input': hasHint || hasParentHint, focus: isFocus }"
      @click="boxFocus"
    >
      <img
        class="icon"
        :src="icon"
        :class="{
          'err-icon': hasParentHint || hasHint,
          'in-icon': !(hasParentHint || hasHint) && ivalue.length > 0,
        }"
        alt="load error"
      />
      <input
        ref="input"
        :name="name"
        :class="{
          in: ivalue.length > 0,
          'hint-input': hasHint || hasParentHint,
        }"
        :pattern="pattern"
        :type="type"
        :placeholder="iPlaceholder"
        v-model="ivalue"
        @change="inputChange"
        @input="oninput"
        @focus="onfocus"
        @blur="onblur"
      />
      <slot></slot>
    </div>
    <span
      class="hint-text hyphens"
      :class="{'hint-text-fold': textFold}"
      v-if="hasHint || hasParentHint"
      v-html="hint"
    ></span>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  name: "IInput",
  components: {},
  props: {
    name: String,
    type: String,
    pattern: String,
    placeholder: String,
    hint: String,
    icon: String,
    hasParentHint: {
      type: Boolean,
      default: false,
    },
    textFold: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    let input = ref(null);
    let ivalue = ref("");
    let hasHint = ref(false);
    let showPlaceholder = ref(true);

    let iPlaceholder = computed(() => {
      return showPlaceholder.value ? props.placeholder : "";
    });

    function inputChange() {
      if (input.value.validity.typeMismatch) {
        hasHint.value = true;
      } else {
        hasHint.value = false;
        context.emit("updateValue", ivalue.value);
      }
    }
    function oninput() {
      context.emit("oninput", ivalue.value);
    }
    let isFocus = ref(false);
    function onfocus() {
      showPlaceholder.value = false;
      isFocus.value = true;
      context.emit("inputFocus", ivalue.value)
    }
    function onblur() {
      showPlaceholder.value = true;
      isFocus.value = false;
      context.emit("inputBlur", ivalue.value)
    }

    function boxFocus() {
      input.value.focus();
    }

    return {
      input,
      ivalue,
      hasHint,
      inputChange,
      oninput,
      onfocus,
      boxFocus,
      isFocus,
      onblur,
      showPlaceholder,
      iPlaceholder,
    };
  },
  created() {},
};
</script>

<style scoped>
.input-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: #fafafa;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  height: 48px;
  padding: 12px;
  box-sizing: border-box;
  -webkit-user-select: text !important;
}

.input > input {
  border: none !important;
  background: #fafafa;
  margin-left: 8px;
  flex: 1;
  outline: none;
  font-size: 14px;
  padding: 0;
  -webkit-user-select: text !important;
}

.input-box .hint-input,
.input .hint-input {
  background-color: #fff2f2;
  color: #fb3636 !important;
  opacity: 1;
  border: 1px solid #fed5d5;
}
.input input[class="hint-input"]::placeholder {
  text-transform: none !important;
  color: #fb3636;
}

.input .in {
  opacity: 1;
}
.input input:focus {
  color: #454545 !important;
  opacity: 1;
}
.input input::placeholder {
  text-transform: none !important;
  color: #7d7d7d;
}
.icon {
  width: 24px;
  height: 24px;
}

.err-icon {
  filter: invert(75%) sepia(86%) saturate(7386%) hue-rotate(350deg)
    brightness(96%) contrast(85%);
}

.in-icon,
.focus img {
  filter: brightness(0);
}

.hint-text {
  font-size: 14px;

  color: #fb3636;
  margin-top: 6px;
  text-align: start;
}
.hint-text-fold {
  width: calc(100% - 94px);
}

@media all and (max-width: 1020px) and (min-width: 320px) {
  .input-box {
    min-width: 258px;
  }
  .input {
    height: 44px;
  }
  .input > input {
    font-size: 16px;
  }
}
</style>
