<template>
  <div class="theme-title">
    <img :src="store.tools[work].icon" alt="load error" />
    <div v-if="store.tools[work].type === 'convert'">
      {{
        `${store.files[store.tools[work].from].name} ${$t("convertPage.to")}  ${
          store.files[store.tools[work].to].name
        }  ${$t("convertPage.worker")}`
      }}
    </div>
    <div v-if="store.tools[work].type === 'merge'">
      {{ `${$t("mergePage.name")} ${store.files[store.tools[work].to].name}` }}
    </div>
    <div v-if="store.tools[work].type === 'split'">
      {{ `${$t("splitPage.name")} ${store.files[store.tools[work].to].name}` }}
    </div>
  </div>
  <WorkArea
    :background-color="
      (store.tools[work].type === 'merge' ||
        store.tools[work].type === 'split') &&
      store.workStatus === 'mergeOrSplitBefore'
        ? 'transparent'
        : store.tools[work].background
    "
    :border-color="store.tools[work].borderColor"
  >
    <div class="work-area">
      <Merge
        v-if="
          store.workStatus === 'mergeOrSplitBefore' ||
          store.workStatus === 'mergeOrSplitView'
        "
        :status="store.workStatus"
        @mergeOrSplitView="mergeOrSplitView"
        :files="mergeFiles"
        @backErr="backErr"
      >
      </Merge>
      <ChooseErr
        v-if="store.workStatus === 'fileTypeErr'"
        :file-type="store.currentRule.name"
        :buttonColor="store.tools[work].textColor"
      />
      <ChooseFile
        v-if="store.workStatus === 'chooseFile'"
        @choose="choose"
        @filedragoverend="filedragoverend"
        :rule="store.currentRule"
        :backgroundColorButton="store.tools[work].textColor"
      />
      <ConvertBefore
        :text="$t('action.download')"
        @click-but="downloadFile"
        :full-filename="store.currentFile.downFullFileName"
        :filename="store.currentFile.downFileName"
        :iconPC="store.files[store.tools[work].from].icon"
        :iconMB="store.files[store.tools[work].from].iconSmall"
        :buttonColor="store.tools[work].textColor"
        :restart="true"
        :behavior-icon="require('@/assets/images/work/icon_download.png')"
        v-if="store.workStatus === 'convertAfter'"
      >
        <img
          src="@/assets/images/work/icon_convert_check.png"
          alt="load error"
          class="before-img"
        />
      </ConvertBefore>
      <ConvertBefore
        :buttonColor="store.tools[work].textColor"
        :text="$t('convertPage.convert')"
        @click-but="convertFile"
        :full-filename="store.currentFile.convertFullFileName"
        :filename="store.currentFile.convertFileName"
        :iconPC="store.files[store.tools[work].from].icon"
        :iconMB="store.files[store.tools[work].from].iconSmall"
        :behavior-icon="require('@/assets/images/work/icon_convert_white.png')"
        v-if="store.workStatus === 'convertBefore'"
      >
        <FileProperty
          :num-pages="store.currentFile.pages"
          :pdf-size="store.currentFile.size"
        ></FileProperty>
      </ConvertBefore>
      <Going
        :rolling="false"
        :icon="store.files[store.tools[work].from].icon"
        :pre="pre"
        :title="$t('convertPage.uploading')"
        :behavior-icon="require('@/assets/images/work/icon_upload.png')"
        :pre-color="store.tools[work].textColor"
        v-if="store.workStatus === 'uploading'"
      />
      <Going
        :icon="store.tools[work].iconMain"
        :pre="pre"
        :title="$t('convertPage.converting')"
        :behavior-icon="require('@/assets/images/work/icon_convert_black.png')"
        :pre-color="store.tools[work].textColor"
        v-if="store.workStatus === 'converting'"
      />
      <ConvertOff v-if="store.workStatus === 'convertOff'"></ConvertOff>
      <ConvertErr
        :errCode="errCode"
        v-if="store.workStatus === 'convertErr'"
        :buttonColor="store.tools[work].textColor"
      ></ConvertErr>
    </div>
  </WorkArea>

  <Feature v-if="!store.isLogin"></Feature>

  <ColumnToolsAndSiteMap></ColumnToolsAndSiteMap>
</template>

<script>
import { reactive, ref, provide, onMounted } from "vue";
import { useStore } from "vuex";
import WorkArea from "../components/WorkArea";
import ChooseFile from "../components/ChooseFile";
import ChooseErr from "../components/ChooseErr";
import ConvertBefore from "../components/ConvertBefore";
import ConvertOff from "../components/ConvertOff";
import Going from "../components/Going";
import ColumnToolsAndSiteMap from "../components/ColumnToolsAndSiteMap";
import Feature from "../components/Feature";
import FileProperty from "../components/FileProperty";
import ConvertErr from "@/components/ConvertErr";
import Merge from "@/components/MergeOrSplit";
import utils from "../util/utils";
import { useRoute } from "vue-router";

export default {
  name: "Convert",
  components: {
    WorkArea,
    ChooseFile,
    ConvertBefore,
    ConvertOff,
    ChooseErr,
    Going,
    ColumnToolsAndSiteMap,
    FileProperty,
    ConvertErr,
    Merge,
    Feature,
  },
  setup() {
    let useStores = useStore();
    let store = useStores.state;
    let route = useRoute();
    let work = route.params.work;
    let errCode = ref("");
    let isFileTypeErr = ref(false);
    let pre = ref(0);
    const ERROR_CODE = ["401", "403", "415", "422", "500", "504"];

    let mergeFiles = reactive({ files: [], paths: [] });
    function mergeOrSplitView() {
      useStores.commit("changeWorkStatus", "mergeOrSplitView");
    }

    function backErr(e) {
      errCode.value = e;
    }
    function downloadFile() {
      if (
        (store.tools[work].to === "jpg" || store.work === "split") &&
        store.currentFile.convertAfterFiles.length > 0
      ) {
        if (store.currentFile.archiveBeforeUrl) {
          utils.download(
            `https://${process.env.VUE_APP_DOMAIN}/ufo/api/v1/s/${store.currentFile.archiveBeforeUrl}`,
            store.currentFile.downFullFileName
          );
          return;
        }
        utils
          .archives(
            store.currentFile.downloadFileUrl,
            store.currentFile.convertAfterFiles,
            store.tools[work].to === "jpg" ? "images" : "pages"
          )
          .then((res) => {
            useStores.commit("changeCurrentFileProperty", {
              key: "downloadFileUrl",
              value: res.data.path,
            });
            useStores.commit("changeCurrentFileProperty", {
              key: "downFullFileName",
              value: res.data.metadata.ofn,
            });
            useStores.commit("changeCurrentFileProperty", {
              key: "archiveBeforeUrl",
              value: res.data.path,
            });
            utils.download(
              `https://${process.env.VUE_APP_DOMAIN}/ufo/api/v1/s/${res.data.path}`,
              res.data.metadata.ofn
            );
          });
      } else {
        utils.download(
          `https://${process.env.VUE_APP_DOMAIN}/ufo/api/v1/s/${store.currentFile.downloadFileUrl}`,
          store.currentFile.downFullFileName
        );
      }
    }
    let inter = null;
    function falseProgress(defPro = 68) {
      pre.value = 0;
      inter = setInterval(function () {
        if (pre.value < defPro) {
          pre.value += 1;
        } else {
          window.clearInterval(inter);
        }
      }, 500);
    }
    function resetProgress() {
      pre.value = 100;
      window.clearInterval(inter);
    }

    function setFileAttr(res, archive = false) {
      let _downloadFileUrl = "";
      let _downFullFileName = "download";
      let _downFileName = "download";

      try {
        _downloadFileUrl = res.data[0].path;
        _downFullFileName = res.data[0].metadata.ofn;
        _downFileName = utils.getOmittedFilename(res.data[0].metadata.ofn);
      } catch (e) {
        console.log(e);
      }
      if (archive) {
        const files = res.data.map((f) => {
          return f.path;
        });
        useStores.commit("changeCurrentFileProperty", {
          key: "convertAfterFiles",
          value: files,
        });
        let suf = store.tools[work].to === "jpg" ? "-images" : `-pages`;
        _downFullFileName =
          utils.getFileBaseName(_downFullFileName) + suf + ".zip";
        _downFileName = utils.getOmittedFilename(_downFullFileName);
      }
      useStores.commit("changeCurrentFileProperty", {
        key: "downloadFileUrl",
        value: _downloadFileUrl,
      });
      useStores.commit("changeCurrentFileProperty", {
        key: "downFullFileName",
        value: _downFullFileName,
      });
      useStores.commit("changeCurrentFileProperty", {
        key: "downFileName",
        value: _downFileName,
      });

      setTimeout(function () {
        useStores.commit("changeWorkStatus", "convertAfter");
      }, 500);
    }

    function convertFile() {
      falseProgress();
      utils
        .convertFile(
          `${store.currentFile.convertFileUrl}/${
            store.files[store.tools[work].to].suffixName
          }`
        )
        .then((res) => {
          pre.value = 100;
          setFileAttr(res, store.tools[work].to === "jpg");
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 429) {
            useStores.commit("change", {
              key: "workLimitType",
              value: "convert",
            });
            useStores.commit("changeWorkStatus", "convertOff");
          } else if (e && e.response && e.response.status) {
            try {
              errCode.value = e.response.status.toString();
              if (!ERROR_CODE.includes(errCode.value)) {
                errCode.value = "500";
              }
            } catch (e) {
              console.log(e);
              errCode.value = "500";
            }
            useStores.commit("changeWorkStatus", "convertErr");
          } else {
            useStores.commit("changeWorkStatus", "chooseFile");
          }
        });

      useStores.commit("changeWorkStatus", "converting");
    }

    function putFileAttribute(data) {
      const _ofn = data.metadata.ofn;
      const _convertFileName = utils.getOmittedFilename(_ofn);
      const _path = data.path;
      let _file = {
        downFileName: _convertFileName.replace(
          `.${utils.getFileSuffix(_ofn)}`,
          `.${store.files[store.tools[work].to].suffixName}`
        ),
        downFullFileName: _ofn.replace(
          `.${utils.getFileSuffix(_ofn)}`,
          `.${store.files[store.tools[work].to].suffixName}`
        ),
        convertFileName: _convertFileName,
        convertFullFileName: _ofn,
        convertFileUrl: _path,
        downloadFileUrl: _path,
      };
      Object.keys(_file).forEach((k) => {
        useStores.commit("changeCurrentFileProperty", {
          key: k,
          value: _file[k],
        });
      });
    }
    provide("putFileAttribute", putFileAttribute);
    provide("setFileAttr", setFileAttr);

    function upload(files) {
      let file = files[0];

      utils.getNumPages(file, store.tools[work].from, function (e) {
        useStores.commit("changeCurrentFileProperty", {
          key: "pages",
          value: (store.currentFile.pages || 0) + e,
        });
      });
      useStores.commit("changeCurrentFileProperty", {
        key: "numFile",
        value: files.length,
      });

      useStores.commit("changeCurrentFileProperty", {
        key: "size",
        value: utils.renderSize(file.size),
      });
      utils
        .uploadFile(files, (current, total) => {
          let _pre = utils.getPercentage(current, total);
          pre.value = 25 + (_pre > 75 ? 75 : _pre);
        })
        .then((res) => {
          if (work === "merge" || work === "split") {
            let _files = Object.values(files);
            mergeFiles.files = _files;
            mergeFiles.paths = _files.length > 1 ? res.data : [res.data];
            useStores.commit("changeWorkStatus", "mergeOrSplitBefore");
            return;
          }
          let _data = Array.isArray(res.data) ? res.data[0] : res.data;
          putFileAttribute(_data);
          useStores.commit("changeWorkStatus", "convertBefore");
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 413) {
            useStores.commit("change", {
              key: "workLimitType",
              value: "upload",
            });
            useStores.commit("changeWorkStatus", "convertOff");
          } else if (e && e.response && e.response.status === 400) {
            backErr("400");
            useStores.commit("changeWorkStatus", "convertErr");
          } else {
            backErr("500");
            useStores.commit("changeWorkStatus", "convertErr");
          }
        });
    }

    async function choose(e) {
      pre.value = 0;
      if (!(e && e[0])) return;
      let files = Object.values(e);


      for (let i = 0; i < files.length; i++) {
        let fileType = utils.getFileSuffix(files[i].name);
        if (
          !store.currentRule.formatIncluded.includes(fileType.toLowerCase())
        ) {
          useStores.commit("changeWorkStatus", "fileTypeErr");
          return false;
        }
      }

      let file = files[0];

      if (file.size <= 0) {
        backErr("422")
        useStores.commit("changeWorkStatus", "convertErr");
        return false;
      }

      if (store.currentRule.suffixName === "pdf") {
        let erObj = null;
        await utils.isEncryptInPdf(file)
          .then(()=>{})
          .catch((err) => {
            erObj = err;
          })
        if (erObj) {
          if (erObj === "NEEDPASS") {
            backErr("522")
            useStores.commit("changeWorkStatus", "convertErr");
          }else if(erObj === "NOTPDF") {
            useStores.commit("changeWorkStatus", "fileTypeErr");
          }
          return false;
        }
      }


      let sizes = files.map((f) => f.size);
      let maxSize = utils.getMaxValue(sizes);
      useStores.commit("changeWorkStatus", "uploading");
      falseProgress(25);
      utils
        .getQuotaOfMe()
        .then((res) => {
          const _quota = res.data;
          if (
            _quota &&
            _quota.upload &&
            maxSize > _quota.upload.single_file_size
          ) {
            useStores.commit("change", {
              key: "workLimitType",
              value: "upload",
            });

            utils
              .uploadFile(files, () => {})
              .then((res) => {
                resetProgress();
                let _data = Array.isArray(res.data) ? res.data[0] : res.data;
                putFileAttribute(_data);
                window.setTimeout(function () {
                  useStores.commit("changeWorkStatus", "convertOff");
                }, 500);
              });

            return;
          }

          if (
            _quota &&
            _quota.process &&
            _quota.process.times_per_day > 0 &&
            _quota.process.times_per_day - _quota.process.used_times < 1
          ) {
            useStores.commit("change", {
              key: "workLimitType",
              value: "convert",
            });

            utils
              .uploadFile(files, () => {})
              .then((res) => {
                resetProgress();
                let _data = Array.isArray(res.data) ? res.data[0] : res.data;
                putFileAttribute(_data);
                window.setTimeout(function () {
                  useStores.commit("changeWorkStatus", "convertOff");
                }, 500);
              });
            return;
          }
          upload(files);
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 422) {
            backErr("422")
            useStores.commit("changeWorkStatus", "convertErr");
            return;
          }
          upload(files);
        });
    }

    function filedragoverend(files) {
      choose(files);
    }

    onMounted(() => {
      let path = utils.getQuery("path");
      if (!path) return;
      utils
        .fileExists(path)
        .then((res) => {
          let _data = Array.isArray(res.data) ? res.data[0] : res.data;
          putFileAttribute(_data);
          useStores.commit("changeWorkStatus", "convertBefore");
        })
        .catch(() => {});
    });

    return {
      work,
      store,
      isFileTypeErr,
      filedragoverend,
      choose,
      upload,
      pre,
      convertFile,
      downloadFile,
      mergeOrSplitView,
      utils,
      mergeFiles,
      errCode,
      backErr,
    };
  },
  created() {},
};
</script>

<style scoped>
.theme-title img {
  width: 24px;
  margin-right: 10px;
}
.theme-title {
  padding-top: 40px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-size: 22px;
  margin-bottom: 28px;
}
.work-area {
  height: 100%;
}
.before-img {
  width: 24px;
  margin-left: 24px;
}
.step {
  margin: 1rem 0;
}
:deep(.small-icon-10) {
  margin-right: 10px;
}
:deep(.small-icon) {
  width: 18px;
  height: 18px;
}
:deep(.small-icon-6) {
  margin-right: 6px;
}
:deep(.small-icon-8) {
  margin-right: 8px;
}
:deep(.small-icon-12) {
  margin-right: 12px;
}
:deep(.small-icon-15) {
  margin-right: 15px;
}
:deep(.status-small-icon) {
  width: 24px;
  height: 24px;
}
:deep(.but) {
  color: #ffffff;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 16px;
  width: 420px;
  height: 48px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 640px) and (min-width: 320px) {
  .theme-title {
    font-size: 24px;
    padding-top: 32px;
  }
  :deep(.but) {
    width: 254px;
    height: 44px;
  }
  :deep(.status-small-icon) {
    width: 20px;
    height: 20px;
    margin-right: 6px;
  }
  .before-img {
    width: 20px;
    margin-left: 14px;
  }
}
@media all and (max-width: 1020px) and (min-width: 640px) {
  :deep(.but) {
    width: 60%;
    height: 46px;
    min-width: 320px;
    max-width: 420px;
  }
}
</style>
