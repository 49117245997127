import { createRouter, createWebHashHistory } from "vue-router";
import { tools } from "@/common/tools";
import ConvertPage from "@/views/ConvertPage";

let work = "";
window.location.href.split("/").forEach((e) => {
  if (e.includes(".html")) {
    const _local = e.split(".")[0];
    Object.keys(tools).forEach((t) => {
      if (tools[t].local === _local) {
        work = t;
      }
    });
  }
});
if (!work) {
  window.location.href = "/pdf-to-word.html";
}
export { work };

const routes = [
  { path: "/", redirect: `/work/${work}` },
  {
    path: "/work/:work",
    name: "work",
    component: ConvertPage,
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach(() => {
  document.documentElement.scrollTop = document.body.scrollTop = 0;
});
export default router;
