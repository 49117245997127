const zh = {
  app: `${process.env.VUE_APP_APP}`,
  pro: {
    name: "PRO",
  },
  useCookieAuth: {
    title: "我们将使用 cookies",
    desc: "本网站使用 cookie %cookie% 和类似技术（统称为“cookie”），这些技术对于本网站的运营及其核心功能至关重要。其他 cookie 只会在您同意的情况下放置。有关更多详细信息，请访问我们的 %privacy% 。",
    buttonText: "好 的"
  },
  error: {
    // 账户不存在
    accountNotExist: "该帐户不存在",
    // 账户存在
    accountExist: "该帐户已存在",
    emailFormatErr: "Email 格式不正确",
    wrongPassword: "密码错误",
    comparePasswordErr: "密码不一致",
    // 密码长度较短
    passwordHintTextShort: "密码长度必须8位或以上",

    passwordHintTextLong: "密码必须包含字母，数字，符号中的2到3种",
    codeVerifyErrText: "验证码不正确",
    // 未输入完整的验证码强行校验时触发
    codeShortText: "验证码不正确",
    // 不是正常操作进入页面时触发
    illogicalText: "请重新登录",
    // 未知错误
    unknownError: "未知错误",
    sendCodeErr: "验证码发送失败",
    // 发生频繁
    sendCodeFrequently: "验证码发送太频繁",

    // 401 - sid未提供或未找到指定的帐户sid
    // 403 - 找到的帐号sid，但无权操作指定资源
    // 415 - 不支持的源或目标格式
    // 504 - 超时
    convertERR400: "Unacceptable files",
    convertERR401: "Invalid account",
    convertERR403:
      "The account does not have the right to operate the specified resources",
    convertERR422: "该文件无效，请检查并重试。",
    convertERR415: "Unsupported Media Type",
    convertERR500: "Unknown error, Please start over",
    convertERR504: "Time out",
    convertERR522: "该文件被锁定，请解锁后重试。",
  },
  action: {
    download: "下载",
    next: "下一步",
    pending: "请等待",
  },
  privacyPolicy: {
    title: "隐私政策",
  },
  termsConditions: {
    title: "使用条款",
  },
  account: {
    name: "帐户",
  },
  head: {
    say: "您好！%userName%",
    signOut: "退出登录",
  },
  payPage: {
    title: `购买 ${process.env.VUE_APP_APP} PRO`,
    // 支付页面
    desc: "不受限制的文件处理",
    creditcards: "Credit Cards",
    grabpay: "Grab Pay",
    alipayglobal: "Alipay Global",
    alipaydesc: "请在跳转至支付宝后完成支付",
    alreadypaid: "已经支付？",
    freshmem: "点击刷新",
    loading: "正在加载...",
    success: {
      pc: {
        title: "支付成功",
        desc: "即将跳转到之前工作的页面（%time%秒）。",
      },
      mb: {
        title: "支付成功",
        desc: "即将跳转到<br>之前工作的页面（%time%秒）。",
      },
    },
    error: {
      pc: {
        title: "出错啦！",
        desc: "请选择其它支付方式",
      },
      mb: {
        title: "出错啦！",
        desc: "请选择其它支付方式",
      },
    },
  },
  productPage: {
    title: "请根据您的预算选择",
    shortTermButtonText: "短期",
    longTermButtonText: "长期",
    // 价格后面显示的时间单位, 如 5.99/月
    showPeriod: "月",
    unit: "$",
    unitText: "",
    days: "天",
    pro_0: {
      butText: "注册",
      // 使用 , 切分
      rules: `访问所有的 ${process.env.VUE_APP_APP} 工具,每天最多处理3个文件,文件体积最大15M`,
      cardBilledtext: "",
    },
    pro_30d: {
      billed: "30天不受限制的文件处理",
      butText: "购买 PRO",
      // 订单页面的plan使用
      plan: "30天 PRO会员",
      // 使用 , 切分
      rules: `访问所有的 ${process.env.VUE_APP_APP} 工具,不受限制的文件处理,文件体积最大1G,文件储存`,
      cardBilledtext: "",
    },
    pro_90d: {
      billed: "90天不受限制的文件处理<br>一次性支付 %totalPrice%.",
      butText: "购买 PRO",
      plan: "90天 PRO会员",
      // 使用 , 切分
      rules: `访问所有的 ${process.env.VUE_APP_APP} 工具,不受限制的文件处理,文件体积最大1G,文件储存`,
      cardBilledtext: "Billed as  %totalPrice%",
    },
    pro_180d: {
      billed: "180天不受限制的文件处理<br>一次性支付 %totalPrice%.",
      butText: "购买 PRO",
      plan: "180天 PRO会员",
      // 使用 , 切分
      rules: `访问所有的 ${process.env.VUE_APP_APP} 工具,不受限制的文件处理,文件体积最大1G,文件储存`,
      cardBilledtext: "Billed as  %totalPrice%",
    },
    pro_365d: {
      billed: "365天不受限制的文件处理<br>一次性支付 %totalPrice%.",
      butText: "购买 PRO",
      plan: "365天 PRO会员",
      // 使用 , 切分
      rules: `访问所有的 ${process.env.VUE_APP_APP} 工具,不受限制的文件处理,文件体积最大1G,文件储存`,
      cardBilledtext: "Billed as  %totalPrice%",
    },
  },
  home: {
    banner: {
      mainSlogan: "一站式 PDF 处理",
      subSlogan: {
        pc: "转换、合并、切分，<br>您需要的工具这都有",
        mb: "转换、合并、切分，<br>您需要的工具这都有",
      } ,
      buttonText: "查看所有 PDF 工具",
    },
    toolSlogan: "最热门的 PDF 工具",
    toolHint: "查看所有工具",

    showBlock: {
      title: "非常简单，交给我们",
      block1: {
        pc: {
          mainTitle: "在线编辑您的文件",
          details:
            "PDF 不仅是用来看的。<br>在您的文档中使用高亮标注、添加图文形状、备注等。<br>文档处理格外犀利。",
        },
        mb: {
          mainTitle: "在线编辑您的文件",
          details:
            "PDF 不仅是用来看的。在您的文档中，<br>使用高亮标注、添加图文形状、备注等。<br>文档处理格外犀利。",
        },
      },
      block2: {
        pc: {
          mainTitle: "创建完美文档",
          details:
            "文件太大了？压缩就好。需要特定格式吗？随手转换。<br>文件乱七八糟难以管理？那就合并、切分、删除冗余页面。",
        },
        mb: {
          mainTitle: "创建完美文档",
          details:
            "文件太大了？压缩就好。<br>需要特定格式吗？随手转换。<br>文件乱七八糟难以管理？<br>那就合并、切分、删除冗余页面。",
        },
      },
    },
    plans: {
      title: "计划及价格",
      desc: `通过购买合适的计划，不受限制地使用 ${process.env.VUE_APP_APP}`,
      buttonText: "比较价格",
      superiorProductPeriod: "月",
      superiorProductDesc: "按月付费，无使用限制",
    },
    chooseUs: {
      title: `为何选择 ${process.env.VUE_APP_APP} ？`,
      showBlock: {
        block1: {
          pc: {
            tag: "安全标准",
            desc: `保证您的文档安全，是我们的首要任务。<br>${process.env.VUE_APP_APP} 符合 ISO 27001，GDPR，CCPA等标准。`,
          },
          mb: {
            tag: "安全标准",
            desc: `保证您的文档安全，<br>是我们的首要任务。<br>${process.env.VUE_APP_APP} 符合 ISO 27001，GDPR，CCPA等标准。`,
          },
        },
        block2: {
          pc: {
            tag: "AI 人工智能转换",
            desc: "我们采用先进的人工智能技术，在转换时<br>尽可能地保留文档的原始风格。",
          },
          mb: {
            tag: "AI 人工智能转换",
            desc: "我们采用先进的人工智能技术，<br>在转换时尽可能地保留文档的原始风格。",
          },
        },
        block3: {
          pc: {
            tag: "24/7 全天候客服支持",
            desc: "昼夜不间断客服支持，随时为您服务。",
          },
          mb: {
            tag: "24/7 全天候客服支持",
            desc: "昼夜不间断客服支持，<br>随时为您服务。",
          },
        },
      },
    },
  },
  footer: {
    copyright: "All rights reserved.",
    company: `${process.env.VUE_APP_COMPANY}, `,
  },
  workspacePage: {
    name: "工作区",
    recents: "最近访问",
    welcome: "欢迎使用 ",
    free: {
      desc: "免费受限制使用",
      to: "升级至 PRO会员",
    },
    paidAndExpired: {
      desc: "PRO会员到期时间",
      to: "更新 PRO会员",
      to2: "订单 & 发票",
    },
    paid: {
      desc: "PRO 会员有效期至",
      to: "订单 & 发票",
    },
    allTools: "全部工具",
    table: {
      name: "文件名",
      size: "大小",
      date: "日期",
      action: "操作",
    },
  },
  convertPage: {
    mergeAndSplitToolName: "合并 & 切分",
    convertFromPDFToolName: "从 PDF 转换",
    convertToPDFToolName: "转换成 PDF",
    to: "转",
    worker: "转换器",
    choose: "选择",
    file: "文件",
    dropFile: "或拖放文件至此处",
    uploading: "正在上传",
    extracting: "正在抽取",
    convert: "转换",
    converting: "正在转换",
    unit: "页",
    startOver: "重新开始",
    startOverTip: "遇到问题，立即联系",
    targetFileErrorDesc: {
      PDF: "该文件内容无法识别为 PDF",
      Word: "该文件内容无法识别为 Word",
      Excel: "该文件内容无法识别为 Excel",
      JPG: "该文件内容无法识别为图像",
      PPT: "该文件内容无法识别为 PPT",
    },
    convertErrTitle: "&nbsp;&nbsp;出错啦！",
  },
  verifyEmailPage: {
    title: "验证您的 Email",
    signUpTip: "还没有帐户？",
    signUpText: "注册",
  },
  verifyCodePage: {
    title: "验证码已发送至",
    resentText: "重新发送",
  },
  signUpPage: {
    name: "注册",
    title: "创建帐户",
    buttonText: "注册",
    loginTip: "已经有帐户？",
    loginText: "登录",
    termsTip:
      "创建帐户即表示您同意<br>我们的&nbsp;%terms%&nbsp;和&nbsp;%policy%&nbsp;",
  },
  loginPage: {
    name: "登录",
    title: "登录至 ",
    forgotTip: "忘记密码？",
    signUpTip: "还没有帐户？",
    signUpText: "注册",
  },
  profilePage: {
    name: "个人信息",
    security: "安全",
    plan: "付费计划",
    firstName: "名",
    lastName: "姓",
    save: "保存",
    saveSuccessDesc: "保存成功",
    saveErrorDesc: "保存失败",
    changePassword: "修改",
    orderAndInvoices: "订单 & 发票",
  },
  changePasswordPage: {
    title: "修改密码",
    currentPasswordPlaceholder: "旧密码",
    newPasswordPlaceholder: "新密码",
    confirmPasswordPlaceholder: "确认密码",
    buttonText: "修改",
    authPasswordErr: "旧密码不正确",
  },
  resetPasswordPage: {
    title: "重置密码",
    buttonText: "重置",
    newPasswordPlaceholder: "新密码",
    confirmPasswordPlaceholder: "确认密码",
    inconsistentPassword: "密码不一致",
  },
  orderPage: {
    title: "订单 & 发票",

    table: {
      date: "日期",
      plan: "计划",
      paid: "支付",
      invoice: "发票",
    },
  },
  email: {
    name: "Email",
  },
  password: {
    name: "密码",
  },
  tools: {
    name: "工具",
    loginTitle: "工具",
    notLoginTitle: "所有工具",
    toolDescText: {
      ptw: "把PDF文档转换为可编辑的Word文档",
      pte: "把PDF文档转换为可编辑的Excel文档",
      ptpp: "把PDF文档转换为可编辑的PowerPoint幻灯片",
      ptj: "把PDF文档按页转换为JPG文档",
      wtp: "把Word文档转换为PDF文档",
      etp: "把Excel文档转换为PDF文档",
      pptp: "把PowerPoint幻灯片转换为PDF文档",
      jtp: "把JPG、PNG、BMP、GIF、TIFF图像转换为PDF文档",
      merge: "把多个PDF文档合并为一个PDF文档",
      split: "从PDF文档提取特定页面，或将每一页分别储存为不同的PDF文档",
    },
    feature: {
      ptw: {
        efficient: {
          title: "如何把PDF转换成Word",
          desc: "将PDF拉至拖放区或点击按钮，以将文件上传至我们的平台。文件转换完成后，便可供您下载。",
        },
        privacy: {
          title: "我们确保您文件的安全！",
          desc: "我们尊重隐私权。在文件转换一小时后，它们将永远从我们的服务器删除。如欲获取更多相关信息，请阅读我们的隐私政策。",
        },
        multiPlatform: {
          title: "适用于所有操作系统！",
          desc: "我们的PDF转Word转换器适用于所有计算机，包括Mac、Windows及Linux。",
        },
        safe: {
          title: "提供最高质量的PDF转Word服务",
          desc: "将PDF转换为Word文档的过程并不简单，因此我们同市面上最佳转换工具提供商 E-ICEBLUE 联手，为您提供最优质的转换服务。",
        },
        reliable: {
          title: "数秒内将PDF转成DOC",
          desc: "文件转换服务过程非常轻松，保证您一试便爱上。",
        },
        convenient: {
          title: "云端转换服务",
          desc: "我们拥有多个将PDF转换成Word文件的云端服务器，因此您可无需操心，放心让我们处理一切。",
        },
      },
      wtp: {
        efficient: {
          title: "快速和高效的处理",
          desc: "Word到PDF的转换是小菜一碟。请上传一个Word文件来开始，然后坐下来放松，我们在短时间内就会准备好你的PDF。DOC和DOCX文件都可以处理。",
        },
        privacy: {
          title: "安全的在线PDF软件",
          desc: `不用担心! 转换后1小时，我们就将文件从服务器上永久删除。如果你想在线存储转换后的文件，请创建一个免费的 ${process.env.VUE_APP_APP} 帐户。`,
        },
        multiPlatform: {
          title: "任何设备，任何操作系统",
          desc: "作为一个基于浏览器的在线软件，你可以随时随地从你的PC、手机或平板电脑方便地访问我们的Doc到PDF转换器。",
        },
        safe: {
          title: "其他受支持的文件类型",
          desc: "乐趣远不止于此。有了这款多合一的PDF转换器，你也可以将其他文件格式转换成PDF，如JPG、PNG、Excel和PPT。",
        },
        reliable: {
          title: "高级Word到PDF转换",
          desc: `如果你每天转换大量的文件，请了解一下${process.env.VUE_APP_APP} PRO，使用这款软件，你可以根据自己的需要将许多Word文件一次过处理成PDF，每个文件最大允许1GB。`,
        },
        convenient: {
          title: "云端作业",
          desc: "我们希望促成一个无缝的数字工作环境。一旦你将一个DOC文件转换为PDF —— 自由预览、编辑和存储 —— 所有这些都在云端完成。",
        },
      },
      pte: {
        efficient: {
          title: "如何把PDF转成Excel",
          desc: "将PDF拉至拖放区或点击相关按钮，以将它上传至我们的平台。然后我们会将它转换可编辑的电子表格供您下载。",
        },
        privacy: {
          title: "最佳转换质量！",
          desc: "PDF转Excel过程非常难。为了提供最佳质量，我们与市场上最优秀的解决方案提供商 E-ICEBLUE 建立了合作。",
        },
        multiPlatform: {
          title: "Windows, Mac及Linux！",
          desc: "无论您使用的是甚么操作系统，我们的PDF转Excel转换器都可于您的计算机运作。",
        },
        safe: {
          title: "我们保障您文件的隐私！",
          desc: "您的隐私权对我们很重要。在文件转换一小时后，所有文件将永远从我们的服务器删除。要了解更多信息，请阅读我们的隐私政策。",
        },
        reliable: {
          title: "转换所有电子表格！",
          desc: "并非所有文件皆可被转换成Excel。请确保您的PDF文件内含电子表格，我们便能轻松地将之转换成Excel。",
        },
        convenient: {
          title: "云端转换",
          desc: "我们的云端服务器不间断地处理PDF文件。无需下载任何软件，我们自会为您处理所有转换程序。很棒对吧？",
        },
      },
      etp: {
        efficient: {
          title: "如何把Excel文件转成PDF",
          desc: "将Excel文件拖放至方框内，或点击“选择文件”按钮以浏览并上传文件。文件将立即被转换成PDF并可下载。",
        },
        privacy: {
          title: "瞬间将Excel转成PDF！",
          desc: "您的Excel文件会按照自动设置快速转换。生成的PDF将会与您想要的样子一模一样，无需进行任何复杂的选择！",
        },
        multiPlatform: {
          title: "支持所有操作系统",
          desc: "我们可在所有操作系统，包括Windows、Mac及Linux，将Excel转成PDF。",
        },
        safe: {
          title: "安全转换",
          desc: "在文件转换一小时后，我们的服务器将永远删除您所有的Excel与PDF文件。请查看下方的隐私政策，以获取详情。",
        },
        reliable: {
          title: "单击创建PDF",
          desc: "仅需上传文件，然后让我们的服务器处理您的xls或xlxs文件。还有比这更简单的方法吗？",
        },
        convenient: {
          title: "云端转换",
          desc: "在云端将Excel转成PDF，而无需使用到计算机的容量。很酷对不？",
        },
      },
      ptpp: {
        efficient: {
          title: "如何将PDF转换成PPT",
          desc: "首先您要拖放或直接点击选择文件按钮以上传PDF。文件会在线上转换，完成后您就能下载文件。",
        },
        privacy: {
          title: "轻松、快速的转换过程！",
          desc: `PDF转PPT的过程极为复杂。为了给您提供最佳解决方案，${process.env.VUE_APP_APP} 已与市面上最佳转换工具提供商 E-ICEBLUE 合作。`,
        },
        multiPlatform: {
          title: "适用于所有操作系统",
          desc: "PDF转换PPT服务适用于所有电脑 – 您的操作系统不会影响转换。",
        },
        safe: {
          title: "安全性是我们的首要考量！",
          desc: "您关心隐私问题，我们亦关心隐私问题。因此所有文件会在转换后一小时内从我们的服务器里删除。如希望知道更多，请参阅我们的私隐政策。",
        },
        reliable: {
          title: "最佳质量的PDF - Powerpoint转换",
          desc: `PDF转PPT的过程非常复杂。为了获得优质文件质量，${process.env.VUE_APP_APP} 与 E-ICEBLUE 达成了合作伙伴关系。E-ICEBLUE 是市面上可提供最佳文件转换质量的解决方案提供商。`,
        },
        convenient: {
          title: "云端服务",
          desc: "我们的云端伺服器会处理您的文件，您无须下载任何软件至您的计算机。很棒哩？",
        },
      },
      pptp: {
        efficient: {
          title: "如何转换PPT文件成PDF",
          desc: "拖放文件至方框或选择“选择文件”选项以上传文件。我们的服务器会将PPT转换成PDF，您就可以下载文件。",
        },
        privacy: {
          title: "转换文件就这样简单",
          desc: "无须额外工作，就可将您的PPT文件转成PDF。上传文件，坐下来，放松，我们就会为您办妥余下的工作！",
        },
        multiPlatform: {
          title: "支持所有操作系统",
          desc: "我们的PPT转PDF转换器适用于所有系统平台，无论您使用那一种系统平台 - Windows、Mac或Linux。",
        },
        safe: {
          title: "保障私隐",
          desc: "我们十分重视安全。将PPT转换成PDF后，我们的服务器将永久删除您的文件。若希望得到更多信息，请参阅隐私政策。",
        },
        reliable: {
          title: "支持PPT与PPTX",
          desc: "旧ppt和新pptx格式我们都能转换。您只须上传文件，我们就会转换。",
        },
        convenient: {
          title: "基于云端的服务器",
          desc: "我们在云端将您的PPT转换成PDF。这意味着您可以随时随地访问，您的计算机就不用处理这些麻烦的工作。",
        },
      },
      ptj: {
        efficient: {
          title: "如何将PDF转换成JPG",
          desc: "仅需将PDF拖放到上面的方框中，即可将PDF转换成JPG。之后您可以选择有意保存的图像，将之储存在计算机。",
        },
        privacy: {
          title: "转换PDF中的全部图片",
          desc: "转换后，图片将以可下载的单独图片文件形式呈现在您面前。您也可以将全部图片作为一个ZIP文件下载下来。",
        },
        multiPlatform: {
          title: "支持所有操作系统",
          desc: "PDF转JPG应用程序在浏览器内运作，这意味着只要您使用最新的浏览器，便可在任何操作系统，如Windows、Mac及Linux享用我们的服务！",
        },
        safe: {
          title: "安全在线转换服务",
          desc: "您的PDF及JPG文件在转换完成一个小时后，就会从我们的服务器删除。无人能查阅或下载这些文件，以保障您的隐私。",
        },
        reliable: {
          title: "JPG格式是质量的保证",
          desc: "JPG是网络上最常见的图片格式。我们的转换服务输出的始终是JPG文件，即便PDF中的图片是以其他格式保存的。",
        },
        convenient: {
          title: "在云端转换",
          desc: "从PDF提取图片的操作是在云端进行的，因此它不会占用电脑的任何空间，而且您可以在任何地方访问PDF转JPG工具。",
        },
      },
      jtp: {
        efficient: {
          title: "如何将JPG转换成PDF",
          desc: "将JPG文件拖放到方框中。您可以立即对文件进行排序，或者使用您偏爱的设置。点击“马上创建PDF”按钮，并下载PDF文件。",
        },
        privacy: {
          title: "轻松、快速的转换过程",
          desc: "即使没有任何文档转换经验的人，也能将图片轻松快速转换为PDF。只需轻轻点击一下，即可下载您的PDF！",
        },
        multiPlatform: {
          title: "支持所有操作系统",
          desc: "JPG转PDF转换器基于浏览器运作，无需依赖OS操作系统，因此适合用于所有操作系统，包括Mac、Windows以及Linux。",
        },
        safe: {
          title: "安全地在线处理文件",
          desc: "您的隐私会受到保护。没有人能够访问您的JPG与PDF文件，这些文件将在一个小时后从我们的服务器上永久被删除。",
        },
        reliable: {
          title: "支持大部分图像格式",
          desc: "JPG是最常用的图片格式，但我们同时支持多种图档格式，如GIF、BMP、PNG及TIFF。故您也可以使用我们的程序转换这些图档。",
        },
        convenient: {
          title: "在云端转换",
          desc: "图片转换在云端进行，因此并不耗费您的计算机容量。",
        },
      },
      merge: {
        efficient: {
          title: "如何合并PDF",
          desc: "将您的PDF或者多个PDF拖放到上方的方框内。当页面出现时，您就可以开始编辑。然后按下方的按钮下载您的PDF。",
        },
        privacy: {
          title: "带预览功能的简易PDF合并",
          desc: "将页面拖动到编辑区域，以重新排序或删除部份页面。同时，您亦可以增加多个PDF并将它们合并为一个文件。",
        },
        multiPlatform: {
          title: "适用于Mac、Windows以及Linux",
          desc: "网上PDF合并应用程式是经过浏览器操作的，所以适合于所有操作系统，包括Mac、Windows以及Linux上使用。",
        },
        safe: {
          title: "安全在线PDF合并",
          desc: "所有您上载以及由我们的服务器产生的文件会在一个小时内被永久删除。有关详情，请参阅我们的私隐政策。",
        },
        reliable: {
          title: "可靠的服务",
          desc: "通常您要为合并PDF或在PDF增加页面而购买昂贵的软件。这个线上的服务既安全又可靠。",
        },
        convenient: {
          title: "在云端处理中",
          desc: "当您完成合并您的PDF后，我们的云瑞服务器会为您处理该文件，所以过程不会耗费您计算机的资源。",
        },
      },
      split: {
        efficient: {
          title: "两次点击的切分流程",
          desc: "快速将PDF切分为互相独立的单页文件，或者提取指定页面形成新的PDF文稿。您还可以同时在多个PDF中添加和提取页面。",
        },
        privacy: {
          title: "具备预览的PDF切分",
          desc: "我们的PDF切分器为您希望切分的页面提供预览。您可以在视讯介面选择页面，或直接输入您希望提取的页码作选择。",
        },
        multiPlatform: {
          title: "在所有平台切分PDF",
          desc: "您可以在所有操作系统上用最新的网页浏览器使用我们的线上应用来切分PDF。不论是Windows、Mac或Linux都能使用。",
        },
        safe: {
          title: "在线安全的PDF切分",
          desc: "我们的私隐政策会向您解释安全性以及您的私隐对我们而言有多重要。我们会在您上传文件后一小时内从我们的服务器里永久删除所有文件。",
        },
        reliable: {
          title: "实惠的在线应用程式",
          desc: "一般而言，若要切分一个PDF文件，您要花很多钱购买高级的软件才能做到。我们则是以经济实惠的按月价格提供此服务。",
        },
        convenient: {
          title: "云端处理PDF",
          desc: "所有切分、提取与删除工作都在云端进行，因此不会耗费您计算机的资源。",
        },
      },
    },
  },
  plan: {
    free: {
      tag: "免费",
      desc: "受限制的文件处理",
    },
    paidAndExpired: {
      tag: "PRO会员到期时间 ",
      desc: "受限制的文件处理",
    },
    paid: {
      tag: "PRO 会员有效期至 ",
      desc: "不受限制的文件处理",
    },
  },
  siteMap: {
    company: "公司",
    about: "关于",
    contactUs: "联系我们",
    inside: "在线帮助",
    product: "产品",
    pricing: "价格",
    help: "帮助",
  },
  contact: {
    name: "联系我们",
    address: "#41-01 Hong Leong Building,<br/>16 Raffles Quay, Singapore 048581"
  },
  limitRule: {
    notLogin: {
      convert: {
        tag: "还有文档需要处理？",
        desc: "注册以获得更多免费处理次数",
        action: "注册",
      },
      upload: {
        tag: "出错啦！文件太大了。",
        desc: "文件不能大于5M，注册用户没有该限制",
        action: "注册",
      },
    },
    login: {
      upload: {
        tag: "出错啦！文件太大了。",
        desc: "文件不能大于15M，升级至 PRO会员 可以无限制处理文档。",
        action: "升级至 PRO会员",
      },
      convert: {
        tag: "还有文档需要处理？",
        desc: "升级至 PRO会员 可以无限制处理文档。",
        action: "升级至 PRO会员",
      },
    },
    paidAndExpired: {
      upload: {
        tag: "出错啦！文件太大了。",
        desc: "文件不能大于15M，更新 PRO会员 可以无限制处理文档。",
        action: "更新 PRO会员",
      },
      convert: {
        tag: "还有文档需要处理？",
        desc: "更新 PRO会员 可以无限制处理文档。",
        action: "更新 PRO会员",
      },
    },
  },
  about: {
    name: `我们是 ${process.env.VUE_APP_APP} 团队`,
    content: `2019年创立于新加坡，从单一工具发展到 PDF 工具集，${process.env.VUE_APP_APP} 通过20多种PDF工具为世界上各式各样的 PDF 使用需求，提供简单、安全和可靠的解决方案。`,
  },
  splitPage: {
    name: "切分",
    // 文件属性中的文件个数
    files: "文件",
    splitFile: "将1个PDF切分为多个",
    splitFileDesc:
      "例如，将一本书切分为若干章节或独立页面。这样就能获得多个PDF。",
    page: "个页面",
    pages: "个页面",
    splitPage: "提取页面",
    splitPageDesc: "例如，从一本书中提取一个章节或页面。这样就能获得一个PDF。",
    navLeftText: "提取页面",
    navRightText: "切分",
    addMore: "添加文件",
    workLoadingText: "正在提取...",
    workButtonText: "提取",
    workButtonMobileText: "提取为 1个 PDF",
    workLoadingSplitText: "正在切分...",
    workButtonSplitText: "切分",
    workButtonSplitMobileText: "切分成 %totalNumber%个 PDF",
    selectAll: "选择所有",
    SplitAllPage: "切分每一页",
    tip: "按&nbsp;%img%%shift%&nbsp;键一次选择多个页面。",
    tipKey: "Shift",
  },
  mergePage: {
    name: "合并",
    // 文件属性中的文件个数
    files: "文件",
    mergeFile: "合并文件",
    mergeFileDesc: "随心所欲排布拼接整个PDF文件。",
    mergePage: "合并页面",
    mergePageDesc: "选择，排布，再拼接PDF文件里的每个单独的页面。",
    addMore: "添加文件",
    navLeftText: "合并文件",
    navRightText: "合并页面",
    addErrorText: "无法添加。该文件可能已加密、损坏或文件类型不受支持。",
    workLoadingText: "正在合并...",
    workButtonText: "合并",
    tip: "长按即可开始编辑",
    exitEditButtonText: "完成",
    editHint: "拖拽以排序",
    confirmRemove: "确认删除",
  },
};
export default zh;
