export let tools = {
  ptw: {
    type: "convert",
    from: "pdf",
    to: "word",
    icon: require("@/assets/images/work/ptw/icon-s-pdf-word.png"),
    iconMain: require("@/assets/images/work/ptw/icon-big-pdf-word.png"),
    iconTool: require("@/assets/images/work/ptw/icon-tool-pdf-word.png"),
    background: "#EBF8FF",
    textColor: "#00A3FF",
    descColor: "#2CB3FF",
    borderColor: "#80D1FF",
    activeBackground: "#BDE7FF",
    activeTextColor: "#00A3FF",
    hoverBackground: "#EBF8FF",
    hoverTextColor: "#00A3FF",
    local: "pdf-to-word",
  },
  pte: {
    type: "convert",
    from: "pdf",
    to: "excel",
    icon: require("@/assets/images/work/pte/icon-s-pdf-excel.png"),
    iconMain: require("@/assets/images/work/pte/icon-big-pdf-excel.png"),
    iconTool: require("@/assets/images/work/pte/icon-tool-pdf-excel.png"),
    background: "#EEFAF3",
    textColor: "#2EC06C",
    descColor: "#47CC80",
    borderColor: "#89D6AA",
    activeBackground: "#C9EFD9",
    activeTextColor: "#2EC06C",
    hoverBackground: "#EEFAF3",
    hoverTextColor: "#2EC06C",
    local: "pdf-to-excel",
  },
  ptpp: {
    type: "convert",
    from: "pdf",
    to: "ppt",
    icon: require("@/assets/images/work/ptpp/icon-s-pdf-ppt.png"),
    iconMain: require("@/assets/images/work/ptpp/icon-big-pdf-ppt.png"),
    iconTool: require("@/assets/images/work/ptpp/icon-tool-pdf-ppt.png"),
    background: "#FDF4EF",
    textColor: "#E57232",
    descColor: "#EB8850",
    borderColor: "#F0BB9E",
    activeBackground: "#F9DBCA",
    activeTextColor: "#E57232",
    hoverBackground: "#FDF4EF",
    hoverTextColor: "#E57232",
    local: "pdf-to-ppt",
  },
  ptj: {
    type: "convert",
    from: "pdf",
    to: "jpg",
    icon: require("@/assets/images/work/ptj/icon-s-pdf-jpg.png"),
    iconMain: require("@/assets/images/work/ptj/icon-big-pdf-jpg.png"),
    iconTool: require("@/assets/images/work/ptj/icon-tool-pdf-jpg.png"),
    background: "#FDF9EF",
    textColor: "#E5B539",
    descColor: "#EBBF50",
    borderColor: "#F0D181",
    activeBackground: "#F9ECCC",
    activeTextColor: "#E5B539",
    hoverBackground: "#FDF9EF",
    hoverTextColor: "#E5B539",
    local: "pdf-to-jpg",
  },
  wtp: {
    type: "convert",
    from: "word",
    to: "pdf",
    icon: require("@/assets/images/work/wtp/icon-s-word-pdf.png"),
    iconMain: require("@/assets/images/work/wtp/icon-big-word-pdf.png"),
    iconTool: require("@/assets/images/work/wtp/icon-tool-word-pdf.png"),
    background: "#EBF8FF",
    textColor: "#00A3FF",
    descColor: "#2CB3FF",
    borderColor: "#80D1FF",
    activeBackground: "#BDE7FF",
    activeTextColor: "#00A3FF",
    hoverBackground: "#EBF8FF",
    hoverTextColor: "#00A3FF",
    local: "word-to-pdf",
  },
  etp: {
    type: "convert",
    from: "excel",
    to: "pdf",
    icon: require("@/assets/images/work/etp/icon-s-excel-pdf.png"),
    iconMain: require("@/assets/images/work/etp/icon-big-excel-pdf.png"),
    iconTool: require("@/assets/images/work/etp/icon-tool-excel-pdf.png"),
    background: "#EEFAF3",
    textColor: "#2EC06C",
    descColor: "#47CC80",
    borderColor: "#89D6AA",
    activeBackground: "#C9EFD9",
    activeTextColor: "#2EC06C",
    hoverBackground: "#EEFAF3",
    hoverTextColor: "#2EC06C",
    local: "excel-to-pdf",
  },
  pptp: {
    type: "convert",
    from: "ppt",
    to: "pdf",
    icon: require("@/assets/images/work/pptp/icon-s-ppt-pdf.png"),
    iconMain: require("@/assets/images/work/pptp/icon-big-ppt-pdf.png"),
    iconTool: require("@/assets/images/work/pptp/icon-tool-ppt-pdf.png"),
    background: "#FDF4EF",
    textColor: "#E57232",
    descColor: "#EB8850",
    borderColor: "#F0BB9E",
    activeBackground: "#F9DBCA",
    activeTextColor: "#E57232",
    hoverBackground: "#FDF4EF",
    hoverTextColor: "#E57232",
    local: "ppt-to-pdf",
  },
  jtp: {
    type: "convert",
    from: "jpg",
    to: "pdf",
    icon: require("@/assets/images/work/jtp/icon-s-jpg-pdf.png"),
    iconMain: require("@/assets/images/work/jtp/icon-big-jpg-pdf.png"),
    iconTool: require("@/assets/images/work/jtp/icon-tool-jpg-pdf.png"),
    background: "#FDF9EF",
    textColor: "#E5B539",
    descColor: "#EBBF50",
    borderColor: "#F0D181",
    activeBackground: "#F9ECCC",
    activeTextColor: "#E5B539",
    hoverBackground: "#FDF9EF",
    hoverTextColor: "#E5B539",
    local: "jpg-to-pdf",
  },
  merge: {
    type: "merge",
    from: "pdf",
    to: "mergepdf",
    borderColor: "#AEA5E5",
    icon: require("@/assets/images/work/merge/icon-s-merge.png"),
    iconMain: require("@/assets/images/work/merge/icon-big-merge.png"),
    iconTool: require("@/assets/images/work/merge/icon-tool-merge.png"),
    background: "#EFEDFC",
    textColor: "#7663E5",
    descColor: "#8575E5",
    activeBackground: "#DBD6FA",
    activeTextColor: "#7561EA",
    hoverBackground: "#EFEDFC",
    hoverTextColor: "#7561EA",
    local: "merge-pdf",
  },
  split: {
    type: "split",
    from: "pdf",
    to: "splitpdf",
    borderColor: "#AEA5E5",
    icon: require("@/assets/images/work/split/icon-s-split.png"),
    iconMain: require("@/assets/images/work/split/icon-big-split.png"),
    iconTool: require("@/assets/images/work/split/icon-tool-split.png"),
    background: "#EFEDFC",
    textColor: "#7663E5",
    descColor: "#8575E5",
    activeBackground: "#DBD6FA",
    activeTextColor: "#7561EA",
    hoverBackground: "#EFEDFC",
    hoverTextColor: "#7561EA",
    local: "split-pdf",
  },
};
