<template>
  <div
    v-show="show"
    class="tool"
    :class="{
      'other-tool': !sortNormal,
      'other-tool-normal': sortNormal,
      }"
  >
    <div class="title" :class="{'normal-title': sortNormal}">{{ $t("convertPage.mergeAndSplitToolName") }}</div>
    <div :key="key" v-for="(item, key) in otherTools">
      <ColumnToolBox
        :style="{
          hoverTextColor: item.hoverTextColor,
          hoverBackground: item.hoverBackground,
          activeTextColor: item.activeTextColor,
          activeBackground: item.activeBackground,
        }"
        :button-text="`${
          item.type === 'merge' ? $t('mergePage.name') : $t('splitPage.name')
        } ${store.files[item.to].name}`"
        :img-url="item.icon"
        :work="key"
      ></ColumnToolBox>
    </div>
  </div>

  <div
    v-show="show"
    class="tool"
    :class="{
      'from-pdf-tool': !sortNormal,
      'from-pdf-tool-normal': sortNormal,
    }"
  >
    <div class="title" :class="{'normal-title': sortNormal}">{{ $t("convertPage.convertFromPDFToolName") }}</div>
    <div :key="key" v-for="(item, key) in fromPDFTools">
      <ColumnToolBox
        :style="{
          hoverTextColor: item.hoverTextColor,
          hoverBackground: item.hoverBackground,
          activeTextColor: item.activeTextColor,
          activeBackground: item.activeBackground,
        }"
        :button-text="`${store.files[item.from].name} ${$t('convertPage.to')} ${
          store.files[item.to].name
        }`"
        :img-url="item.icon"
        :work="key"
      ></ColumnToolBox>
    </div>
  </div>
  <div
    v-show="show"
    class=" tool"
    :class="{
      'to-pdf-tool': !sortNormal,
      'to-pdf-tool-normal': sortNormal,
    }"
  >
    <hr class="line" :style="{ width: lineWidth + 'px' }" />
    <div class="title" :class="{'normal-title': sortNormal}">{{ $t("convertPage.convertToPDFToolName") }}</div>
    <div :key="key" v-for="(item, key) in toPDFTools">
      <ColumnToolBox
        :style="{
          hoverTextColor: item.hoverTextColor,
          hoverBackground: item.hoverBackground,
          activeTextColor: item.activeTextColor,
          activeBackground: item.activeBackground,
        }"
        :button-text="`${store.files[item.from].name} ${$t('convertPage.to')} ${
          store.files[item.to].name
        }`"
        :img-url="item.icon"
        :work="key"
      ></ColumnToolBox>
    </div>
  </div>
</template>

<script>
import ColumnToolBox from "@/components/ColumnToolBox";
import { otherTools, toPDFTools, fromPDFTools } from "@/store/typeTools";
import { useStore } from "vuex";

export default {
  name: "ColumnToolBlock",
  components: { ColumnToolBox },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    sortNormal: {
      type: Boolean,
      default: false,
    },
    lineWidth: {
      type: Number,
      default: 920,
    },
  },
  setup() {
    let store = useStore().state;

    return {
      store,
      otherTools,
      toPDFTools,
      fromPDFTools,
    };
  },
};
</script>

<style scoped>
.other-tool,
.from-pdf-tool,
.to-pdf-tool {
  margin-top: 40px;
  margin-bottom: 28px;
}
.other-tool-normal {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 3;
  grid-column-end: 4;
}
.other-tool {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;
}

.from-pdf-tool-normal {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 2;
}
.from-pdf-tool {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 2;
}
.to-pdf-tool-normal {
  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 3;
}
.to-pdf-tool {
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 2;
  position: relative;
}
.line {
  position: absolute;
  width: 920px;
  top: -40px;
  right: 0px;
}
.title {
  padding-left: 14px;
  margin-bottom: 8px;
  font-weight: 700;
}
.normal-title {
  font-size: 14px;
}
</style>
