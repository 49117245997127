<template>
  <div class="uploading">
    <img class="icon" :src="icon" />
    <div class="flex">
      <img class="small-icon small-icon-15" :src="behaviorIcon" />
      <span> {{ title }} </span>
    </div>
    <div class="pre">
      <div class="pre-channel" v-if="!rolling">
        <div
          :style="{
            width: pre + '%',
            border: '1px solid ' + preColor,
            backgroundColor: preColor,
          }"
          class="current"
        ></div>
        <div class="total" :style="{ border: '1px solid' + preColor }"></div>
      </div>
      <Bar
        v-else
        :style="{
          width: '420px',
          height: '6px',
          borderRadius: '2px',
          backgroundColor: store.tools[store.work].textColor,
          lineBackgroundColor: store.tools[store.work].activeBackground,
        }"
      ></Bar>
    </div>
  </div>
</template>

<script>
// import { toRefs, reactive, ref } from 'vue'
import Bar from "@/components/Bar";
import { useStore } from "vuex";
export default {
  name: "Uploading",
  components: { Bar },
  props: {
    title: {
      type: String,
      default: "ing...",
    },
    rolling: {
      type: Boolean,
      default: true,
    },
    preColor: {
      type: String,
      default: "#00a3ff",
    },
    pre: {
      type: Number,
      default: 0,
    },
    behaviorIcon: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
  setup() {
    let store = useStore().state;
    return {
      store,
    };
  },
  // created () {
  // }
};
</script>

<style scoped>
.uploading {
  padding-top: 30px;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.icon {
  /*width: 72px;*/
  height: 72px;
  margin-bottom: 24px;
}
.pre {
  margin-top: 27px;
  width: 420px;
  height: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pre .text {
  margin-left: 1rem;
}
.pre .pre-channel {
  border-radius: 2px;
  width: 100%;
  position: relative;
  height: 100%;
}
.current {
  top: 0;
  z-index: 2;
  height: 100%;
  border-radius: inherit;
  box-sizing: border-box;
  position: absolute;
  transition: width 0.5s;
  -webkit-transition: width 0.5s;
  max-width: 100%;
}
.total {
  top: 0;
  height: 100%;
  border-radius: inherit;
  width: 100%;
  position: absolute;
  box-sizing: border-box;
}

@media all and (max-width: 640px) and (min-width: 320px) {
  .uploading {
    padding: 38px;
    box-sizing: border-box;
    font-size: 18px;
  }
  .icon {
    margin-bottom: 24px;
  }
  .pre {
    margin-top: 14px;
    max-width: 251px;
  }
}
@media all and (max-width: 1020px) and (min-width: 640px) {
  .uploading {
    padding: 28px 30px 42px;
    box-sizing: border-box;
    font-size: 18px;
  }
  .icon {
    margin-bottom: 18px;
  }
  .pre {
    margin-top: 24px;
    width: 60%;
  }
}
</style>
