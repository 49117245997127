import App from "./ConvertApp.vue";
import { createApp } from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import store from "../store/workStroe";
import { i18n } from "@/language/i18n";
import router from "../router/work";
import { Lazy, Radio } from "@varlet/ui";


let app = createApp(App);
app.config.globalProperties.$languages = {
  en: "English",
  zh: "简体中文",
  zh_tc: "繁体中文",
};

app
  .use(VueAxios, axios)
  .use(Lazy)
  .use(Radio)
  .use(i18n)
  .use(router)
  .use(store)
  .mount("#app");
