<template>
  <transition name="left" class="v-enter-active v-leave-active">
    <div class="login">
      <div class="title">
        {{ `${$t("loginPage.title")} ${$t("app")}` || "Login to CyberPDF" }}
      </div>
      <Brand class="brand"></Brand>
      <IEmail
        class="eml"
        @updateValue="updateValueEmail"
        @oninput="oninputEmail"
        :has-parent-hint="hasHintEmail"
        :hint="emailHint"
      >
      </IEmail>
      <IPassword
        @updateValue="updateValuePassword"
        :has-parent-hint="hasHintPassword"
        @oninput="oninputPassword"
        :hint="passwordHint"
        @keypress.enter="buttonClick"
      ></IPassword>
      <Button class="button" @click="buttonClick" :status="butStatus">
        <span v-if="butStatus !== 'ok'">{{
          $t("loginPage.name") || "Login"
        }}</span>
        <img
          v-if="butStatus === 'ok'"
          src="@/assets/images/account/icon_check_bgw.png"
          alt="load error"
        />
      </Button>

      <div style="height: 14px"></div>
      <a href="javascript:void(0);" @click="toVerifyEmail" class="forgot-tip">{{
        $t("loginPage.forgotTip") || "Forgot your password?"
      }}</a>
      <div style="height: 14px"></div>
      <hr />
      <div style="height: 14px"></div>
      <div class="tip">
        {{ $t("loginPage.signUpTip") || "Don’t have an account?" }}
        <a href="javascript:void(0);" @click="toPage">{{
          $t("loginPage.signUpText") || "Sign up"
        }}</a>
      </div>
    </div>
  </transition>
</template>
<script>
import { inject, onMounted, ref } from "vue";
import IEmail from "../components/IEmail";
import IPassword from "../components/IPassword";
import Brand from "../components/Brand";
import Button from "../components/Button";
import utils from "../util/utils";
import Account from "../store/account";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { getBackUrl } from "@/router/back";
export default {
  name: "Login",
  components: {
    Brand,
    IEmail,
    IPassword,
    Button,
  },
  props: {
    isHead: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["headToggle", "toVerifyEmail"],
  setup(props, context) {
    const { t } = useI18n();
    let useStores = useStore();
    let router = useRouter();
    let route = useRoute();
    let email = ref("");
    let hasHintEmail = ref(false);
    let password = ref("");
    let hasHintPassword = ref(false);
    let butStatus = ref("before");
    let emailHint = ref("");
    let passwordHint = ref("");

    function toPage() {
      if (props.isHead) context.emit("headToggle");
      else router.push({ name: "signUp" });
    }

    function toVerifyEmail() {
      if (props.isHead) context.emit("toVerifyEmail");
      else router.push({ name: "vEmail" });
    }

    function updateValueEmail(e) {
      email.value = e;
    }
    function updateValuePassword(e) {
      password.value = e;
    }
    function oninputEmail(e) {
      email.value = e;
      hasHintEmail.value = false;
      emailHint.value = "";
    }
    function oninputPassword(e) {
      password.value = e;
      hasHintPassword.value = false;
    }

    let headTopupHide = inject("headTopupHide");
    let isHeadTopup = inject("isHeadTopup");

    function login() {
      utils
        .login(email.value, password.value)
        .then((res) => {
          butStatus.value = "ok";
          utils.setCookie("sid", res.data.sid);
          Account.storage(res.data.sid);
          useStores.dispatch("account/init");
          useStores.commit("changeLogin", true);
          useStores.commit("change", {
            key: "recents",
            value: utils.getRecents(res.data.code),
          });
          window.setTimeout(() => {
            if (isHeadTopup.value) {
              headTopupHide();
              window.location.reload();
            } else {
              window.location.href = route.query.from ? getBackUrl("signUp") : "/index.html#/workspace";
            }
          }, 1000);
        })
        .catch((e) => {
          butStatus.value = "err";
          if (e && e.response && e.response.status === 404) {
            hasHintEmail.value = true;
            emailHint.value =
              t("error.accountNotExist") || "Account does not exist";
            console.log("Account does not exist");
          }
          if (e && e.response && e.response.status === 403) {
            passwordHint.value = t("error.wrongPassword") || "Wrong password";
            hasHintPassword.value = true;
            console.log("Wrong password");
          }
          if (e && e.response && e.response.status === 406) {
            hasHintEmail.value = true;
            emailHint.value =
              t("error.emailFormatErr") || "The email format is incorrect";
            console.log("The mailbox format is incorrect");
          }
        });
    }

    function buttonClick() {
      if (!email.value || !utils.verifyEmail(email.value)) {
        hasHintEmail.value = true;
        return;
      } else {
        hasHintEmail.value = false;
      }
      if (!password.value) {
        hasHintPassword.value = true;
        return;
      } else {
        hasHintPassword.value = false;
      }
      butStatus.value = "going";

      utils
        .getAccountExist(email.value)
        .then((res) => {
          if (res && res.data) {
            login();
          } else {
            hasHintEmail.value = true;
            emailHint.value =
              t("error.accountNotExist") ||
              "The current email is not registered, please sign up first!";
            butStatus.value = "before";
          }
        })
        .catch(() => {
          login();
        });
    }

    onMounted(() => {
      if (window.document.body.clientWidth < 765) {
        try {
          document
            .getElementsByClassName("eml")[0]
            .getElementsByTagName("input")[0]
            .focus();
        } catch (e) {
          console.log(e);
        }
      }
    });

    return {
      toPage,
      toVerifyEmail,
      butStatus,
      email,
      hasHintEmail,
      hasHintPassword,
      emailHint,
      passwordHint,
      password,
      updateValueEmail,
      updateValuePassword,
      oninputEmail,
      oninputPassword,
      buttonClick,
    };
  },
  // created () {
  // }
};
</script>

<style scoped>
.login {
  margin-top: 48px;
  margin-left: auto;
  margin-right: auto;
  width: 360px;
  text-align: center;
  font-size: 14px;
}
.login .title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 28px;
}
.brand {
  margin-bottom: 28px;
}
.login .eml {
  margin-bottom: 14px;
}
.button {
  margin-top: 14px;
}
a {
  color: #00a3ff;
  text-decoration: none;
}
.tip {
  color: #7b7b7b;
}

.button img {
  width: 28px;
  height: 28px;
}

@media all and (max-width: 1020px) and (min-width: 320px) {
  .login {
    box-sizing: border-box;
    width: auto;
    margin: 0 auto;
  }
  .login .title {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .login .eml {
    margin-bottom: 18px;
  }
  .tip,
  button {
    font-size: 16px;
  }
  .button {
    margin-top: 28px;
  }
  .brand {
    margin-bottom: 0;
  }
  .forgot-tip {
    font-size: 16px;
  }
}
@media all and (max-width: 1020px) and (min-width:765px) {
  .login {
    margin: 40px auto;
  }
  .brand {
    margin-bottom: 24px;
  }
}
</style>
