<template>
  <button class="button" :class="status">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "Button",
  components: {},
  props: {
    status: {
      default: "before",
      type: String,
    },
  },
};
</script>

<style scoped>
button {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.before {
  /*background-color: #00a3ff;*/
}
.going {
  background-color: #80d1ff;
}
.ok {
  background-color: #00cb08;
}
.err {
  background-color: #00a3ff;
}

@media all and (max-width: 1020px) and (min-width: 320px) {
  button {
    min-width: 258px;
    height: 44px;
  }
}
</style>
