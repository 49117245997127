<template>
  <div></div>
</template>

<script>
// import { toRefs, reactive, ref } from 'vue'

export default {
  name: "Access", // 认证
  components: {},
  setup() {},
  created() {},
};
</script>

<style scoped></style>
