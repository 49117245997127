<template>
  <transition name="left" class="v-enter-active v-leave-active">
    <div
      class="ver-email-body flex"
      :style="{
        position: isHead ? 'static' : 'absolute',
        'background-color': isHead ? '#ffffff' : '#f5f5f5',
      }"
    >
      <div class="ver-email flex">
        <div class="">
          <div class="title">{{ $t("verifyEmailPage.title") }}</div>
          <IEmail
            class="eml"
            @updateValue="updateValueEmail"
            @oninput="oninputEmail"
            :has-parent-hint="hasHintEmail"
            :hint="hint"
            @keypress.enter="buttonClick"
          >
          </IEmail>

          <Button @click="buttonClick" :status="butStatus">
            {{ $t("action.next") }}
          </Button>

          <div class="tip">
            {{ $t("verifyEmailPage.signUpTip") }}
            <a href="javascript:void(0);" @click="toSign">{{ $t("verifyEmailPage.signUpText") }}</a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import IEmail from "../components/IEmail";
import Button from "../components/Button";
import utils from "../util/utils";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default {
  name: "VerifyEmail",
  components: {
    IEmail,
    Button,
  },
  props: {
    isHead: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const { t, locale } = useI18n();
    let useStores = useStore();

    let hint = ref("");
    let email = ref("");
    let router = useRouter();
    let hasHintEmail = ref(false);
    let hasHintPassword = ref(false);
    let butStatus = ref("before");
    function updateValueEmail(e) {
      email.value = e;
    }
    function oninputEmail(e) {
      email.value = e;
      hasHintEmail.value = false;
      hint.value = "";
    }

    function goNext() {
      useStores.commit("setCurrentEmail", email.value);
      useStores.commit("change", { key: "canResendCode", value: false });
      if (props.isHead) context.emit("toVerifyCode");
      else router.push({ name: "vPage", params: { source: "forgot" } });
    }
    function buttonClick() {
      if (!email.value || !utils.verifyEmail(email.value)) {
        hasHintEmail.value = true;
        return;
      } else {
        hasHintEmail.value = false;
      }
      butStatus.value = "going";

      utils
        .sendCode(email.value, true, "other", locale.value)
        .then(() => {
          goNext();
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 404) {
            hasHintEmail.value = true;
            hint.value =
              t("error.accountNotExist") ||
              "Account does not exist, Please sign up first!";
          } else if (e && e.response && e.response.status === 429) {
            goNext();
          } else {
            hasHintEmail.value = true;
            console.log(e);
          }
        })
        .finally(() => {
          butStatus.value = "before";
        });
    }

    function toSign() {
      if (props.isHead) context.emit("toSignUpUp");
      else router.push({name: "signUp"});
    }

    onMounted(() => {
      if (window.document.body.clientWidth < 765) {
        try {
          document
            .getElementsByClassName("eml")[0]
            .getElementsByTagName("input")[0]
            .focus();
        } catch (e) {
          console.log(e);
        }
      }
    });

    return {
      butStatus,
      email,
      hasHintEmail,
      hasHintPassword,
      updateValueEmail,
      oninputEmail,
      buttonClick,
      hint,
      toSign,
    };
  },
  // created () {
  // }
};
</script>

<style scoped>
a {
  color: #00a3ff;
  text-decoration: none;
}
.ver-email-body {
  /*position: absolute;*/
  /*background-color: #f5f5f5;*/
  width: 100%;
  bottom: 48px;
  top: 48px;
  /*height: 100%;*/
  flex-direction: column;
}
.ver-email {
  background: #ffffff;
  border-radius: 8px;
  width: 420px;
  text-align: center;
  padding: 32px 0;
  font-size: 14px;
}
.ver-email > div {
  width: 360px;
}
.ver-email .title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 28px;
}
.ver-email button {
  margin: 14px 0;
}
.ver-email .flex {
  flex-direction: column;
}
.tip {
  color: #7b7b7b;
}

@media all and (max-width: 570px) and (min-width: 320px) {
  .ver-email .title {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .ver-email,
  .ver-email button {
    font-size: 16px;
  }
  .ver-email,
  .ver-email > div {
    padding: 0;
    width: 100%;
  }
}
</style>
